import classNames from 'classnames';
import { ReactElement } from 'react';

import Loading from '../../loading/main-loading';
import { colors } from '../../../../constants/colors';
import { TableHeaders } from '../../table/types';

import styles from './Table.module.css';

interface ITable {
  headers: TableHeaders[];
  isLoading?: boolean;
  children: ReactElement | ReactElement[];
}

function TableSDLC({ headers, isLoading, children }: ITable) {
  return (
    <div className={classNames(styles.tableWrapper, styles.scrollWrapperX)}>
      <table>
        <thead>
          <tr className={styles.sdlcSubHeader}>
            <th className={styles.tasks}>
              <span>Tasks</span>
            </th>
            <th className={styles.analysis} colSpan={4}>
              <span>Analysis steps</span>
            </th>
            <th className={styles.implementation} colSpan={9}>
              <span>Implementation steps</span>
            </th>
            <th className={styles.release} colSpan={3}>
              <span>Release steps</span>
            </th>
          </tr>
          <tr className={styles.headRow}>
            {headers.map((header, i) => (
              <th
                key={header.title}
                style={header.style}
                className={classNames(styles.headerTh, {
                  [styles.stickyHeaderTh]: i === 0,
                })}
              >
                <span
                  className={styles.headerTitle}
                  style={{
                    justifyContent: header?.changeFlex,
                  }}
                >
                  <span
                    style={{
                      borderRight:
                        i !== headers.length - 1
                          ? `1px solid ${colors.silver}`
                          : 'none',
                      width: '100%',
                    }}
                  >
                    {header.title}
                  </span>
                </span>
              </th>
            ))}
          </tr>
        </thead>
        <tbody className={styles.tbody}>
          {isLoading ? (
            <tr>
              <td colSpan={headers.length} className={styles.loader}>
                <div className={styles.loaderItem}>
                  <Loading />
                </div>
              </td>
            </tr>
          ) : (
            children
          )}
        </tbody>
      </table>
    </div>
  );
}

export default TableSDLC;
