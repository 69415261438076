// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.chip_chip__C8CCr{
  padding-left: 6px;
  padding-right: 6px;
  padding-top: 4px;
  padding-bottom: 4px;
  font-size: 12px;
  border: 1px solid var(--main-font);
  border-radius: 10px;
  display: flex;
}
`, "",{"version":3,"sources":["webpack://./src/renderer/components/shared/chip/chip.module.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,kBAAkB;EAClB,gBAAgB;EAChB,mBAAmB;EACnB,eAAe;EACf,kCAAkC;EAClC,mBAAmB;EACnB,aAAa;AACf","sourcesContent":[".chip{\n  padding-left: 6px;\n  padding-right: 6px;\n  padding-top: 4px;\n  padding-bottom: 4px;\n  font-size: 12px;\n  border: 1px solid var(--main-font);\n  border-radius: 10px;\n  display: flex;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"chip": `chip_chip__C8CCr`
};
export default ___CSS_LOADER_EXPORT___;
