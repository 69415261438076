import { useEffect } from 'react';
import { toast } from 'react-toastify';
import { useMutation } from 'react-query';

import { sdlcTasksApi } from '../../../api/sdlc';
import { HTTP_STATUSES, TOAST_MESSAGES } from '../../../constants';
import StatusSelect from '../../../components/shared/table-release-modules/status-select';
import {
  STEP_STATUSES,
  STEPS,
  STEPS_USER_VIEW,
  useStatusMap,
} from '../../../components/shared/table-release-modules/constants';
import { fieldsToRemove } from '../../../components/shared/table-release-modules/table-items/sdlc-item';
import { removeFields } from '../../../utils';
import { TaskBlockProps } from './types';
import styles from './TaskView.module.css';

const TaskProgress = ({ taskId, editAvailable }: TaskBlockProps) => {
  const statusMap = useStatusMap(STEP_STATUSES);
  const {
    mutate: getEnvironments,
    data: sdlcDetails,
    isLoading,
  } = useMutation(sdlcTasksApi.getSDLCDataByFeatureId, {});

  useEffect(() => {
    getEnvironments(taskId);
  }, []);

  const updateStatus = async (status: any, step: string, oldStatus: any) => {
    if (status === oldStatus?.value) return;
    const allStatuses = removeFields(sdlcDetails?.data, fieldsToRemove);
    const body = {
      featureId: taskId,
      ...allStatuses,
      [step]: status,
    };
    const response = await sdlcTasksApi.updateSDLCStepByFeatureId(taskId, body);
    if (response?.status === HTTP_STATUSES.ok) {
      toast.success(TOAST_MESSAGES.SAVED);
    } else {
      toast.error(TOAST_MESSAGES.COMMON_ERROR);
    }
  };

  return (
    <div className={styles.displayColumn}>
      <span className={styles.detailsTitle}>Progress</span>
      <div className={styles.progress}>
        <div className={styles.progressGroups}>
          <div></div>
          <div className={`${styles.analysis} ${styles.groupItem}`}>
            <span>Analysis steps</span>
          </div>
          <div className={`${styles.implementation} ${styles.groupItem}`}>
            <span>Implementation steps</span>
          </div>
          <div className={`${styles.release} ${styles.groupItem}`}>
            <span>Release steps</span>
          </div>
        </div>
        <div className={styles.progressContent}>
          <div className={styles.sectionRow}>
            <div className={styles.taskViewTableHeader}>
              <span>Step</span>
            </div>
            <div
              className={styles.taskViewTableHeader}
              style={{ justifyContent: 'center' }}
            >
              <span>Status</span>
            </div>
          </div>
          {STEPS.map((el) => {
            const value = sdlcDetails?.data[el] || '';
            const labelText = statusMap[el]?.[value] || '';
            const statusOptions = STEP_STATUSES[el];
            const currentValue = statusOptions.find(
              (op: any) => op?.label === labelText,
            );
            return (
              <div key={el} className={styles.sectionRow}>
                <div className={styles.sectionName}>{STEPS_USER_VIEW[el]}</div>
                <div
                  className={styles.sectionStatus}
                  style={{ cursor: editAvailable ? 'pointer' : '' }}
                >
                  {isLoading ? (
                    ''
                  ) : (
                    <StatusSelect
                      status={currentValue}
                      options={statusOptions}
                      callback={(v: string) =>
                        updateStatus(v, el, currentValue)
                      }
                      disabled={!editAvailable}
                      containerStyles={{
                        maxWidth: '170px',
                        width: '-webkit-fill-available',
                      }}
                    />
                  )}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default TaskProgress;
