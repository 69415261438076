import React, { useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { ModalWrapperProps } from './types';
import './modal-wrapper.css';

const ModalWrapper: React.FC<ModalWrapperProps> = ({
  children,
  index = 8,
  style,
}) => {
  const modalRootRef = useRef<HTMLElement | null>(null);
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    let modalRoot = document.getElementById('modal');
    if (!modalRoot) {
      modalRoot = document.createElement('div');
      modalRoot.id = 'modal';
      document.body.appendChild(modalRoot);
    }
    modalRootRef.current = modalRoot;
    setIsMounted(true);

    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, []);

  if (!isMounted || !modalRootRef.current) {
    return null;
  }

  return ReactDOM.createPortal(
    <div style={{ zIndex: index, ...style }} className="modal__wrapper">
      {children}
    </div>,
    modalRootRef.current,
  );
};

export default ModalWrapper;
