import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Confluence from '../../../../assets/icons/confluence';
import Redmine from '../../../../assets/icons/redmine';
import Account from '../../../../assets/icons/account';
import { RoutesData } from '../../../../types/routes/routes';
import { TASK_STATUS } from '../../../../screens/Tasks/create-new-task/constants';
import StatusSelect from '../status-select';
import styles from './TableItem.module.css';

function ReleaseItem({ item }: any): React.JSX.Element {
  const navigate = useNavigate();

  const [taskStatus, setTaskStatus] = useState<any>({});
  const isAssignees = item?.assignees?.length > 0;
  useEffect(() => {
    const status = TASK_STATUS.find((s: any) => {
      return s?.value === item?.status;
    });
    setTaskStatus(status);
  }, []);

  const handleRedirect = () => {
    navigate(`${RoutesData.Tasks}/${item.id}`, { state: { taskId: item.id } });
  };

  return (
    <tr className={styles.item}>
      <td onClick={handleRedirect} style={{ width: '24%' }}>
        {item?.name}
      </td>
      <td>{item?.priority}</td>
      <td style={{ width: '24%' }}>
        {isAssignees
          ? item?.assignees.map((user: any) => (
              <div className={styles.tooltip} key={user.id}>
                <Account />
                <span className={styles.tooltiptext}>
                  {user.assignee?.user?.firstName}{' '}
                  {user.assignee?.user?.lastName}
                </span>
              </div>
            ))
          : null}
      </td>
      <td>
        <div className={styles.linkItem}>
          <Confluence />
          <a href={item?.confluenceLink} target="_blank" rel="noreferrer">
            Link
          </a>
        </div>
      </td>
      <td>
        <div className={styles.linkItem}>
          <Redmine />
          <a href={item?.redmineTaskLink} target="_blank" rel="noreferrer">
            Link
          </a>
        </div>
      </td>
      <td style={{ width: '250px' }}>
        <StatusSelect status={taskStatus} id={item.id} />
      </td>
    </tr>
  );
}

export default ReleaseItem;
