import { toast } from 'react-toastify';
import { taskApi } from '../../../../api/task';
import ExitIcon from '../../../../assets/icons/exit-icon';
import { ApproveModal, MultiSelectUsers } from '../../../../components/shared';
import { HTTP_STATUSES, TOAST_MESSAGES } from '../../../../constants';
import MultiSelectUserItem from '../../../../components/shared/multi-select-user-id/multi-select-user-item';
import styles from './Modals.module.css';

interface IEditAssigneesModal {
  close: () => void;
  updateList: () => void;
  assignees: any[];
  taskId: string | number;
  showListOnly?: boolean;
}

const EditAssigneesModal = ({
  assignees,
  close,
  updateList,
  taskId,
  showListOnly = false,
}: IEditAssigneesModal) => {
  const defUsers = assignees.map((us: any) => {
    return {
      label: `${us.assignee.user?.firstName || ''} ${us.assignee.user?.lastName || ''}`,
      value: us.assigneeId,
      details: us.assignee.user.email,
    };
  });

  const setTargetUsersIds = async (arr: any) => {
    const ids = arr?.map((el: any) => el?.value);
    const body = {
      assigneeIds: ids,
    };
    const response = await taskApi.updateAssignees(taskId, body);
    if (response.status === HTTP_STATUSES.ok) {
      toast.success(TOAST_MESSAGES.UPDATED);
      updateList();
    } else {
      toast.error(TOAST_MESSAGES.CANT_BE_UPDATED);
    }
  };

  return (
    <ApproveModal
      btnText="Save"
      callback={() => {}}
      closeModal={close}
      disabled={false}
      hideActionBtn
    >
      <div className={styles.editWrapper} style={{ width: '420px' }}>
        <span role="button" onClick={close} className={styles.closeBtn}>
          <ExitIcon />
        </span>
        <div className={styles.modalTitle} style={{ marginBottom: '0' }}>
          Assignee
        </div>
        {showListOnly ? (
          <div>
            <div className={styles.userListView}>
              <span className={styles.employeesCounter}>
                {assignees?.length} employee
              </span>
              {assignees.map((el) => {
                const assignee = {
                  label: `${el.assignee?.user?.firstName}
                ${el.assignee?.user?.lastName}`,
                };
                return (
                  <MultiSelectUserItem
                    key={el.label}
                    item={assignee}
                    handleDeleteItem={() => {}}
                    itemModeView="detailed"
                    hideActions
                  />
                );
              })}
            </div>
          </div>
        ) : (
          <MultiSelectUsers
            emptyListTitle="No employee"
            label="Add employee"
            placeHolder="Enter employee"
            onChange={setTargetUsersIds}
            defaultUsers={defUsers}
            itemModeView="detailed"
            customUserList={styles.userListView}
            showUsersCounter
          />
        )}
      </div>
    </ApproveModal>
  );
};

export default EditAssigneesModal;
