import api from './axios.config';

const defaultParams = { skip: 0, take: 100, sort: 'releaseDate', order: 'ASC' };

const getSDLCData = (params = defaultParams) => {
  return api.get<any>('/feature-phase-statuses', { params });
};
const getSDLCDataById = (id: string | number) => {
  return api.get<any>(`/feature-phase-statuses/${id}`);
};
const getSDLCDataByFeatureId = (id: string | number) => {
  return api.get<any>(`/features/${id}/phase-statuses`);
};
const updateSDLCStepStatus = (id: string | number, body: any) => {
  return api.patch<any>(`/feature-phase-statuses/${id}`, body);
};
const updateSDLCStepByFeatureId = (id: string | number, body: any) => {
  return api.patch<any>(`features/${id}/phase-statuses`, body);
};
export const sdlcTasksApi = {
  getSDLCData,
  getSDLCDataById,
  updateSDLCStepStatus,
  getSDLCDataByFeatureId,
  updateSDLCStepByFeatureId,
};
