import { useCallback, useEffect, useState } from 'react';
import { groupByDate, groupByKey } from '../../../utils';
import { taskApi } from '../../../api/task';
import { HTTP_STATUSES } from '../../../constants';
import {
  NoReleaseModule,
  ReleaseModule,
  Select,
} from '../../../components/shared';
import { isObjectEmpty } from '../../../utils/objects/isObjectEmpty';
import Loading from '../../../components/shared/loading/main-loading';

import styles from './Planning.module.css';

const PlanningTable = ({ search, searchByString, users }: any) => {
  const [tasksWithDate, setTasksWithDate] = useState<any[]>([]);
  const [tasksWithoutDate, setTasksWithoutDate] = useState<any>({});
  const [loading, setLoading] = useState<boolean>(false);
  const [filterByUser, setFilterByUser] = useState<any>('all');

  const getData = useCallback(
    async (filter: Record<string, any> | null = null) => {
      setLoading(true);
      try {
        const params: any = {
          skip: 0,
          take: 100,
          sort: 'releaseDate',
          order: 'ASC',
          ...(search && { search: JSON.stringify({ name: search }) }),
          ...(filter ? { filter: JSON.stringify(filter) } : {}),
        };
        const response = await taskApi.getTasksList(params);

        if (response?.status === HTTP_STATUSES.ok) {
          const filterNoDate = groupByKey(
            response?.data?.list.filter((task: any) => !task?.releaseDate),
            'releaseId',
          );
          setTasksWithoutDate(filterNoDate);

          const filterWithDate = groupByDate(
            response?.data?.list.filter((task: any) => task?.releaseDate),
            'releaseDate',
          );
          const tasksByDateRelease = filterWithDate
            .map((el: any) => {
              return groupByKey(el?.items, 'releaseId');
            })
            .reverse();
          setTasksWithDate(tasksByDateRelease);
        }
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    },
    [search],
  );

  useEffect(() => {
    handleFilterByUser(undefined);
  }, [searchByString]);

  const handleFilterByUser = (user: any) => {
    if (!user) {
      setFilterByUser('all');
      getData(null);
      return;
    }
    const { value } = user;
    setFilterByUser(value);
    const filterId = value === 'all' ? null : { 'assignees.assigneeId': value };
    getData(filterId);
  };

  const isSomeSearch = search?.length > 0;

  return (
    <div className={styles.wrapper}>
      <div className={styles.searchRow}>
        <span>Filter:</span>
        <div style={{ width: '20%' }}>
          <Select
            showAdditionalInfoInSelect
            options={users}
            onChange={handleFilterByUser}
            emptyText=""
            value={filterByUser}
            showClear
          />
        </div>
      </div>
      {loading ? (
        <div className={styles.loadingContainer}>
          <Loading />
        </div>
      ) : (
        <>
          {tasksWithDate?.length || !isObjectEmpty(tasksWithoutDate) ? (
            <>
              {Object.entries(tasksWithoutDate)
                .reverse()
                .map((item: any, index: number) => {
                  if (item[0] === 'no_release') {
                    return (
                      <NoReleaseModule key={item[0] + index} tasks={item[1]} />
                    );
                  } else {
                    return (
                      <ReleaseModule
                        key={item[1] + index}
                        tasks={item[1]}
                        releaseId={item[0]}
                        isSomeSearch={isSomeSearch}
                      />
                    );
                  }
                })}
              {tasksWithDate.map((item: any, index: number) => {
                const taskObj = Object.entries(item);
                return taskObj.map((task: any) => {
                  if (task[0] === 'no_release') {
                    return (
                      <NoReleaseModule key={task[0] + index} tasks={task[1]} />
                    );
                  } else {
                    return (
                      <ReleaseModule
                        key={task[0] + index}
                        tasks={task[1]}
                        releaseId={task[0]}
                        isSomeSearch={isSomeSearch}
                      />
                    );
                  }
                });
              })}
            </>
          ) : (
            <div>
              <NoReleaseModule />
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default PlanningTable;
