export function groupByKey(
  dataArray: any[],
  key: string,
  customEmptyField = 'no_release',
) {
  return dataArray?.reduce((acc, item) => {
    const keyValue =
      item[key] !== undefined && item[key] !== null
        ? item[key]
        : customEmptyField;
    if (!acc[keyValue]) {
      acc[keyValue] = [];
    }
    acc[keyValue].push(item);
    return acc;
  }, {});
}

export function groupByNestedKey(dataArray: any[], key: string, key2: string) {
  return dataArray.reduce(
    (acc, item) => {
      const keyValue =
        item[key] && item[key][key2] !== undefined && item[key][key2] !== null
          ? item[key][key2]
          : 'no_release';

      if (!acc[keyValue]) {
        acc[keyValue] = [];
      }

      acc[keyValue].push(item);
      return acc;
    },
    {} as Record<string, any[]>,
  );
}
