import { User } from '../../components/shared/multi-select-user-id/multi-select-user';
import { Release } from './types';
import styles from './NotesTable.module.css';

export const TASK_FILTER_OPTIONS = [
  {
    value: 'TASK',
    body: 'Task',
    label: 'Task',
  },
  {
    value: 'QUESTION',
    body: 'Question',
    label: 'Question',
  },
  {
    value: 'ISSUE',
    body: 'Issue',
    label: 'Issue',
  },
  {
    value: 'MESSAGE',
    body: 'Message',
    label: 'Message',
  },
];

export const TASK_FILTER = [
  { body: 'New', value: 'NEW' },
  { body: 'Open', value: 'OPENED' },
  { body: 'Resolved', value: 'RESOLVED' },
  { body: 'Rejected', value: 'REJECTED' },
];

export const SORT_BY = [
  {
    value: 'name',
    label: 'Note name',
  },
  {
    value: 'RELATED_TASK',
    label: 'Related task',
  },
  {
    value: 'DEADLINE',
    label: 'Deadline',
  },
];

export const TYPE = [
  {
    value: 'QUESTION',
    label: 'Question',
  },
  {
    value: 'ISSUE',
    label: 'Issue',
  },
  {
    value: 'MESSAGE',
    label: 'Message',
  },
  {
    value: 'TASK',
    label: 'Task',
  },
];

export const PRIORITY = [
  {
    value: 'LOW',
    label: 'Low',
  },
  {
    value: 'MEDIUM',
    label: 'Medium',
  },
  {
    value: 'HIGH',
    label: 'High',
  },
  {
    value: 'URGENT',
    label: 'Urgent',
  },
];

export const STATUS = [
  {
    value: 'NEW',
    label: 'New',
  },
  {
    value: 'IN_PROGRESS',
    label: 'In progress',
  },
  {
    value: 'RESOLVED',
    label: 'Resolved',
    style: styles.resolvedNote,
  },
  {
    value: 'REJECTED',
    label: 'Rejected',
    style: styles.rejectedNote,
  },
];

export enum NoteStatus {
  NEW = 'NEW',
  IN_PROGRESS = 'IN_PROGRESS',
  RESOLVED = 'RESOLVED',
  REJECTED = 'REJECTED',
}

export enum NotePriority {
  LOW = 'LOW',
  MEDIUM = 'MEDIUM',
  HIGH = 'HIGH',
  URGENT = 'URGENT',
}

export enum NoteType {
  QUESTION = 'QUESTION',
  ISSUE = 'ISSUE',
  MESSAGE = 'MESSAGE',
  TASK = 'TASK',
}

export type NoteComment = {
  noteId: number;
  comment: string;
  createdById: number;
  createdBy: User;
};

export type NoteAssignee = {
  noteId: number;
  note: Note;
  assigneeId: number;
  assignee: User;
};

export type Note = {
  id: number;
  createdAt: string;
  createdById: number;
  createdBy: User;
  updatedById: number;
  updatedBy: User;
  name: string;
  projectId: number | null;
  project: unknown;
  featureId: number | null;
  feature: {
    name: string;
  };
  releaseId: number | null;
  release: Release;
  meetUrl: string;
  status: NoteStatus;
  description: string | null;
  deadline: Date | null;
  priority: NotePriority;
  type: NoteType;
  isHidden: boolean;
  assignees: any;
};
