import React from 'react';
import classNames from 'classnames';

import styles from './chip.module.css';

type ChipProps = {
  label: string;
  customStyles?: string;
};

const Chip = ({ label, customStyles = '' }: ChipProps) => {
  const chipClassName = classNames(styles.chip, {
    [customStyles]: customStyles,
  });
  return <div className={chipClassName}>{label}</div>;
};

export default Chip;
