// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.NoteComment_wrapper__gOuWl {
  border-radius: 8px;
  background-color: var(--white);
  width: 30%;
  min-width: 320px;
  height: 100%;
  min-height: 500px;
  color: var(--main-font);
  padding: 16px;
  margin-left: 4px;
  overflow: scroll;
  position: relative;
}

.NoteComment_header__IItyL {
  display: flex;
  justify-content: space-between;
}

.NoteComment_title__qJ1d7 {
  font-size: 16px;
  font-weight: 500;
}

.NoteComment_text__gsDOh {
  font-size: 16px;
  font-weight: 400;
}

.NoteComment_field__KAJkp {
  margin-top: 8px;
  margin-bottom: 8px;
}

.NoteComment_expand__7kS72 {
  cursor: pointer;
}

.NoteComment_label__75Yu7 {
  font-size: 12px;
}

.NoteComment_description__KLMHI {
  font-size: 13px;
}
`, "",{"version":3,"sources":["webpack://./src/renderer/containers/notes/NoteComment/NoteComment.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,8BAA8B;EAC9B,UAAU;EACV,gBAAgB;EAChB,YAAY;EACZ,iBAAiB;EACjB,uBAAuB;EACvB,aAAa;EACb,gBAAgB;EAChB,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,8BAA8B;AAChC;;AAEA;EACE,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE,eAAe;EACf,kBAAkB;AACpB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,eAAe;AACjB","sourcesContent":[".wrapper {\n  border-radius: 8px;\n  background-color: var(--white);\n  width: 30%;\n  min-width: 320px;\n  height: 100%;\n  min-height: 500px;\n  color: var(--main-font);\n  padding: 16px;\n  margin-left: 4px;\n  overflow: scroll;\n  position: relative;\n}\n\n.header {\n  display: flex;\n  justify-content: space-between;\n}\n\n.title {\n  font-size: 16px;\n  font-weight: 500;\n}\n\n.text {\n  font-size: 16px;\n  font-weight: 400;\n}\n\n.field {\n  margin-top: 8px;\n  margin-bottom: 8px;\n}\n\n.expand {\n  cursor: pointer;\n}\n\n.label {\n  font-size: 12px;\n}\n\n.description {\n  font-size: 13px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `NoteComment_wrapper__gOuWl`,
	"header": `NoteComment_header__IItyL`,
	"title": `NoteComment_title__qJ1d7`,
	"text": `NoteComment_text__gsDOh`,
	"field": `NoteComment_field__KAJkp`,
	"expand": `NoteComment_expand__7kS72`,
	"label": `NoteComment_label__75Yu7`,
	"description": `NoteComment_description__KLMHI`
};
export default ___CSS_LOADER_EXPORT___;
