import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import Confluence from '../../../../assets/icons/confluence';
import Redmine from '../../../../assets/icons/redmine';
import Account from '../../../../assets/icons/account';
import { RoutesData } from '../../../../types/routes/routes';
import { TASK_STATUS } from '../../../../screens/Tasks/create-new-task/constants';
import StatusSelect from '../status-select';
import { taskApi } from '../../../../api/task';
import { TOAST_MESSAGES } from '../../../../constants';
import styles from './TableItem.module.css';

function ReleaseItem({ item }: any): React.JSX.Element {
  const navigate = useNavigate();

  const [taskStatus, setTaskStatus] = useState<any>({});
  const isAssignees = item?.assignees?.length > 0;
  useEffect(() => {
    const status = TASK_STATUS.find((s: any) => {
      return s?.value === item?.status;
    });
    setTaskStatus(status);
  }, []);

  const handleRedirect = () => {
    navigate(`${RoutesData.TaskDetails}/${item.id}`, {
      state: { taskId: item.id },
    });
  };

  const updateStatus = (status: string) => {
    if (status === item?.status) return;
    try {
      taskApi.updateTaskStatus(item.id, { status });
      toast.success(TOAST_MESSAGES.SAVED);
    } catch (error) {
      toast.error(TOAST_MESSAGES.CANT_BE_UPDATED);
    }
  };

  return (
    <tr className={styles.item}>
      <td onClick={handleRedirect} className={styles.releaseDetails}>
        {item?.name}
      </td>
      <td>{item?.priority}</td>
      <td className={styles.releaseDetails}>
        {isAssignees
          ? item?.assignees.map((user: any, index: number) => (
              <div className={styles.tooltip} key={user.id}>
                <span
                  className={styles.avatarContainer}
                  style={{ marginLeft: index === 0 ? '' : '-14px' }}
                >
                  <Account />
                </span>
                <span className={styles.tooltiptext}>
                  {user.assignee?.user?.firstName}{' '}
                  {user.assignee?.user?.lastName}
                </span>
              </div>
            ))
          : null}
      </td>
      <td>
        <div className={styles.linkItem}>
          <Confluence />
          {item?.confluenceLink ? (
            <a href={item?.confluenceLink} target="_blank" rel="noreferrer">
              Link
            </a>
          ) : (
            <span>Link</span>
          )}
        </div>
      </td>
      <td>
        <div className={styles.linkItem}>
          <Redmine />
          {item?.redmineTaskLink ? (
            <a href={item?.redmineTaskLink} target="_blank" rel="noreferrer">
              Link
            </a>
          ) : (
            <span>Link</span>
          )}
        </div>
      </td>
      <td className={styles.tdStatus}>
        <StatusSelect
          status={taskStatus}
          callback={updateStatus}
          options={TASK_STATUS}
        />
      </td>
    </tr>
  );
}

export default ReleaseItem;
