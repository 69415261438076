import React from 'react';
import { IIcon } from '../../types';
import { colors } from '../../constants/colors';

const ClockIcon = ({
  className,
  onClick = undefined,
  color = colors.main_blue,
}: IIcon) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      role="button"
      className={className}
      onClick={onClick}
    >
      <path
        d="M3.33301 2L1.33301 4M14.6663 4L12.6663 2M3.99967 12.6667L2.66634 14M11.9997 12.6667L13.333 14M7.99967 6V8.66667L9.33301 10M7.99967 14C9.41414 14 10.7707 13.4381 11.7709 12.4379C12.7711 11.4377 13.333 10.0811 13.333 8.66667C13.333 7.2522 12.7711 5.89563 11.7709 4.89543C10.7707 3.89523 9.41414 3.33333 7.99967 3.33333C6.58519 3.33333 5.22863 3.89523 4.22844 4.89543C3.22824 5.89563 2.66634 7.2522 2.66634 8.66667C2.66634 10.0811 3.22824 11.4377 4.22844 12.4379C5.22863 13.4381 6.58519 14 7.99967 14Z"
        stroke={color}
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ClockIcon;
