import React, { useState } from 'react';
import styles from './NoteComment.module.css';
import { Note, TASK_FILTER_OPTIONS } from '../constants';
import Hr from '../../../components/shared/hr/hr';
import { DangerModal } from '../../../components/shared';
import Menu from '../../../components/shared/menu/menu';
import { useFormik } from 'formik';
import ChatComponent from './ChatComponent';

type NoteCommentProps = {
  note: Note;
};

const NoteComment = ({ note }: NoteCommentProps) => {
  const type = TASK_FILTER_OPTIONS.find(
    (typeValue) => typeValue.value === note.type,
  );

  const [deleteModalOpened, setDeleteModalOpened] = useState(false);

  const openDeleteModal = () => setDeleteModalOpened(true);
  const closeDeleteModal = () => setDeleteModalOpened(false);

  const deleteForm = useFormik({
    initialValues: {},
    onSubmit: () => {},
  });

  const [mode, setMode] = useState<'EDIT' | 'READ'>('READ');

  const openEdit = () => setMode('EDIT');
  const openRead = () => setMode('READ');

  const actions = [
    {
      name: mode === 'READ' ? 'Edit notes' : 'View note',
      action: mode === 'READ' ? openEdit : openRead,
    },
    {
      name: 'Delete note',
      action: openDeleteModal,
    },
  ];

  return (
    <div className={styles.wrapper}>
      {mode === 'EDIT' ? (
        <div>
          <div className={styles.header}>
            <div className={styles.title}>{type?.label} note</div>

            <div className={styles.expand}>
              <Menu actions={actions} color={'black'} />
            </div>
          </div>
          <ChatComponent noteId={note?.id} />
        </div>
      ) : (
        <>
          <div className={styles.header}>
            <div className={styles.title}>{type?.label} note</div>

            <div className={styles.expand}>
              <Menu actions={actions} color={'black'} />
            </div>
          </div>
          <div className={styles.field}>
            <div className={styles.text}>{note.name}</div>
          </div>
          <div className={styles.field}>
            <div className={styles.label}>Related task</div>
            <div className={styles.description}>{note?.feature?.name}</div>
          </div>
          <div className={styles.field}>
            <div className={styles.label}>Related meeting</div>
            <div className={styles.description}>{note?.meetUrl}</div>
          </div>

          <div className={styles.field}>
            <Hr />
          </div>

          <div className={styles.field}>
            <div className={styles.description}>{note?.description}</div>
          </div>

          {deleteModalOpened && (
            <DangerModal
              title="Delete note"
              text="Are you sure you want to delete this note?"
              closeModal={closeDeleteModal}
              callback={deleteForm.submitForm}
              disabled={deleteForm.isSubmitting}
              dangerBtnText={'Delete'}
            />
          )}
        </>
      )}
    </div>
  );
};

export default NoteComment;
