import { Navigate, Route, Routes } from 'react-router-dom';
import PrivateRoute from '../components/layouts/RoutesWrappers/PrivateRoute';
import Login from '../containers/login/Login';
import UnauthorizedUserRoute from '../components/layouts/RoutesWrappers/UnauthorizedUserRoute';
import routes from './routes';
import { RoutesData, RoutesProps } from '../types/routes/routes';
import Seo from '../components/layouts/Seo';

function Routing() {
  return (
    <Routes>
      <Route element={<UnauthorizedUserRoute />}>
        <Route path="/login" element={<Login />} />
      </Route>
      <Route element={<PrivateRoute />}>
        {routes.map(({ path, component, withNavigation }: RoutesProps) => {
          return (
            <Route
              key={path}
              path={path}
              element={<Seo withNavigation={withNavigation}>{component}</Seo>}
            />
          );
        })}
        <Route
          path="*"
          element={<Navigate to={RoutesData.Planning} replace />}
        />
      </Route>
    </Routes>
  );
}

export default Routing;
