export const SPENDING_TYPE = {
  TEAM_ESTIMATE: 'TEAM_ESTIMATE',
  TEAM_FACT_BEFORE_TESTING: 'TEAM_FACT_BEFORE_TESTING',
  TEAM_FACT_AFTER_TESTING: 'TEAM_FACT_AFTER_TESTING',
};
export const SPENDING_TYPE_CUSTOMER = {
  TEAM_ESTIMATE: 'TEAM_ESTIMATE',
  CUSTOMER_ESTIMATE: 'CUSTOMER_ESTIMATE',
  TEAM_FACT_BEFORE_TESTING: 'TEAM_FACT_BEFORE_TESTING',
  TEAM_FACT_AFTER_TESTING: 'TEAM_FACT_AFTER_TESTING',
};
export const SPENDING_TYPE_VALUES = {
  TEAM_ESTIMATE: 'Estimated',
  CUSTOMER_ESTIMATE: 'For the client',
  TEAM_FACT_BEFORE_TESTING: 'Fact before testing',
  TEAM_FACT_AFTER_TESTING: 'Fact after testing',
};
