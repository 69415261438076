import { ReactNode } from 'react';

export type RoutesProps = {
  component: JSX.Element | ReactNode;
  path: string;
  exact?: boolean;
  withNavigation?: boolean;
};

export enum RoutesData {
  BaseRoute = '/app',
  Tasks = '/app/tasks',
  CreateTask = '/app/tasks/create-task',
  Planning = '/app/tasks/planning',
  Estimate = '/app/tasks/estimate-fact',
  SDLC = '/app/tasks/sdlc',
  EnvironmentsDashboard = '/app/environments-dashboard',
  DEV = '/app/environments-dashboard/dev',
  QA = '/app/environments-dashboard/qa',
  Stage = '/app/environments-dashboard/stage',
  Prod = '/app/environments-dashboard/prod',
  Notes = '/app/notes',
  Releases = '/app/releases',
}
