import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { createPortal } from 'react-dom';

import ArrowSelectIcon from '../../../assets/icons/arrow-select';
import useOutsideClick from '../../../hooks/useOutsideClick';
import { colors } from '../../../constants/colors';
import { StatusSelectType } from './types';
import styles from './Release.module.css';

function StatusSelect({
  status,
  options,
  callback,
  disabled = false,
  showArrow = true,
  containerStyles = {},
}: StatusSelectType): React.JSX.Element {
  const ref = useRef<HTMLDivElement | null>(null);

  const [showListOptions, setShowListOptions] = useState(false);
  const [valueStatus, setValueStatus] = useState(status);
  const [position, setPosition] = useState({ top: 0, left: 0 });

  useOutsideClick(ref, () => setShowListOptions(false));

  useEffect(() => {
    setValueStatus(status);
  }, [status]);

  useEffect(() => {
    if (showListOptions && ref.current) {
      const rect = ref.current.getBoundingClientRect();
      setPosition({
        top: rect.bottom + window.scrollY,
        left: rect.left + window.scrollX,
      });
    }
  }, [showListOptions]);

  const onChangeHandler = (v: any) => {
    setValueStatus(v);
    setShowListOptions(false);
    callback(v?.value);
  };
  const statusClassName = classNames(styles.status, {
    [styles.status__open]: showListOptions,
    [styles[valueStatus?.style]]: valueStatus?.style,
    [styles[`${valueStatus?.style}__open`]]: showListOptions,
  });
  const iconClassName = classNames(styles.arrowBtn, {
    [styles.arrowBtn__open]: showListOptions,
  });

  return (
    <div
      className={styles.statusContainer}
      ref={ref}
      style={containerStyles}
      onClick={() =>
        disabled ? undefined : setShowListOptions(!showListOptions)
      }
      role="button"
    >
      <span className={statusClassName}>{valueStatus?.label}</span>
      {disabled ? null : (
        <>
          {showArrow && (
            <ArrowSelectIcon
              className={iconClassName}
              color={showListOptions ? colors.dark_blue : colors.main_font}
            />
          )}
          {showListOptions
            ? createPortal(
                <div
                  className={styles.container__options}
                  style={{
                    top: position.top,
                    left: position.left,
                  }}
                >
                  {options.map((option: any) => {
                    return (
                      <span
                        className={styles.option}
                        key={option.value}
                        onMouseDown={(e) => {
                          e.preventDefault();
                          onChangeHandler(option);
                        }}
                        role="button"
                      >
                        {option.label}
                      </span>
                    );
                  })}
                </div>,
                document.body,
              )
            : null}
        </>
      )}
    </div>
  );
}

export default StatusSelect;
