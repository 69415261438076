import React from 'react';

import ExitIcon from '../../../../assets/icons/exit-icon';
import ModalWrapper from '../modal-wrapper';
import { IViewModal } from '../types';

import styles from './Modal.module.css';

const Modal: React.FC<IViewModal> = ({
  callback,
  text,
  title,
  subTitle,
  width,
}) => {
  return (
    <ModalWrapper>
      <div className={styles.modal} style={width ? { width } : {}}>
        {title && <div className="modal__title">{title}</div>}
        {subTitle && <div className="modal__body">{subTitle}</div>}
        {text && <div className="modal__body">{text}</div>}
        <ExitIcon className={styles.icon} onClick={callback} />
      </div>
    </ModalWrapper>
  );
};

export default Modal;
