import { memo, useEffect, useMemo, useState } from 'react';
import { useFormik } from 'formik';
import { toast } from 'react-toastify';
import { number, object } from 'yup';
import { useMutation } from 'react-query';

import {
  filterEstimateByType,
  ROLE_SYSTEM,
  totalEstimate,
  findEstimateByType,
} from './constants';
import {
  HTTP_STATUSES,
  SPENDING_TYPE_CUSTOMER,
  SPENDING_TYPE,
  TOAST_MESSAGES,
} from '../../../constants';
import { estimateTaskApi } from '../../../api/estimate';
import SandTimerIcon from '../../../assets/icons/sand-timer';
import ArrowSelectIcon from '../../../assets/icons/arrow-select';
import { groupByKey } from '../../../utils';
import { colors } from '../../../constants/colors';
import PencilIcon from '../../../assets/icons/pencil';
import EditEstimateModal from './modals/edit-estimate-modal';
import { SPENDING_TYPE_VALUES } from '../../../constants/estimate-tasks';
import EstimateHistory from './modals/estimate-history-modal';
import DecompositionIcon from '../../../assets/icons/decomposition-icon';
import { isObjectEmpty } from '../../../utils/objects/isObjectEmpty';
import styles from './TaskView.module.css';

const emptyValue = <span style={{ color: colors.icon_gray }}>—</span>;

const formValidation = object().shape({
  estimate: number()
    .min(1)
    .when('decompositionMode', {
      is: (val: boolean) => !val,
      then: (schema) => schema.required(),
      otherwise: (schema) => schema.nullable(),
    }),
});
const initialCurrentItemState = {
  type: '',
  user: null,
  role: null,
  estimate: '',
  estimateId: '',
  decompositionMode: false,
  decomposition: [],
  addDecomposition: [],
};

interface TaskEstimateItemProps {
  taskId: number;
  editPMAvailable: boolean;
  role: any;
  users: any[];
  showCustomer: boolean;
  updateByRoleID: string | number;
  getSummary: () => void;
  setUpdateByRoleID: (value: string | number) => void;
}
const TaskEstimateItem = ({
  taskId,
  editPMAvailable,
  role,
  users,
  showCustomer,
  updateByRoleID,
  setUpdateByRoleID,
  getSummary,
}: TaskEstimateItemProps) => {
  const [historyModal, setHistoryModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [currentItem, setCurrentItem] = useState<any>(initialCurrentItemState);
  const [showRoleDetails, setShowRoleDetails] = useState(false);
  const [cachedData, setCachedData] = useState<any>([]);
  const getEstimateByRole = async () => {
    if (taskId && role?.id) {
      const params = {
        sort: 'id',
        order: 'ASC',
        filter: JSON.stringify({ featureId: taskId, roleId: role?.id }),
      };
      const estimate = await estimateTaskApi.getTaskSpendingList(params);
      return estimate.data?.list;
    }
  };
  const { mutate: getData, data: estimateDetails } = useMutation(
    getEstimateByRole,
    {
      onSuccess: (newData) => {
        setCachedData(newData);
      },
    },
  );

  const details = estimateDetails || cachedData;
  useEffect(() => {
    getData();
  }, []);
  useEffect(() => {
    if (updateByRoleID == role?.id) {
      getData();
      setUpdateByRoleID('');
    }
  }, [updateByRoleID, role?.id]);

  const roleName = useMemo(
    () => ROLE_SYSTEM.find((r: any) => r.value === role?.systemName),
    [role],
  );
  const renderDetailsByType = useMemo(
    () =>
      Object.values(showCustomer ? SPENDING_TYPE_CUSTOMER : SPENDING_TYPE).map(
        (type: string) => {
          return (
            filterEstimateByType(details, type) || [
              {
                roleId: role?.id,
                type,
                estimate: 0,
              },
            ]
          );
        },
      ),
    [taskId, details, showCustomer],
  );
  const detailsGroupedByUserId = useMemo((): any[] => {
    const groupData = details && groupByKey(details, 'userId', 'no_user');

    return groupData
      ? Object.entries(groupData)
          .map(([userId, data]) => ({
            userId,
            data,
          }))
          .reverse()
      : [];
  }, [details]);

  const renderEditAction = (item: any) => {
    const editDetailedItem = (event: any) => {
      event.stopPropagation();
      setCurrentItem((prevValues: any) => ({
        ...prevValues,
        ...item,
      }));
      setEditModal(true);
    };
    const { decompositionMode } = item;
    return (
      <>
        {decompositionMode ? (
          <span
            role="button"
            onClick={editDetailedItem}
            className={styles.iconDec}
          >
            <DecompositionIcon />
          </span>
        ) : editPMAvailable ? (
          <span
            role="button"
            onClick={editDetailedItem}
            className={styles.iconEdit}
          >
            <PencilIcon />
          </span>
        ) : null}
      </>
    );
  };

  const form = useFormik({
    initialValues: currentItem,
    enableReinitialize: true,
    onSubmit: async (values: any) => {
      const arrSumDecomposition = values.decompositionMode
        ? values.decomposition
            .map((el: any) => ({
              ...el,
              estimate: Number(el.estimate),
              featureTimeSpendingId: values.estimateId,
            }))
            .concat(
              values.addDecomposition
                .filter((el: any) => el?.name && el?.estimate)
                .map(({ name, estimate }: any) => ({
                  name,
                  estimate: Number(estimate),
                })),
            )
        : [];
      let body;
      let response = { status: 0 };
      if (values.estimateId) {
        body = {
          userId: values.user?.value || null,
          estimate: values?.estimate,
          decomposition: arrSumDecomposition,
        };
        response = await estimateTaskApi.updateTaskEstimateById(
          values.estimateId,
          body,
        );
      } else {
        body = {
          featureId: Number(taskId),
          type: values.type.value,
          timeSpending: [
            {
              userId: values.user.value,
              roleId: role?.id,
              estimate: Number(values.estimate),
              decomposition: arrSumDecomposition,
            },
          ],
        };
        response = await estimateTaskApi.addTaskEstimate(body);
      }
      if (
        response.status === HTTP_STATUSES.ok ||
        response.status === HTTP_STATUSES.created
      ) {
        toast.success(TOAST_MESSAGES.UPDATED);
        setEditModal(false);
        getData();
        getSummary();
      } else {
        toast.error(TOAST_MESSAGES.CANT_BE_UPDATED);
      }
    },
    validationSchema: formValidation,
  });
  const isDetailsEmpty = isObjectEmpty(detailsGroupedByUserId);

  return (
    <>
      <tr className={styles.estimateItem}>
        <td>
          <div
            className={styles.detailsHeader}
            style={{
              margin: '0',
              paddingRight: '16px',
              color: showRoleDetails ? colors.main_blue : colors.main_font,
            }}
          >
            {roleName?.label}
            <ArrowSelectIcon
              onClick={
                isDetailsEmpty
                  ? undefined
                  : () => setShowRoleDetails(!showRoleDetails)
              }
              className={isDetailsEmpty ? styles.iconNon : styles.icon}
              color={
                isDetailsEmpty
                  ? colors.icon_gray
                  : showRoleDetails
                    ? colors.main_blue
                    : colors.main_font
              }
            />
          </div>
        </td>
        {renderDetailsByType.map((detail: any) => {
          return (
            <td
              key={detail?.[0]?.id}
              className={showRoleDetails ? styles.roles : ''}
            >
              {totalEstimate(detail, 'estimate') || emptyValue}
            </td>
          );
        })}
        <td rowSpan={showRoleDetails ? detailsGroupedByUserId?.length + 1 : 1}>
          <span
            onClick={editPMAvailable ? () => setHistoryModal(true) : undefined}
            role="button"
          >
            <SandTimerIcon
              color={editPMAvailable ? colors.dark_blue : colors.icon_gray}
            />
          </span>
        </td>
      </tr>
      {showRoleDetails
        ? detailsGroupedByUserId.map((row) => {
            const isNoUser = row.userId === 'no_user';

            const user = isNoUser
              ? { label: 'No name' }
              : users.slice(2)?.find((u: any) => u.value == row.userId);
            const spendingTypes = [
              'TEAM_ESTIMATE',
              'CUSTOMER_ESTIMATE',
              'TEAM_FACT_BEFORE_TESTING',
              'TEAM_FACT_AFTER_TESTING',
            ] as const;
            return (
              <tr className={styles.detailedEstimateItem} key={row.userId}>
                <td style={{ paddingLeft: '10%', textAlign: 'start' }}>
                  {user?.label}
                </td>
                {spendingTypes.map((type) => {
                  const estimateValue = isNoUser
                    ? totalEstimate(
                        filterEstimateByType(row?.data, type),
                        'estimate',
                      )
                    : findEstimateByType(row?.data, type) || '—';
                  const decompositionArray =
                    row?.data
                      ?.filter(
                        (d: any) =>
                          !!d?.decomposition?.length && d?.type === type,
                      )?.[0]
                      ?.decomposition?.map((el: any) => ({
                        name: el.name,
                        id: el.id,
                        estimate: el.estimate,
                      })) || [];
                  const isDecompositionExist = Boolean(
                    decompositionArray?.length,
                  );
                  return type === SPENDING_TYPE_CUSTOMER.CUSTOMER_ESTIMATE &&
                    !showCustomer ? null : (
                    <td key={type}>
                      {isNoUser ? estimateValue : estimateValue?.estimate}
                      {renderEditAction({
                        user,
                        type: {
                          value: type,
                          label: SPENDING_TYPE_VALUES[type],
                        },
                        role: roleName?.label,
                        estimate: isNoUser
                          ? estimateValue
                          : estimateValue?.estimate,
                        estimateId: isNoUser
                          ? filterEstimateByType(row?.data, type)?.[0]?.id
                          : estimateValue?.estimateId,
                        decomposition: decompositionArray,
                        decompositionMode: isDecompositionExist,
                      })}
                    </td>
                  );
                })}
                <td></td>
              </tr>
            );
          })
        : null}
      {editModal ? (
        <EditEstimateModal
          form={form}
          close={() => {
            setEditModal(false);
            setCurrentItem(initialCurrentItemState);
          }}
          editPMAvailable={editPMAvailable}
        />
      ) : null}
      {historyModal ? (
        <EstimateHistory
          roleId={role?.id}
          role={roleName}
          close={() => setHistoryModal(false)}
          taskId={taskId}
        />
      ) : null}
    </>
  );
};

export default memo(TaskEstimateItem);
