export const getStatus = (status: 'NEW' | 'WAITING' | 'RESOLVED') => {
  if (status !== 'RESOLVED') return 'Unresolved';
  return 'Resolved';
};

export const statusForModule = (isDeployed: boolean | undefined) => {
  if (isDeployed === undefined || isDeployed === null) return 'Off';
  return isDeployed ? 'True' : 'False';
};

export const TASK_STATUS = {
  NEW: 'NEW',
  RESOLVED_NEW: 'RESOLVED||NEW',
  WAITING: 'WAITING',
};

export const LIST_TYPE = {
  ACTIVE: 'active',
  WAITING: 'waiting',
};
