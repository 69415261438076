import api from './axios.config';

const defaultParams = { skip: 0, take: 100, sort: 'id', order: 'ASC' };

const getTaskSpendingSumList = (params = defaultParams) => {
  return api.get<any>('/feature-time-spending/summary', { params });
};
const getTaskEstimate = (id: string | number) => {
  return api.get<any>(`/feature-time-spending/${id}`);
};
const addTaskEstimate = (body: any) => {
  return api.post<any>('/feature-time-spending', body);
};
const updateTaskEstimate = (body: any) => {
  return api.patch<any>('/feature-time-spending', body);
};
const deleteTaskEstimate = (id: string | number) => {
  return api.get<any>(`/feature-time-spending/${id}`);
};
export const estimateTaskApi = {
  addTaskEstimate,
  deleteTaskEstimate,
  getTaskSpendingSumList,
  getTaskEstimate,
  updateTaskEstimate,
};
