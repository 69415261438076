import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import classNames from 'classnames';
import { useFormik } from 'formik';
import { object, string, number } from 'yup';

import ExitIcon from '../../../../assets/icons/exit-icon';
import {
  ApproveModal,
  CustomInput,
  Select,
  Switch,
  ToggleButtonGroup,
} from '../../../../components/shared';
import {
  HTTP_STATUSES,
  SPENDING_TYPE_CUSTOMER,
  TOAST_MESSAGES,
} from '../../../../constants';
import { ROLE_SYSTEM } from '../constants';
import DecompositionIcon from '../../../../assets/icons/decomposition-icon';
import Decomposition from './decomposition';
import { estimateTaskApi } from '../../../../api/estimate';
import { isObjectEmpty } from '../../../../utils/objects/isObjectEmpty';
import styles from './Modals.module.css';

const assigneeOptions = [
  { value: 'role', label: 'Role' },
  { value: 'user', label: 'Assigned' },
];
const typeOptions = [
  { value: SPENDING_TYPE_CUSTOMER.TEAM_ESTIMATE, label: 'Estimate' },
  {
    value: SPENDING_TYPE_CUSTOMER.CUSTOMER_ESTIMATE,
    label: 'Estimate for Customer',
  },
  {
    value: SPENDING_TYPE_CUSTOMER.TEAM_FACT_BEFORE_TESTING,
    label: 'Fact before testing',
  },
  {
    value: SPENDING_TYPE_CUSTOMER.TEAM_FACT_AFTER_TESTING,
    label: 'Fact after testing',
  },
];
const formValidation = object().shape({
  userId: string().when('selectType', {
    is: (val: string) => val === 'user',
    then: (schema) => schema.required(),
    otherwise: (schema) => schema.nullable(),
  }),
  roleId: string().when('selectType', {
    is: (val: string) => val === 'role',
    then: (schema) => schema.required(),
    otherwise: (schema) => schema.nullable(),
  }),
  estimate: number()
    .min(1)
    .when('decompositionMode', {
      is: (val: boolean) => !val,
      then: (schema) => schema.required(),
      otherwise: (schema) => schema.nullable(),
    }),
});

interface IAddEstimateModal {
  taskId: number;
  close: () => void;
  type: 'user' | 'pm' | 'lead';
  users: any;
  roles: any;
  setAddEstimate: (value: boolean) => void;
  getEstimate: () => void;
  setUpdateByRoleID: (id: number | string) => void;
}

const AddEstimateModal = ({
  taskId,
  close,
  type,
  users,
  roles,
  setAddEstimate,
  getEstimate,
  setUpdateByRoleID,
}: IAddEstimateModal) => {
  const form = useFormik({
    initialValues: {
      featureId: taskId,
      selectType: '',
      decompositionMode: false,
      addDecomposition: [{ id: Date.now(), name: '', estimate: '' }],
    },
    onSubmit: async (values: any, { resetForm }) => {
      const decomposition = values?.decompositionMode
        ? values.addDecomposition
            .filter((el: any) => el?.name && el?.estimate)
            .map(({ name, estimate }: any) => ({
              name,
              estimate: Number(estimate),
            }))
        : [];

      const body = {
        featureId: Number(taskId),
        type: values.type,
        timeSpending: [
          {
            userId: values.userId,
            roleId: values.roleId,
            estimate: isNaN(values.estimate) ? null : Number(values.estimate),
            decomposition,
          },
        ],
      };
      const response = await estimateTaskApi.addTaskEstimate(body);

      if (response.status === HTTP_STATUSES.created) {
        toast.success(TOAST_MESSAGES.SAVED);
        getEstimate();
        setAddEstimate(false);
        setUpdateByRoleID(values.roleId);
        resetForm();
      } else if (response.status === HTTP_STATUSES.bad_request) {
        if (response?.data?.message === 'ALREADY_EXISTS') {
          toast.error(TOAST_MESSAGES.ALREADY_EXISTS);
        }
      } else {
        toast.error(TOAST_MESSAGES.CANT_BE_UPDATED);
        setUpdateByRoleID('');
      }
    },
    validationSchema: formValidation,
  });
  const [touched, setTouched] = useState(false);

  const isLead = type === 'lead';
  const isUser = type === 'user';

  useEffect(() => {
    if (isUser) {
      form?.setFieldValue('type', SPENDING_TYPE_CUSTOMER.TEAM_ESTIMATE);
      form?.setFieldValue('userId', users.id);
      form?.setFieldValue('roleId', users.userProjects[0]?.roleId);
    }
  }, [users, type]);

  const isDecomposition = form?.values.decompositionMode;

  const rolesOptions = roles.map((r: any) => ({
    value: r.id,
    label: ROLE_SYSTEM.find((rs: any) => rs.value === r.systemName)?.label,
  }));
  const usersOptions = !isUser && users.slice(2);

  const handleAssignee = (fieldName: string, v: any) => {
    // here we use id from the root userObj
    if (fieldName === 'roleId') {
      form?.setFieldValue(fieldName, v.value);
      form?.setFieldValue('userId', null);
    } else {
      form?.setFieldValue(fieldName, v.value);
      form?.setFieldValue('roleId', v.roleId);
    }
  };
  const renderForm = (typeView: 'user' | 'pm') => {
    switch (typeView) {
      case 'user':
        return (
          <>
            <div className={styles.modalTitle}>Estimate Task</div>
            <p>
              Please specify the estimated time you need to complete this task.
            </p>
            <CustomInput
              label="Estimated time"
              placeHolder="0"
              name="estimate"
              value={form?.values?.estimate}
              onChange={(v: string) => {
                form?.setFieldValue('estimate', v);
                setTouched(true);
              }}
            />
          </>
        );
      case 'pm':
        return (
          <>
            <div className={styles.modalTitle}>Add Estimate & Fact</div>
            <ToggleButtonGroup
              options={assigneeOptions}
              onChange={(value) => {
                form?.setFieldValue('selectType', value);
                if (value === 'role') {
                  form?.setFieldValue('userId', null);
                }
              }}
              defaultValue="role"
            />
            {form?.values.selectType === 'role' ? (
              <div>
                <p className={styles.label}>Role</p>
                <Select
                  options={rolesOptions}
                  onChange={(o: any) => handleAssignee('roleId', o)}
                  emptyText=""
                  value={form?.values.roleId}
                  name="roleId"
                />
              </div>
            ) : (
              <div>
                <p className={styles.label}>Assignee</p>
                <Select
                  options={usersOptions}
                  onChange={(o: any) => handleAssignee('userId', o)}
                  emptyText=""
                  value={form?.values.userId}
                  name="userId"
                />
              </div>
            )}
            <div>
              <p className={styles.label}>Type</p>
              {isDecomposition ? (
                <span style={{ fontWeight: '400', fontSize: '14px' }}>
                  Estimate
                </span>
              ) : (
                <ToggleButtonGroup
                  options={typeOptions}
                  onChange={(value) => form?.setFieldValue('type', value)}
                  customStyle={{ gridTemplateColumns: 'repeat(2, 1fr)' }}
                  defaultValue={SPENDING_TYPE_CUSTOMER.TEAM_ESTIMATE}
                />
              )}
            </div>
            {isDecomposition ? (
              <Decomposition
                form={form}
                data={[]}
                setTouched={setTouched}
                editMode={false}
                editPMAvailable
              />
            ) : (
              <CustomInput
                label="Time"
                placeHolder="0"
                name="estimate"
                value={form?.values?.estimate}
                onChange={(v: string) => {
                  form?.setFieldValue('estimate', v);
                  setTouched(true);
                }}
              />
            )}

            <div className={styles.decompositionWrapper}>
              <div className={styles.decompositionSwitch}>
                <Switch
                  initialState={form?.initialValues.decompositionMode}
                  customOnIcon={<DecompositionIcon />}
                  handleSwitch={(v: boolean) => {
                    form?.setFieldValue('decompositionMode', v);
                    form?.setFieldValue(
                      'type',
                      SPENDING_TYPE_CUSTOMER.TEAM_ESTIMATE,
                    );
                  }}
                />
                <span>{isDecomposition ? 'Decomposition' : 'Single task'}</span>
              </div>
            </div>
          </>
        );
      default:
        break;
    }
  };
  const wrapperClassName = classNames(styles.addWrapper, {
    [styles.addWrapperDec]: isDecomposition,
  });

  return (
    <ApproveModal
      btnText="Save"
      callback={form?.submitForm}
      closeModal={close}
      disabled={form?.isSubmitting || !touched || !isObjectEmpty(form.errors)}
    >
      <div className={wrapperClassName}>
        <span role="button" onClick={close} className={styles.closeBtn}>
          <ExitIcon />
        </span>
        <>{renderForm(isLead ? 'pm' : type)}</>
      </div>
    </ApproveModal>
  );
};

export default AddEstimateModal;
