import React from 'react';

const Arrow = ({ rotate = false }: { rotate?: boolean }) => {
  return (
    <div
      style={
        rotate
          ? {
              transform: 'rotateZ(180deg)',
              display: 'flex',
              alignItems: 'center',
            }
          : { display: 'flex', alignItems: 'center' }
      }
    >
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M7.99998 3.33301L7.99998 12.6663M7.99998 12.6663L12.6666 7.99968M7.99998 12.6663L3.33331 7.99967"
          stroke="#0B1215"
          strokeWidth="1.3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
};

export default Arrow;
