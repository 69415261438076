import React from 'react';
import { useNavigate } from 'react-router-dom';
import { RoutesData } from '../../../../types/routes/routes';
import {
  estimateForFE,
  findEstimateByKey,
  findTotalByType,
  ROLE_SYSTEM,
  sumTestingValue,
} from '../constants';
import { SPENDING_TYPE_CUSTOMER } from '../../../../constants';
import styles from './TableItem.module.css';

const rolesBeforeFE = [
  ROLE_SYSTEM.BA,
  ROLE_SYSTEM.DESIGNER,
  ROLE_SYSTEM.TECH_LEAD,
  ROLE_SYSTEM.BE_DEV,
];
const rolesAfterFE = [ROLE_SYSTEM.QA, ROLE_SYSTEM.DEVOPS, ROLE_SYSTEM.PM];

function EstimateRow({ item, role, isClientView }: any) {
  return (
    <td>
      <div className={styles.estimateRow}>
        <span className={styles.estimate}>
          {findEstimateByKey(
            item?.timeSpendingSummary,
            SPENDING_TYPE_CUSTOMER.TEAM_ESTIMATE,
            role,
          )}
        </span>
        {isClientView && (
          <span className={styles.customer}>
            {findEstimateByKey(
              item?.timeSpendingSummary,
              SPENDING_TYPE_CUSTOMER.CUSTOMER_ESTIMATE,
              role,
            )}
          </span>
        )}
        <span className={styles.fact}>
          {sumTestingValue(
            findEstimateByKey(
              item?.timeSpendingSummary,
              SPENDING_TYPE_CUSTOMER.TEAM_FACT_AFTER_TESTING,
              role,
            ),
            findEstimateByKey(
              item?.timeSpendingSummary,
              SPENDING_TYPE_CUSTOMER.TEAM_FACT_BEFORE_TESTING,
              role,
            ),
          )}
        </span>
      </div>
    </td>
  );
}

function EstimateItem({ item, isClientView }: any): React.JSX.Element {
  const navigate = useNavigate();

  const handleRedirect = () => {
    navigate(`${RoutesData.TaskDetails}/${item.id}`, {
      state: { taskId: item.id },
    });
  };

  return (
    <tr className={styles.item}>
      <td onClick={handleRedirect} className={styles.estimateName}>
        {item?.name}
      </td>

      {rolesBeforeFE.map((role) => (
        <EstimateRow
          key={role}
          item={item}
          role={role}
          isClientView={isClientView}
        />
      ))}

      {/* FE sum */}
      <td>
        <div className={styles.estimateRow}>
          <span className={styles.estimate}>
            {estimateForFE(
              item?.timeSpendingSummary,
              SPENDING_TYPE_CUSTOMER.TEAM_ESTIMATE,
            )}
          </span>
          {isClientView && (
            <span className={styles.customer}>
              {estimateForFE(
                item?.timeSpendingSummary,
                SPENDING_TYPE_CUSTOMER.CUSTOMER_ESTIMATE,
              )}
            </span>
          )}
          <span className={styles.fact}>
            {sumTestingValue(
              estimateForFE(
                item?.timeSpendingSummary,
                SPENDING_TYPE_CUSTOMER.TEAM_FACT_AFTER_TESTING,
              ),
              estimateForFE(
                item?.timeSpendingSummary,
                SPENDING_TYPE_CUSTOMER.TEAM_FACT_BEFORE_TESTING,
              ),
            )}
          </span>
        </div>
      </td>

      {rolesAfterFE.map((role) => (
        <EstimateRow
          key={role}
          item={item}
          role={role}
          isClientView={isClientView}
        />
      ))}
      {/* Total */}
      <td>
        <div className={styles.estimateRow}>
          <span className={styles.estimate}>
            {findTotalByType(
              item?.timeSpendingSummaryTotal,
              SPENDING_TYPE_CUSTOMER.TEAM_ESTIMATE,
            )}
          </span>
          {isClientView && (
            <span className={styles.customer}>
              {findTotalByType(
                item?.timeSpendingSummaryTotal,
                SPENDING_TYPE_CUSTOMER.CUSTOMER_ESTIMATE,
              )}
            </span>
          )}
          <span className={styles.fact}>
            {sumTestingValue(
              findTotalByType(
                item?.timeSpendingSummaryTotal,
                SPENDING_TYPE_CUSTOMER.TEAM_FACT_AFTER_TESTING,
              ),
              findTotalByType(
                item?.timeSpendingSummaryTotal,
                SPENDING_TYPE_CUSTOMER.TEAM_FACT_BEFORE_TESTING,
              ),
            )}
          </span>
        </div>
      </td>
    </tr>
  );
}

export default EstimateItem;
