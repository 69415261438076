import React from 'react';
import { useNavigate } from 'react-router-dom';

import NonBorderBtn from '../buttons/NonBorderBtn/NonBorderBtn';
import Tabs from '../tabs/tabs';
import { RoutesData } from '../../../types/routes/routes';
import CustomInput from '../inputs';

import { IPageHeader } from './types';
import styles from './Header.module.css';

function PageHeader({
  navigateArr,
  leftSideSideChildren,
  rightSideChildren,
  searchValue,
  searchByString,
  setSearchValue,
  setSearchByString,
  disableSearch = false,
}: IPageHeader): React.JSX.Element {
  const navigate = useNavigate();

  return (
    <div className={styles.wrapper}>
      <div className={styles.second_layer_wrapper}>
        <div className={styles.leftSide}>
          {leftSideSideChildren ? (
            leftSideSideChildren
          ) : (
            <CustomInput
              view="startAdornment"
              className={styles.searchInput}
              containerClassName={styles.searchInput}
              onChange={setSearchValue}
              onClear={() => {
                setSearchValue && setSearchValue('');
                setSearchByString && setSearchByString(!searchByString);
              }}
              onSearch={() => {
                setSearchByString && setSearchByString(!searchByString);
              }}
              onEnter={() => {
                setSearchByString && setSearchByString(!searchByString);
              }}
              value={searchValue}
              withClear
              id="searchInput"
              disabled={disableSearch}
            />
          )}
        </div>
        <div className={styles.middle}>
          <div className={styles.tabWrapper}>
            <Tabs navigateArr={navigateArr} />
          </div>
        </div>
        <div className={styles.rightSide}>
          {rightSideChildren ? (
            rightSideChildren
          ) : (
            <NonBorderBtn
              text="New task"
              onClick={() => navigate(RoutesData.CreateTask)}
              withIcon
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default PageHeader;
