import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import { useMutation } from 'react-query';

import ContentPageContainer from '../../../components/layouts/ContentPageContainer/content-page-container';
import { releaseApi } from '../../../api/release';
import { HTTP_STATUSES, TOAST_MESSAGES } from '../../../constants';
import { RoutesData } from '../../../types/routes/routes';
import { DangerModal, NonBorderBtn } from '../../../components/shared';
import DeleteIcon from '../../../assets/icons/delete-icon';
import BackNavIcon from '../../../assets/icons/back-nav-icon';
import { Role } from '../../../store/roleStore';
import TaskDetails from './task-details';
import TaskProgress from './task-progress';
import TaskEstimate from './task-estimates';
import TaskNotes from './task-notes';
import { taskApi } from '../../../api/task';
import styles from './TaskView.module.css';

const TaskView = ({ roles, currentUser, users }: any) => {
  const location = useLocation();
  const navigate = useNavigate();
  const taskId = location?.state?.taskId || location.pathname.split('/').at(-1);

  const [editAvailable, setIsEditAvailable] = useState(false);
  const [releasesData, setReleases] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const { mutate: getReleases } = useMutation(releaseApi.getReleasesList, {
    onSuccess: (releases: any) => {
      setReleases(
        releases?.data?.list?.map((el: any) => {
          return {
            label: el?.releaseNumber,
            value: el?.id,
            planedDate: el?.planedDate,
          };
        }),
      );
    },
  });

  useEffect(() => {
    const params = {
      skip: 0,
      take: 10,
      sort: 'createdAt',
      order: 'ASC',
    };
    getReleases(params);
  }, [getReleases]);

  useEffect(() => {
    if (!roles?.length || !currentUser?.userProjects?.length) return;
    const pmRole = roles && roles.find((r: Role) => r?.systemName === 'PM');
    if (pmRole?.id === currentUser?.userProjects[0]?.roleId) {
      setIsEditAvailable(true);
    }
  }, [currentUser, roles]);

  const deleteTask = async () => {
    const response = await taskApi.deleteTask(taskId);
    if (response.status === HTTP_STATUSES.ok) {
      toast.success(TOAST_MESSAGES.DELETED);
      navigate(RoutesData.Tasks);
    } else {
      toast.error(TOAST_MESSAGES.COMMON_ERROR);
    }
  };

  return (
    <>
      <ToastContainer />
      <ContentPageContainer>
        <div className={styles.taskDetailsHeader}>
          <div
            className={styles.navigateBtn}
            onClick={() => navigate(RoutesData.Tasks)}
            role="button"
          >
            <BackNavIcon className={styles.marginRight} />
            Task View
          </div>
          <NonBorderBtn
            text="Delete"
            onClick={() => setShowDeleteModal(!showDeleteModal)}
            classNameBtn={styles.deleteBtn}
            icon={<DeleteIcon />}
          />
        </div>

        <div className={styles.content}>
          <div
            className={styles.blockWrapper}
            style={{ background: 'transparent', padding: '0', gap: '4px' }}
          >
            <TaskDetails
              editAvailable={editAvailable}
              taskId={taskId}
              releases={releasesData}
            />
            <div className={styles.blockWrapper}>
              <TaskEstimate
                editPMAvailable={editAvailable}
                taskId={taskId}
                roles={roles}
                users={users}
                currentUser={currentUser}
              />
            </div>
            <div className={styles.blockWrapper}>
              <TaskNotes taskId={taskId} />
            </div>
          </div>
          <div>
            <div className={styles.blockWrapper}>
              <TaskProgress taskId={taskId} editAvailable={editAvailable} />
            </div>
          </div>
        </div>
      </ContentPageContainer>
      {showDeleteModal ? (
        <DangerModal
          callback={deleteTask}
          closeModal={() => setShowDeleteModal(false)}
          text="Are you sure you want to delete this task?"
          title="Delete"
        />
      ) : null}
    </>
  );
};

export default TaskView;
