export const TASK_STATUS = [
  { value: 'NOT_STARTED', label: 'Not started' },
  { value: 'BRS_RECEIVED', label: 'BRs received' },
  { value: 'ANALYSIS', label: 'Analysis' },
  { value: 'DEVELOPMENT', label: 'Development' },
  { value: 'TESTING', label: 'Testing' },
  { value: 'UAT', label: 'UAT' },
  { value: 'READY_TO_RELEASE', label: 'Ready to release' },
  { value: 'RELEASED', label: 'Released' },
];
export const ESTIMATE_HEADERS = [
  { title: 'Name', style: { width: '32%' } },
  { title: 'Estimated', changeFlex: 'center', style: { width: '16%' } },
  {
    title: 'Fact before testing',
    changeFlex: 'center',
  },
  {
    title: 'Fact after testing',
    changeFlex: 'center',
  },
  { title: '', style: { width: '6%' } },
];
export const NOTES_HEADERS = [
  { title: 'Note', style: { width: '40%' } },
  { title: 'Priority', style: { width: '10%' } },
  { title: 'Assignee' },
  { title: 'Deadline', style: { width: '12%' } },
  { title: 'Status', style: { width: '13%' } },
];
export const ESTIMATE_HEADERS_CLIENT = [
  { title: 'Name', style: { width: '32%' } },
  { title: 'Estimated', changeFlex: 'center', style: { width: '16%' } },
  { title: 'For the client', changeFlex: 'center' },
  {
    title: 'Fact before testing',
    style: { width: '16%' },
    changeFlex: 'center',
  },
  {
    title: 'Fact after testing',
    style: { width: '16%' },
    changeFlex: 'center',
  },
  { title: '', style: { width: '6%' } },
];
export const HISTORY_HEADERS = [
  { title: 'Assigned', style: { width: '225px' } },
  { title: 'Type', changeFlex: 'center', style: { width: '200px' } },
  { title: 'Old value', changeFlex: 'center' },
  { title: 'New value', changeFlex: 'center' },
  { title: 'Edited by', changeFlex: 'center', style: { width: '225px' } },
  { title: 'Date & Time', changeFlex: 'center' },
];
export const ROLE_SYSTEM = [
  { value: 'BA', label: 'BA' },
  { value: 'DESIGNER', label: 'Designer' },
  { value: 'TECH_LEAD', label: 'Tech. lead' },
  { value: 'BE_DEV', label: 'BE' },
  { value: 'MOBILE_DEV', label: 'Mob FE' },
  { value: 'WEB_DEV', label: 'Web FE' },
  { value: 'QA', label: 'QA' },
  { value: 'DEVOPS', label: 'DevOps' },
  { value: 'PM', label: 'PM' },
];
export const filterEstimateByType = (data: any, type: string) => {
  if (!data) return '';
  const elements = data?.filter((item: any) => item.type === type);
  if (elements?.length) {
    return elements;
  } else return '';
};
export const totalEstimate = (
  tasksArray: any,
  field: string,
  alternative = '—',
) => {
  if (!tasksArray?.length) return alternative;
  return tasksArray?.reduce(
    (sum: number, task: any) => sum + (task[field] || 0),
    0,
  );
};
export const findEstimateByType = (data: any, type: string) => {
  const el = data.find((item: any) => item.type === type);
  if (el) {
    return { estimate: el.estimate, estimateId: el.id };
  } else return { estimate: '—', estimateId: '' };
};

export const EDITABLE_STATUS = ['IN_PROGRESS', 'NEW'];
