import { useEffect, useState } from 'react';
import { useMutation } from 'react-query';

import { Role } from '../../../store/roleStore';
import { groupByDate, groupByKey } from '../../../utils';
import { estimateTaskApi } from '../../../api/estimate';
import {
  NoReleaseModule,
  ReleaseModule,
  Select,
  Switch,
} from '../../../components/shared';
import { isObjectEmpty } from '../../../utils/objects/isObjectEmpty';
import Loading from '../../../components/shared/loading/main-loading';

import styles from './Estimate.module.css';

const EstimateTable = ({
  search,
  searchByString,
  users,
  roles,
  currentUser,
}: any) => {
  const [tasksWithDate, setTasksWithDate] = useState<any[]>([]);
  const [tasksWithoutDate, setTasksWithoutDate] = useState<any>({});
  const [filterByUser, setFilterByUser] = useState<any>('all');
  const [isClientView, setIsClientView] = useState<boolean>(false);
  const [isClientSwitchAvailable, setIsClientSwitchAvailable] =
    useState<boolean>(false);
  useEffect(() => {
    if (!roles?.length || !currentUser?.userProjects?.length) return;

    const pmRole = roles && roles.find((r: Role) => r?.systemName === 'PM');
    if (pmRole?.id === currentUser?.userProjects[0]?.roleId) {
      setIsClientSwitchAvailable(true);
    }
  }, [currentUser, roles]);

  const getEstimateData = async (filter: Record<string, any> | null = null) => {
    const params: any = {
      skip: 0,
      take: 100,
      sort: 'releaseDate',
      order: 'ASC',
      ...(search && { search: JSON.stringify({ name: search }) }),
      ...(filter ? { filter: JSON.stringify(filter) } : {}),
    };
    const response = await estimateTaskApi.getTaskSpendingSumList(params);
    return response.data;
  };

  const { mutate: getData, isLoading } = useMutation(getEstimateData, {
    onSuccess: (data: any) => {
      const filterNoDate = groupByKey(
        data?.list.filter((task: any) => !task?.releaseDate),
        'releaseId',
      );
      setTasksWithoutDate(filterNoDate);

      const filterWithDate = groupByDate(
        data?.list.filter((task: any) => task?.releaseDate),
        'releaseDate',
      );
      const tasksByDateRelease = filterWithDate
        .map((el: any) => {
          return groupByKey(el?.items, 'releaseId');
        })
        .reverse();
      setTasksWithDate(tasksByDateRelease);
    },
  });

  useEffect(() => {
    handleFilterByUser(undefined);
  }, [searchByString]);

  const handleFilterByUser = (user: any) => {
    if (!user) {
      setFilterByUser('all');
      getData(null);
      return;
    }
    const { value } = user;
    setFilterByUser(value);
    const filterId = value === 'all' ? null : { 'assignees.assigneeId': value };
    getData(filterId);
  };

  const isSomeSearch = search?.length > 0;

  return (
    <div className={styles.wrapper}>
      <div className={styles.searchRow}>
        <div className={styles.legend}>
          {isClientSwitchAvailable ? (
            <div>
              <Switch
                iconView="eye"
                handleSwitch={(v: boolean) => setIsClientView(v)}
              />
              <p>For the client</p>
            </div>
          ) : null}
          <div className={styles.borderLeft}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="8"
              height="8"
              viewBox="0 0 8 8"
              fill="none"
            >
              <circle cx="4" cy="4" r="4" fill="#8E9FA7" />
            </svg>
            <p>Estimated</p>
          </div>
          {isClientView && (
            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="8"
                height="8"
                viewBox="0 0 8 8"
                fill="none"
              >
                <circle cx="4" cy="4" r="4" fill="#D12075" />
              </svg>
              <p>Assessment for the client</p>
            </div>
          )}
          <div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="8"
              height="8"
              viewBox="0 0 8 8"
              fill="none"
            >
              <circle cx="4" cy="4" r="4" fill="#0B1215" />
            </svg>
            <p>Fact of time spent</p>
          </div>
        </div>
        <div className={styles.search}>
          <span>Filter:</span>
          <div style={{ width: '40%' }}>
            <Select
              showAdditionalInfoInSelect
              options={users}
              onChange={handleFilterByUser}
              emptyText=""
              value={filterByUser}
              showClear
            />
          </div>
        </div>
      </div>
      {isLoading ? (
        <div className={styles.loadingContainer}>
          <Loading />
        </div>
      ) : (
        <>
          {tasksWithDate?.length || !isObjectEmpty(tasksWithoutDate) ? (
            <>
              {Object.entries(tasksWithoutDate)
                .reverse()
                .map((item: any, index: number) => {
                  if (item[0] === 'no_release') {
                    return (
                      <NoReleaseModule
                        viewType="ESTIMATE"
                        key={item[0] + index}
                        tasks={item[1]}
                        isClientView={isClientView}
                      />
                    );
                  } else {
                    return (
                      <ReleaseModule
                        key={item[1] + index}
                        tasks={item[1]}
                        releaseId={item[0]}
                        isSomeSearch={isSomeSearch}
                        viewType="ESTIMATE"
                        isClientView={isClientView}
                      />
                    );
                  }
                })}
              {tasksWithDate.map((item: any, index: number) => {
                const taskObj = Object.entries(item);
                return taskObj.map((task: any) => {
                  if (task[0] === 'no_release') {
                    return (
                      <NoReleaseModule
                        viewType="ESTIMATE"
                        key={task[0] + index}
                        tasks={task[1]}
                        isClientView={isClientView}
                      />
                    );
                  } else {
                    return (
                      <ReleaseModule
                        key={task[0] + index}
                        tasks={task[1]}
                        releaseId={task[0]}
                        isSomeSearch={isSomeSearch}
                        viewType="ESTIMATE"
                        isClientView={isClientView}
                      />
                    );
                  }
                });
              })}
            </>
          ) : (
            <div>
              <NoReleaseModule viewType="ESTIMATE" />
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default EstimateTable;
