import api from './axios.config';

const defaultParams: any = {
  skip: 0,
  take: 10,
  sort: 'createdAt',
  order: 'ASC',
  search: { name: '' },
};
const getNotes = (params = defaultParams) => {
  return api.get<any>('/notes', { params });
};

const getNoteById = (id: number) => {
  return api.get(`/notes/${id}`);
};

const getNoteAssignee = (id: number) => {
  return api.get(
    `/note-assignees?skip=0&take=999&sort=id&order=ASC&filter={"noteId": ${id}}`,
  );
};
const updateNoteAssignees = (data: unknown) => {
  return api.patch('/note-assignees', data);
};
const addNoteAssignees = (data: unknown) => {
  return api.post('/note-assignees', data);
};
const deleteNoteAssignees = (id: number | string) => {
  return api.delete(`/note-assignees/${id}`);
};
const updateNote = (id: number, data: unknown) => {
  return api.patch(`/notes/${id}`, data);
};

const createNote = (data: unknown) => {
  return api.post('/notes', data);
};

const createNoteComment = (data: unknown) => {
  return api.post('/note-comments', data);
};

const getNoteComments = (params: unknown) => {
  return api.get('/note-comments', { params });
};

export const notesApi = {
  getNotes,
  updateNote,
  createNote,
  getNoteById,
  getNoteAssignee,
  updateNoteAssignees,
  addNoteAssignees,
  deleteNoteAssignees,
  createNoteComment,
  getNoteComments,
};
