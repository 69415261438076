import { useState } from 'react';
import ExitIcon from '../../../../assets/icons/exit-icon';
import {
  ApproveModal,
  CustomInput,
  Switch,
} from '../../../../components/shared';
import { colors } from '../../../../constants/colors';
import DecompositionIcon from '../../../../assets/icons/decomposition-icon';
import Decomposition from './decomposition';
import { SPENDING_TYPE } from '../../../../constants';
import classNames from 'classnames';
import styles from './Modals.module.css';

interface IEditEstimateModal {
  close: () => void;
  form:
    | {
        submitForm: () => void;
        isSubmitting: boolean;
        values: any;
        setFieldValue: (name: string, v: any) => void;
        initialValues: any;
        setFieldError: (name: string, v: any) => void;
      }
    | undefined;
  editPMAvailable: boolean;
}

const EditEstimateModal = ({
  form,
  close,
  editPMAvailable,
}: IEditEstimateModal) => {
  const [touched, setTouched] = useState(false);

  const isNoUser = form?.values.user?.label === 'No name';
  const isDecomposition = form?.values.decompositionMode;
  const isEditMode = !!form?.values.estimateId;

  const wrapperClassName = classNames(styles.editWrapper, {
    [styles.addWrapperDec]: isDecomposition,
  });

  return (
    <ApproveModal
      btnText="Save"
      callback={form?.submitForm}
      closeModal={close}
      disabled={form?.isSubmitting || !touched || !form?.values?.estimate}
      hideActionBtn={!editPMAvailable}
    >
      <div className={wrapperClassName}>
        <span role="button" onClick={close} className={styles.closeBtn}>
          <ExitIcon />
        </span>
        <div className={styles.modalTitle}>Edit Estimate & Fact</div>
        <div className={styles.estimateDetails}>
          <div>
            <p>Role</p>
            <span>{form?.values.role}</span>
          </div>
          <div>
            <p>Assigned</p>
            <span
              style={{ color: isNoUser ? colors.icon_gray : colors.main_font }}
            >
              {form?.values.user?.label}
            </span>
          </div>
          <div>
            <p>Type</p>
            <span>{form?.values.type?.label}</span>
          </div>
        </div>
        {isDecomposition ? (
          <Decomposition
            form={form}
            data={form?.values.decomposition}
            setTouched={setTouched}
            editMode={isEditMode}
            editPMAvailable={editPMAvailable}
          />
        ) : (
          <CustomInput
            label="Time"
            placeHolder="0"
            name="estimate"
            value={form?.values?.estimate}
            onChange={(v: string) => {
              form?.setFieldValue('estimate', v);
              setTouched(true);
            }}
            type="number"
          />
        )}
        {form?.values.type.value === SPENDING_TYPE.TEAM_ESTIMATE &&
          editPMAvailable && (
            <div className={styles.decompositionWrapper}>
              <div className={styles.decompositionSwitch}>
                <Switch
                  initialState={form?.initialValues?.decompositionMode}
                  customOnIcon={<DecompositionIcon />}
                  handleSwitch={(v: boolean) =>
                    form?.setFieldValue('decompositionMode', v)
                  }
                />
                <span>{isDecomposition ? 'Decomposition' : 'Single task'}</span>
              </div>
            </div>
          )}
      </div>
    </ApproveModal>
  );
};

export default EditEstimateModal;
