import { RoutesData } from '../../types/routes/routes';
import EnvDev from '../../assets/icons/navigation-icons/env-dev';
import EnvQa from '../../assets/icons/navigation-icons/env-qa';
import EnvStage from '../../assets/icons/navigation-icons/env-stage';
import EnvProd from '../../assets/icons/navigation-icons/env-prod';

export type INavigation = {
  name: string;
  icon?: React.ComponentType;
  to: string;
  value: string;
};
export const taskNavigation: INavigation[] = [
  {
    name: 'Dev',
    to: RoutesData.DEV,
    icon: EnvDev,
    value: 'dev',
  },
  {
    name: 'QA',
    to: RoutesData.QA,
    icon: EnvQa,
    value: 'qa',
  },
  {
    name: 'Stage',
    to: RoutesData.Stage,
    icon: EnvStage,
    value: 'stage',
  },
  {
    name: 'Production',
    to: RoutesData.Prod,
    icon: EnvProd,
    value: 'prod',
  },
];

export const order = ['Dev', 'QA', 'Stage', 'Prod'];
