import { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { toast } from 'react-toastify';
import { object, string } from 'yup';

import {
  ApproveModal,
  Select,
  ToggleButtonGroup,
  CustomInput,
} from '../../../components/shared';
import { taskApi } from '../../../api/task';
import { HTTP_STATUSES, TOAST_MESSAGES } from '../../../constants';
import { envApi } from '../../../api/env';
import { TASK_STATUS } from '../constants';
import { IModalActions } from './types';

import styles from '../expired-view/Styles.module.css';

const TASK_ACTIONS = { create: 'create', select: 'select' };

const toggleOptions = [
  { value: TASK_ACTIONS.select, label: 'Select' },
  { value: TASK_ACTIONS.create, label: 'Create new' },
];

const taskValidation = object().shape({
  name: string().when('taskOption', ([taskOption], schema) => {
    if (taskOption == TASK_ACTIONS.create) {
      return schema.min(0).max(100).required('Task name is required');
    }
    return schema;
  }),
  feature: string().when('taskOption', ([taskOption], schema) => {
    if (taskOption == TASK_ACTIONS.select) {
      return schema.min(1).required('Task is required');
    }
    return schema;
  }),
  confluenceLink: string().min(0),
  redmineTaskLink: string().min(0),
});

const AddTask = ({
  close,
  name = '',
  envId = '',
  getNewDetails,
  isWaitingAction,
}: IModalActions) => {
  const [releasesTasks, setReleasesTasks] = useState<any>([]);
  const taskForm = useFormik({
    initialValues: {
      feature: '',
      taskOption: toggleOptions[0]?.value,
      name: '',
      confluenceLink: '',
      redmineTaskLink: '',
    },
    onSubmit: async (values) => {
      let body;
      if (values?.taskOption === TASK_ACTIONS.create) {
        const taskBody = {
          projectId: 1,
          name: values.name,
          confluenceLink: values.confluenceLink || '',
          redmineTaskLink: values.redmineTaskLink || '',
        };
        const taskResponse = await taskApi.createNewTask(taskBody);
        if (taskResponse?.status === HTTP_STATUSES.created) {
          body = {
            features: [{ featureId: taskResponse?.data?.id, status: 'NEW' }],
          };
        }
      } else {
        body = {
          features: [
            {
              featureId: values?.feature,
              status: isWaitingAction ? TASK_STATUS.WAITING : TASK_STATUS.NEW,
            },
          ],
        };
      }
      const response = await envApi.assigneeTaskToEnv(envId, body);

      if (response?.status === HTTP_STATUSES.created) {
        toast.success(TOAST_MESSAGES.SAVED);
        getNewDetails(
          isWaitingAction ? TASK_STATUS.WAITING : TASK_STATUS.RESOLVED_NEW,
        );
        close();
      } else {
        toast.error(TOAST_MESSAGES.COMMON_ERROR);
      }
    },
    validateOnChange: false,
    validationSchema: taskValidation,
  });
  const currentTaskOptionToAdd =
    taskForm?.values?.taskOption || TASK_ACTIONS.select;

  const getReleaseTasks = async () => {
    setReleasesTasks([]);
    const params: any = {
      skip: 0,
      take: 100,
      sort: 'id',
      order: 'ASC',
      filter: JSON.stringify({}),
    };
    const response = await taskApi.getTasksList(params);
    if (response?.status === HTTP_STATUSES.ok) {
      const uniqueReleaseIds = Array.from(
        new Set(
          response?.data?.list.map((el: any) => el?.release?.releaseNumber),
        ),
      );
      const mappedTasksByRelease = uniqueReleaseIds.map((releaseId) => ({
        releaseNumber: releaseId ?? null,
        relatedTasks: response?.data?.list?.filter(
          (task: any) => task?.release?.releaseNumber === releaseId,
        ),
      }));
      const options = mappedTasksByRelease.map((mappedTask) => ({
        name: mappedTask?.releaseNumber
          ? `Release ${mappedTask.releaseNumber}`
          : 'No included in any release',
        options: mappedTask.relatedTasks.map((task: any) => ({
          label: task.name,
          value: task.id,
        })),
      }));
      setReleasesTasks(options);
    }
  };
  useEffect(() => {
    if (currentTaskOptionToAdd === TASK_ACTIONS.select) {
      getReleaseTasks();
    }
  }, [currentTaskOptionToAdd]);

  return (
    <ApproveModal
      btnText="Add"
      callback={taskForm.submitForm}
      closeModal={close}
      disabled={taskForm.isSubmitting}
    >
      <div className={styles.editWrapper}>
        <span className={styles.modal__title}>Add Task</span>
        <div className={styles.description}>
          Select a task or create a new task for the {name} environment.
        </div>
        <ToggleButtonGroup
          options={toggleOptions}
          onChange={(value) => taskForm.setFieldValue('taskOption', value)}
        />
        {currentTaskOptionToAdd === TASK_ACTIONS.select ? (
          <Select
            label="Add task"
            options={releasesTasks}
            onChange={(o) => taskForm.setFieldValue('feature', o.value)}
            name="feature"
            value={taskForm?.values?.feature}
            error={taskForm.errors.feature}
          />
        ) : (
          <>
            <CustomInput
              label="Name"
              placeHolder="Enter name"
              name="name"
              value={taskForm.values.name}
              onChange={(v: string) => taskForm.setFieldValue('name', v)}
              showCountDown
              error={taskForm.errors.name}
            />
            <CustomInput
              label="Confluence link"
              placeHolder="https://"
              name="confluenceLink"
              value={taskForm.values.confluenceLink}
              onChange={(v: string) =>
                taskForm.setFieldValue('confluenceLink', v)
              }
              error={taskForm.errors.confluenceLink}
            />
            <CustomInput
              label="Redmine link"
              placeHolder="https://"
              name="redmineTaskLink"
              value={taskForm.values.redmineTaskLink}
              onChange={(v: string) =>
                taskForm.setFieldValue('redmineTaskLink', v)
              }
              error={taskForm.errors.redmineTaskLink}
            />
          </>
        )}
      </div>
    </ApproveModal>
  );
};

export default AddTask;
