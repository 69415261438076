import { useCallback, useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import { toast } from 'react-toastify';

import { CustomInput, EnvActionsButtons } from '../../../components/shared';
import { envApi } from '../../../api/env';
import { HTTP_STATUSES, TOAST_MESSAGES } from '../../../constants';
import Menu from '../../../components/shared/menu/menu';
import ContentPageContainer from '../../../components/layouts/ContentPageContainer/content-page-container';
import CollapseIcon from '../../../assets/icons/collapse-icon';
import ActiveTaskListTable from './active-list-table';
import WaitingListTaskTable from './waiting-list-table';
import { TASK_STATUS } from '../constants';
import { IType } from '../main-view/types';
import ModalsManager from '../modals/modals-manager';
import MoveTaskSelect from '../components/move-selected-tasks/move-task-select';
import { IEnvDetails } from './types';

import styles from './Styles.module.css';

const emptyArray: any[] = [];

const EnvDetails = ({
  data,
  setExpandedView,
  envs,
  moveTaskForm,
  selectedTasks = [],
  setSelectedTasks,
  updateData,
  setUpdateData,
}: IEnvDetails) => {
  const [taskList, setTaskList] = useState<any>(emptyArray);
  const [waitingTaskList, setWaitingTaskList] = useState<any>(emptyArray);
  const [searchValue, setSearchValue] = useState('');
  const [searchByString, setSearchByString] = useState(false);
  const [loadingTask, setLoadingTask] = useState(false);
  const [loadingWaitingTask, setLoadingWaitingTask] = useState(false);
  const [envDetails, setEnvDetails] = useState<any>({});
  const [isMultipleAction, setIsMultipleAction] = useState(false);
  const [isWaitingMultipleAction, setIsWaitingMultipleAction] = useState(false);
  const [multipleActionType, setMultipleActionType] =
    useState<IType>('no_type');
  const [isWaitingAction, setIsWaitingAction] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [modals, setModals] = useState({
    showAddTaskModal: false,
    showReleaseModal: false,
    showEditDescEnv: false,
    showRestore: false,
    showSuccessSnapshot: false,
    showCleanAll: false,
    showMoveRelease: false,
  });
  const isProduction = useMemo(() => envDetails?.isProduction, [envDetails]);
  const isDeleteTasks = multipleActionType === 'delete';
  const isDisabledByTask = taskList?.length <= 0;
  const isDisabledByWaitingTask = waitingTaskList?.length <= 0;
  const isDisabledByRelease = Boolean(!envDetails?.releaseId);
  const handleSnapshot = useCallback(async () => {
    const response = await envApi.createSnapshot(envDetails?.id);
    if (response?.status === HTTP_STATUSES.created) {
      setModals((prev) => ({ ...prev, showSuccessSnapshot: true }));
    }
  }, [envDetails?.id]);
  const handleMultipleTasks = useCallback(
    (type: IType, isWaitingList: boolean) => {
      setSelectedTasks([]);
      moveTaskForm.setFieldValue('currentId', envDetails?.id);
      moveTaskForm.setFieldValue('isWaitingAction', isWaitingList);
      setMultipleActionType(type);
      if (isWaitingList) {
        setIsWaitingMultipleAction(true);
        setIsWaitingAction(true);
        setIsMultipleAction(false);
      } else {
        setIsMultipleAction(true);
        setIsWaitingAction(false);
        setIsWaitingMultipleAction(false);
      }
    },
    [envDetails?.id],
  );
  const deleteSelectedTasks = useCallback(async () => {
    setDeleteLoading(true);
    const body = {
      featureIds: selectedTasks,
    };
    const response = await envApi.removeTasksFromEnv(envDetails?.id, body);
    if (response?.status === HTTP_STATUSES.created) {
      toast.success(TOAST_MESSAGES.DELETED);
      if (isProduction) {
        getTaskList(TASK_STATUS.WAITING);
        return;
      }
      getTaskList(
        isWaitingAction ? TASK_STATUS.WAITING : TASK_STATUS.RESOLVED_NEW,
      );
      setSelectedTasks([]);
    } else {
      toast.error(TOAST_MESSAGES.COMMON_ERROR);
    }
    setDeleteLoading(false);
  }, [selectedTasks]);

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>, option: any) => {
      const shouldAdd = e.target.checked;
      setSelectedTasks((prevArray: any) =>
        shouldAdd
          ? [...prevArray, option]
          : prevArray.filter((el: any) => el?.id !== option?.id),
      );
    },
    [],
  );

  const handleAllChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    listType: 'waiting' | 'active',
  ) => {
    const currentTaskList = listType === 'waiting' ? waitingTaskList : taskList;
    if (e?.target?.checked) {
      const newArray = currentTaskList?.map((el: any) => ({
        id: el?.featureId,
        modules: el?.modules,
      }));
      return setSelectedTasks([...newArray]);
    }
    return setSelectedTasks([]);
  };

  const cancelMultipleAction = (type?: 'active' | 'waiting') => {
    setSelectedTasks([]);
    moveTaskForm.resetForm();
    if (!type) {
      setIsWaitingMultipleAction(false);
      setIsMultipleAction(false);
      return;
    }
    type === 'waiting'
      ? setIsWaitingMultipleAction(false)
      : setIsMultipleAction(false);
  };

  const commonActions = [
    {
      name: 'Edit deployment status',
      action: () => setModals((prev) => ({ ...prev, showEditDescEnv: true })),
      addUnderLine: true,
      isWaitingList: false,
    },
    {
      name: 'Create snapshot',
      action: handleSnapshot,
      addUnderLine: false,
      isWaitingList: false,
    },
  ];

  const getActions = (type: 'active' | 'waiting') => [
    {
      name: 'Assign release',
      action: () => setModals((prev) => ({ ...prev, showReleaseModal: true })),
      addUnderLine: false,
      isWaitingList: false,
    },
    {
      name: 'Add task',
      action: (isWaiting: boolean) => {
        setModals((prev) => ({ ...prev, showAddTaskModal: true }));
        setIsWaitingAction(isWaiting);
      },
      addUnderLine: false,
      isWaitingList: true,
    },
    {
      name: 'Move selected tasks',
      action: handleMultipleTasks,
      addUnderLine: false,
      isWaitingList: true,
      type: 'select',
      isDisabled:
        type === 'active' ? isDisabledByTask : isDisabledByWaitingTask,
    },
    {
      name: 'Move release tasks',
      action: () => setModals((prev) => ({ ...prev, showMoveRelease: true })),
      addUnderLine: false,
      isWaitingList: false,
      isDisabled: isDisabledByRelease,
    },
    ...commonActions,
    {
      name: 'Restore',
      action: () => setModals((prev) => ({ ...prev, showRestore: true })),
      addUnderLine: true,
      isWaitingList: false,
    },
    {
      name: 'Delete tasks',
      action: handleMultipleTasks,
      addUnderLine: false,
      isWaitingList: true,
      type: 'delete',
      isDisabled:
        type === 'active' ? isDisabledByTask : isDisabledByWaitingTask,
    },
    {
      name: 'Clean all',
      action: () => setModals((prev) => ({ ...prev, showCleanAll: true })),
      addUnderLine: false,
      isWaitingList: false,
    },
  ];

  const prodActions = [
    {
      name: 'Add task',
      action: () => {
        setModals((prev) => ({ ...prev, showAddTaskModal: true }));
        setIsWaitingAction(true);
      },
      addUnderLine: false,
    },
    {
      name: 'Move tasks',
      action: handleMultipleTasks,
      type: 'select',
      addUnderLine: false,
      isDisabled: isDisabledByWaitingTask,
    },
    ...commonActions,
    {
      name: 'Restore',
      action: () => setModals((prev) => ({ ...prev, showRestore: true })),
      addUnderLine: true,
    },
    {
      name: 'Delete tasks',
      action: handleMultipleTasks,
      type: 'delete',
      addUnderLine: false,
      isDisabled: isDisabledByWaitingTask,
    },
  ];

  const getTaskList = useCallback(
    async (status = TASK_STATUS.RESOLVED_NEW) => {
      const isWaiting = status === TASK_STATUS.WAITING;
      if (isProduction && !isWaiting) return;
      isWaiting ? setLoadingWaitingTask(true) : setLoadingTask(true);
      if (envDetails?.id) {
        const params = {
          skip: 0,
          take: 100,
          order: 'DESC',
          sort: isWaiting ? 'priority' : 'updatedAt',
          filter: JSON.stringify({ status }),
        };
        const response = await envApi.getEnvsTasks(envDetails?.id, params);
        if (response.status === HTTP_STATUSES.ok) {
          if (status === TASK_STATUS.WAITING) {
            setWaitingTaskList(response?.data?.list);
          } else {
            setTaskList(response?.data?.list);
          }
        }
        isWaiting ? setLoadingWaitingTask(false) : setLoadingTask(false);
      }
    },
    [envDetails?.id],
  );

  useEffect(() => {
    const fetchData = async () => {
      await getTaskList();
      await getTaskList(TASK_STATUS.WAITING);
    };
    fetchData();
  }, [getTaskList]);

  const getTasksBySearch = useCallback(
    async (envId: string | number) => {
      setLoadingTask(true);
      try {
        const params: any = {
          skip: 0,
          take: 100,
          order: 'DESC',
          sort: 'updatedAt',
          filter: JSON.stringify({ status: TASK_STATUS.RESOLVED_NEW }),
          ...(searchValue && {
            search: JSON.stringify({ ['feature.name']: searchValue }),
          }),
        };
        const response = await envApi.getEnvsTasks(envId, params);
        if (response?.status === HTTP_STATUSES.ok) {
          setTaskList(response?.data?.list);
        }
      } catch (error) {
        console.log(error);
      } finally {
        setLoadingTask(false);
      }
    },
    [searchValue],
  );

  useEffect(() => {
    if (isProduction) getTasksBySearch(envDetails?.id);
  }, [searchByString, isProduction]);

  const getEnvDetails = async (isGetNewTasksList = false) => {
    if (!data?.id) return;
    const response = await envApi.getEnvDetails(data.id);
    if (isGetNewTasksList) {
      await getTaskList();
      await getTaskList(TASK_STATUS.WAITING);
    }
    if (response?.status === HTTP_STATUSES.ok) {
      setEnvDetails(response.data);
    }
  };
  useEffect(() => {
    getEnvDetails();
    cancelMultipleAction();
  }, [data.id]);

  useEffect(() => {
    if (updateData) {
      const isCurrentEnv =
        moveTaskForm?.values?.targetEnvDetails?.envId === data.id ||
        moveTaskForm?.values?.currentId === data.id;
      if (isCurrentEnv) {
        const fetchData = async () => {
          await getTaskList();
          await getTaskList(TASK_STATUS.WAITING);
        };
        fetchData();
      }
      setUpdateData(false);
    }
  }, [
    updateData,
    data.id,
    moveTaskForm?.values?.currentId,
    moveTaskForm?.values?.targetEnvDetails?.envId,
  ]);

  const headerClassName = classNames(
    styles.header_radius,
    styles[`header_${data?.name?.toLowerCase()}`],
  );

  const disableAction = isDeleteTasks
    ? deleteLoading || !selectedTasks?.length
    : moveTaskForm.isSubmitting ||
      !moveTaskForm?.values?.targetEnvDetails?.envId ||
      !selectedTasks?.length;

  const envAction = () =>
    isDeleteTasks ? deleteSelectedTasks() : moveTaskForm.submitForm();

  const handleClearSearch = () => {
    setSearchValue('');
    setSearchByString(!searchByString);
  };

  useEffect(() => {
    handleClearSearch();
  }, [data.id]);

  return (
    <ContentPageContainer>
      <div className={styles.container}>
        <div className={styles.header}>
          <div className={styles.headerLeft}>
            <div
              style={{ height: '40px', width: '40px' }}
              className={headerClassName}
            />
            <div className={styles.headerLeftTitle}>
              <span>{envDetails?.name}</span>
              <span className={styles.release}>
                {envDetails?.release?.releaseNumber
                  ? `Release ${envDetails?.release?.releaseNumber}`
                  : ''}
              </span>
            </div>
          </div>
          {isMultipleAction && isDeleteTasks ? (
            <EnvActionsButtons
              onCancel={() => cancelMultipleAction('active')}
              onAction={envAction}
              type={multipleActionType}
              disabled={disableAction}
            />
          ) : (
            <div style={{ display: 'flex', gap: '8px' }}>
              <CollapseIcon
                onClick={() => setExpandedView(false)}
                className={styles.btnIcon}
              />
              <Menu
                actions={isProduction ? prodActions : getActions('active')}
              />
            </div>
          )}
        </div>
        {isMultipleAction && !isDeleteTasks ? (
          <div className={styles.moveOption}>
            <MoveTaskSelect
              addWidthSelect
              type="row"
              envs={envs}
              currentEnv={envDetails?.id}
              currentList="active"
              form={moveTaskForm}
              isProduction={isProduction}
            />
            <EnvActionsButtons
              onCancel={() => cancelMultipleAction('active')}
              onAction={envAction}
              type={multipleActionType}
              disabled={disableAction}
            />
          </div>
        ) : (
          <div className={styles.envDescription}>
            <span>{envDetails?.description}</span>
          </div>
        )}
        {isProduction ? (
          <div>
            <div className={styles.title} style={{ paddingTop: '0' }}>
              <span style={{ width: '-webkit-fill-available' }}>
                Waiting list
              </span>
            </div>
            <WaitingListTaskTable
              envID={envDetails?.id}
              taskList={waitingTaskList}
              isMultipleAction={isMultipleAction}
              getNewDetails={() => getTaskList(TASK_STATUS.WAITING)}
              handleChange={handleChange}
              isLoading={loadingWaitingTask}
              handleAllChange={handleAllChange}
              selectedTasks={selectedTasks}
              isProdEnv={isProduction}
            />
            <div className={styles.title}>
              <span>Production history</span>
              <div style={{ background: 'transparent', width: '320px' }}>
                <CustomInput
                  view="startAdornment"
                  className={styles.searchInput}
                  containerClassName={styles.searchInput}
                  onChange={setSearchValue}
                  onClear={handleClearSearch}
                  onSearch={() => setSearchByString(!searchByString)}
                  onEnter={() => setSearchByString(!searchByString)}
                  placeHolder="Search task"
                  value={searchValue}
                  withClear
                  id="searchInput"
                />
              </div>
            </div>
            <ActiveTaskListTable
              envID={envDetails?.id}
              isMultipleAction={false}
              taskList={taskList}
              isLoading={loadingTask}
              isProdEnv={isProduction}
            />
          </div>
        ) : (
          <div>
            <ActiveTaskListTable
              envID={envDetails?.id}
              isMultipleAction={isMultipleAction}
              taskList={taskList}
              handleChange={handleChange}
              selectedTasks={selectedTasks}
              isLoading={loadingTask}
              handleAllChange={handleAllChange}
            />
            <div className={styles.title}>
              {isWaitingMultipleAction && !isDeleteTasks ? null : (
                <span style={{ width: '-webkit-fill-available' }}>
                  Waiting list
                </span>
              )}
              {isWaitingMultipleAction ? (
                <div className={styles.moveOption}>
                  {isDeleteTasks ? null : (
                    <MoveTaskSelect
                      addWidthSelect
                      type="row"
                      envs={envs}
                      currentEnv={envDetails?.id}
                      currentList="waiting"
                      form={moveTaskForm}
                      isProduction={isProduction}
                    />
                  )}
                  <EnvActionsButtons
                    onCancel={() => cancelMultipleAction('waiting')}
                    onAction={envAction}
                    type={multipleActionType}
                    disabled={disableAction}
                  />
                </div>
              ) : (
                <Menu
                  actions={getActions('waiting').filter(
                    (el) => el.isWaitingList,
                  )}
                  isWaitingOnly
                />
              )}
            </div>
            <WaitingListTaskTable
              envID={envDetails?.id}
              taskList={waitingTaskList}
              isMultipleAction={isWaitingMultipleAction}
              getNewDetails={() => getTaskList(TASK_STATUS.WAITING)}
              handleChange={handleChange}
              isLoading={loadingWaitingTask}
              handleAllChange={handleAllChange}
              selectedTasks={selectedTasks}
              isProdEnv={isProduction}
            />
          </div>
        )}
        <ModalsManager
          isWaitingAction={isWaitingAction}
          envDetails={envDetails}
          getTaskList={getTaskList}
          getEnvDetails={getEnvDetails}
          setModals={setModals}
          modals={modals}
          envs={envs}
          isProdEnv={isProduction}
        />
      </div>
    </ContentPageContainer>
  );
};

export default EnvDetails;
