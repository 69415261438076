import React from 'react';
import styles from './multi-select-user.module.css';
import DeleteIcon from '../../../assets/icons/delete-icon';
import Account from '../../../assets/icons/account';

type ViewMode = 'compact' | 'detailed';

const MultiSelectUserItem = ({
  item,
  handleDeleteItem,
  itemModeView = 'compact',
  hideActions,
}: any) => {
  const renderItem = (mode: ViewMode) => {
    switch (mode) {
      case 'compact':
        return (
          <div className={styles.userItem}>
            {item.label}{' '}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              role="button"
              onClick={() => handleDeleteItem(item)}
            >
              <path
                d="M12 4L4 12M4 4L12 12"
                stroke="#0B1215"
                strokeWidth="1.3"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
        );
      case 'detailed':
        return (
          <div className={styles.detailedItem}>
            <div className={styles.userName}>
              <Account height="24" width="24" />
              {item?.label || item?.details || ''}
            </div>
            {hideActions ? null : (
              <DeleteIcon onClick={() => handleDeleteItem(item)} />
            )}
          </div>
        );
      default:
        break;
    }
  };
  return <>{renderItem(itemModeView)}</>;
};

export default MultiSelectUserItem;
