import React, { useEffect, useState } from 'react';
import styles from './ChatComponent.module.css';
import Account from '../../../assets/icons/account';
import { useFormik } from 'formik';
import { CustomInput, PrimaryBtn } from '../../../components/shared';
import { notesApi } from '../../../api/notes';
import { HTTP_STATUSES } from '../../../constants';
import { useUserQuery } from '../../../hooks/useQuery';
import classNames from 'classnames';

type Comment = {
  id: number;
  createdAt: string;
  updatedAt: string;
  deletedAt: null | string;
  noteId: number;
  comment: string;
  createdById: number;
  createdBy: {
    id: number;
    createdAt: string;
    updatedAt: string;
    deletedAt: null | string;
    type: 'USER';
    email: string;
    firstName: string;
    lastName: string;
    googleId: number;
    status: 'ACTIVE' | 'INACTIVE';
  };
};

type GroupedComments = {
  commentator: {
    id: number;
    firstName: string;
    lastName: string;
  };
  messages: Comment[];
}[];

function groupCommentsByCreator(comments: Comment[]): GroupedComments {
  if (comments.length === 0) return [];

  const groupedComments: GroupedComments = [];
  let currentGroup: {
    commentator: { id: number; firstName: string; lastName: string };
    messages: Comment[];
  } | null = null;

  comments.forEach((comment) => {
    const creator = comment.createdBy;

    if (currentGroup && currentGroup.commentator.id === creator.id) {
      currentGroup.messages.push(comment);
    } else {
      if (currentGroup) {
        groupedComments.push(currentGroup);
      }
      currentGroup = {
        commentator: {
          id: creator.id,
          firstName: creator.firstName,
          lastName: creator.lastName,
        },
        messages: [comment],
      };
    }
  });

  if (currentGroup) {
    groupedComments.push(currentGroup);
  }

  return groupedComments;
}

const ChatComponent = ({ noteId }: { noteId: number }) => {
  const { data: user } = useUserQuery();

  const userId = user?.data?.id;

  const [noteComments, setNoteComments] = useState<Comment[]>([]);

  const getNoteComments = async () => {
    const { data } = await notesApi.getNoteComments({
      filter: JSON.stringify({
        noteId,
      }),
      skip: 0,
      take: 999,
      sort: 'id',
      order: 'ASC',
    });

    setNoteComments(data?.list);
  };

  const groups = groupCommentsByCreator(noteComments);

  useEffect(() => {
    getNoteComments();
  }, []);

  const messageForm = useFormik({
    initialValues: {
      message: '',
    },
    onSubmit: async (values) => {
      const { status } = await notesApi.createNoteComment({
        noteId,
        comment: values.message,
      });

      if (status === HTTP_STATUSES.created) {
        getNoteComments();
      }
    },
  });

  return (
    <div className={styles.chatContainer}>
      {groups.map((group) => (
        <React.Fragment key={group?.commentator?.id}>
          <div className={styles.message}>
            <div className={styles.avatarContainer}>
              <Account width={18} height={18} />
            </div>
            <div className={styles.messageHeader}>
              <span className={styles.userName}>
                {group?.commentator?.firstName} {group?.commentator?.lastName}
              </span>
              <span className={styles.messageTime}>16:51</span>
            </div>
          </div>
          <div>
            <div className={styles.messageText}>
              {group?.messages?.map((comment) => (
                <div
                  className={classNames(
                    styles.flex,
                    userId === group.commentator.id && styles.right,
                  )}
                  key={comment.id}
                >
                  <p className={styles.messageContent}>{comment?.comment}</p>
                </div>
              ))}
            </div>
          </div>
        </React.Fragment>
      ))}
      <div className={styles.addMessageContainer}>
        <CustomInput
          value={messageForm.values.message}
          onChange={(v: string) => messageForm.setFieldValue('message', v)}
        />
        <PrimaryBtn
          onClick={messageForm.submitForm}
          text="Send"
          disabled={messageForm.isSubmitting}
          classNameBtn={styles.button}
        />
      </div>
    </div>
  );
};

export default ChatComponent;
