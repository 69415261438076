import { memo } from 'react';

import { Table } from '../../../components/shared';
import TaskItem from './task-item';
import { TaskProps } from './types';

import styles from './Styles.module.css';

const ActiveTaskListTable = ({
  envID,
  isMultipleAction,
  taskList,
  isLoading,
  handleChange,
  handleAllChange,
  selectedTasks,
  isProdEnv,
}: TaskProps) => {
  const headers = [
    ...(isProdEnv
      ? [{ title: 'Deployed', style: { width: '10%' }, changeFlex: 'center' }]
      : []),
    { title: 'Task', style: { width: '30%' } },
    { title: 'BE', style: { width: '10%' } },
    { title: 'Mob', style: { width: '10%' } },
    { title: 'Admin', style: { width: '10%' } },
    { title: 'Affiliates', style: { width: '10%' } },
    { title: 'Terms acceptance', style: { width: '12%' } },
    { title: 'Web utils', style: { width: '8%' } },
    ...(isProdEnv ? [] : [{ title: 'State', style: { width: '10%' } }]),
  ];

  return (
    <Table
      headers={headers}
      isShowCheckbox={isMultipleAction}
      isLoading={isLoading}
      handleCheckbox={
        handleAllChange
          ? (e: React.ChangeEvent<HTMLInputElement>) =>
              handleAllChange(e, 'active')
          : undefined
      }
      addScrollWrapperY={isProdEnv}
    >
      {taskList?.length ? (
        taskList.map((el: any) => (
          <TaskItem
            key={el?.id}
            item={el}
            envId={envID}
            isMultipleAction={isMultipleAction}
            handleChange={handleChange}
            selectedTasks={selectedTasks}
            isProdEnv={isProdEnv}
          />
        ))
      ) : (
        <tr>
          <td colSpan={8} className={styles.noData}>
            No data
          </td>
        </tr>
      )}
    </Table>
  );
};

export default memo(ActiveTaskListTable);
