import React from 'react';
import { IIcon } from '../../types';
import { colors } from '../../constants/colors';

const SandTimerIcon = ({
  className,
  onClick = undefined,
  color = colors.main_blue,
}: IIcon) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      role="button"
      className={className}
      onClick={onClick}
    >
      <path
        d="M7.99984 8.00016L5.15124 5.62634C4.72801 5.27364 4.5164 5.0973 4.36426 4.88112C4.22946 4.68958 4.12936 4.47586 4.06851 4.24968C3.99984 3.99442 3.99984 3.71896 3.99984 3.16803V1.3335M7.99984 8.00016L10.8484 5.62634C11.2716 5.27364 11.4833 5.0973 11.6354 4.88112C11.7702 4.68958 11.8703 4.47586 11.9312 4.24968C11.9998 3.99442 11.9998 3.71896 11.9998 3.16803V1.3335M7.99984 8.00016L5.15124 10.374C4.72801 10.7267 4.5164 10.903 4.36426 11.1192C4.22946 11.3108 4.12936 11.5245 4.06851 11.7506C3.99984 12.0059 3.99984 12.2814 3.99984 12.8323V14.6668M7.99984 8.00016L10.8484 10.374C11.2716 10.7267 11.4833 10.903 11.6354 11.1192C11.7702 11.3108 11.8703 11.5245 11.9312 11.7506C11.9998 12.0059 11.9998 12.2814 11.9998 12.8323V14.6668M2.6665 1.3335H13.3332M2.6665 14.6668H13.3332"
        stroke={color}
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default SandTimerIcon;
