import { useEffect, useState } from 'react';
import { ToastContainer } from 'react-toastify';
import { useMutation } from 'react-query';

import { Role } from '../../../store/roleStore';
import { groupByNestedDate, groupByNestedKey } from '../../../utils';
import { sdlcTasksApi } from '../../../api/sdlc';
import {
  NoReleaseModule,
  ReleaseModule,
  Select,
} from '../../../components/shared';
import { isObjectEmpty } from '../../../utils/objects/isObjectEmpty';
import Loading from '../../../components/shared/loading/main-loading';

import styles from './SDLS.module.css';

const SDLCTable = ({
  search,
  searchByString,
  users,
  roles,
  currentUser,
}: any) => {
  const [tasksWithDate, setTasksWithDate] = useState<any[]>([]);
  const [tasksWithoutDate, setTasksWithoutDate] = useState<any>({});
  const [filterByUser, setFilterByUser] = useState<any>('all');
  const [isEditStatusAvailable, setIsEditStatusAvailable] =
    useState<boolean>(false);

  useEffect(() => {
    if (!roles?.length || !currentUser?.userProjects?.length) return;

    const pmRole = roles && roles.find((r: Role) => r?.systemName === 'PM');
    if (pmRole?.id === currentUser?.userProjects[0]?.roleId) {
      setIsEditStatusAvailable(true);
    }
  }, [currentUser, roles]);

  const getSDLCData = async (filter: Record<string, any> | null = null) => {
    const params: any = {
      skip: 0,
      take: 100,
      sort: 'feature.releaseDate',
      order: 'ASC',
      ...(search && {
        search: JSON.stringify({ 'feature.name': search }),
      }),
      ...(filter ? { filter: JSON.stringify(filter) } : {}),
    };
    const response = await sdlcTasksApi.getSDLCData(params);
    return response.data;
  };

  const { mutate: getData, isLoading } = useMutation(getSDLCData, {
    onSuccess: (data: any) => {
      const filterNoDate = groupByNestedKey(
        data?.list.filter((task: any) => !task?.feature?.releaseDate),
        'feature',
        'releaseId',
      );
      setTasksWithoutDate(filterNoDate);

      const filterWithDate = groupByNestedDate(
        data?.list.filter((task: any) => task?.feature?.releaseDate),
        'feature',
        'releaseDate',
      );
      const tasksByDateRelease = filterWithDate
        .map((el: any) => {
          return groupByNestedKey(el?.items, 'feature', 'releaseId');
        })
        .reverse();
      setTasksWithDate(tasksByDateRelease);
    },
  });

  useEffect(() => {
    handleFilterByUser(undefined);
  }, [searchByString]);

  const handleFilterByUser = (user: any) => {
    if (!user) {
      setFilterByUser('all');
      getData(null);
      return;
    }
    const { value } = user;
    setFilterByUser(value);
    const filterId = value === 'all' ? null : { 'assignees.assigneeId': value };
    getData(filterId);
  };

  const isSomeSearch = search?.length > 0;
  return (
    <>
      <ToastContainer />
      <div className={styles.wrapper}>
        <div className={styles.searchRow}>
          <div className={styles.search}>
            <span>Filter:</span>
            <div style={{ width: '20%' }}>
              <Select
                showAdditionalInfoInSelect
                options={users}
                onChange={handleFilterByUser}
                emptyText=""
                value={filterByUser}
                showClear
              />
            </div>
          </div>
        </div>
        {isLoading ? (
          <div className={styles.loadingContainer}>
            <Loading />
          </div>
        ) : (
          <>
            {tasksWithDate?.length || !isObjectEmpty(tasksWithoutDate) ? (
              <>
                {Object.entries(tasksWithoutDate)
                  .reverse()
                  .map((item: any, index: number) => {
                    if (item[0] === 'no_release') {
                      return (
                        <NoReleaseModule
                          viewType="SDLC"
                          key={item[0] + index}
                          tasks={item[1]}
                          isEditStatusAvailable={isEditStatusAvailable}
                        />
                      );
                    } else {
                      return (
                        <ReleaseModule
                          key={item[1] + index}
                          tasks={item[1]}
                          releaseId={item[0]}
                          isSomeSearch={isSomeSearch}
                          viewType="SDLC"
                          isEditStatusAvailable={isEditStatusAvailable}
                        />
                      );
                    }
                  })}
                {tasksWithDate.map((item: any, index: number) => {
                  const taskObj = Object.entries(item);
                  return taskObj.map((task: any) => {
                    if (task[0] === 'no_release') {
                      return (
                        <NoReleaseModule
                          viewType="SDLC"
                          key={task[0] + index}
                          tasks={task[1]}
                          isEditStatusAvailable={isEditStatusAvailable}
                        />
                      );
                    } else {
                      return (
                        <ReleaseModule
                          key={task[0] + index}
                          tasks={task[1]}
                          releaseId={task[0]}
                          isSomeSearch={isSomeSearch}
                          viewType="SDLC"
                          isEditStatusAvailable={isEditStatusAvailable}
                        />
                      );
                    }
                  });
                })}
              </>
            ) : (
              <div>
                <NoReleaseModule viewType="SDLC" />
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default SDLCTable;
