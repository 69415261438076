import { usersApi } from '../api/users';
import { QueryClient, useQuery } from 'react-query';
import { getAuthUserRequest } from '../api/users';

export const createQueryClient = () =>
  new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        retryOnMount: false,
        retry: false,
      },
    },
  });

export const useUserQuery = () => {
  return useQuery('user', getAuthUserRequest, {
    // clean cache
    staleTime: 0,
    cacheTime: 0,
  });
};
export const useRoleQuery = () => {
  return useQuery('roles', usersApi.getUserRoles, {});
};
