import React from 'react';
import { ITooltip } from './types';

import styles from './Tooltip.module.css';

const Tooltip: React.FC<ITooltip> = ({ label, customStyles = {} }) => {
  return (
    <span className={styles.tooltipStatus} style={customStyles}>
      {label}
    </span>
  );
};

export default Tooltip;
