import React from 'react';

const Account = ({ width = '40', height = '40' }: any) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 40 40"
      fill="none"
      style={{ borderRadius: '24px' }}
    >
      <rect width="40" height="40" fill="#F9E6E6" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.0001 14.6501C18.7022 14.6501 17.6501 15.7022 17.6501 17.0001C17.6501 18.298 18.7022 19.3501 20.0001 19.3501C21.298 19.3501 22.3501 18.298 22.3501 17.0001C22.3501 15.7022 21.298 14.6501 20.0001 14.6501ZM16.3501 17.0001C16.3501 14.9843 17.9843 13.3501 20.0001 13.3501C22.016 13.3501 23.6501 14.9843 23.6501 17.0001C23.6501 19.0159 22.016 20.6501 20.0001 20.6501C17.9843 20.6501 16.3501 19.0159 16.3501 17.0001ZM20.0001 23.3168C17.8676 23.3168 15.9197 24.245 14.473 25.7793C14.2268 26.0405 13.8154 26.0526 13.5542 25.8064C13.293 25.5601 13.2809 25.1487 13.5272 24.8875C15.1949 23.1187 17.4753 22.0168 20.0001 22.0168C22.5249 22.0168 24.8053 23.1187 26.473 24.8875C26.7193 25.1487 26.7072 25.5601 26.446 25.8064C26.1848 26.0526 25.7734 26.0405 25.5272 25.7793C24.0805 24.245 22.1326 23.3168 20.0001 23.3168Z"
        fill="#8E9FA7"
      />
    </svg>
  );
};

export default Account;
