import React, { useEffect, useState } from 'react';
import { useMutation } from 'react-query';

import { Note, STATUS, TASK_FILTER_OPTIONS } from './constants';
import { PRIORITY_FILTER } from './Notes';
import Account from '../../assets/icons/account';
import {
  AssigneeAvatarTooltip,
  ViewModal,
  Table,
  Chip,
} from '../../components/shared';
import ClockIcon from '../../assets/icons/clock';
import { formatDate } from '../../utils';
import { Edit } from '../../assets/icons/edit';
import { EditNotesModal } from './EditNotes';
import { notesApi } from '../../api/notes';
import classNames from 'classnames';
import NoteComment from './NoteComment/NoteComment';
import { colors } from '../../constants/colors';
import { EDITABLE_STATUS } from '../../screens/Tasks/task-view/constants';
import styles from './NotesTable.module.css';

type NotesTableProps = {
  notes: Note[];
  refresh: () => void;
};

type Assignee = {
  assignee: {
    user: {
      id: number;
      createdAt: string;
      updatedAt: string;
      deletedAt: null | string;
      type: string;
      email: string;
      firstName: string;
      lastName: string;
      googleId: string;
      status: 'ACTIVE' | 'INACTIVE';
    };
  };
};

type AssigneeModalProps = {
  assignees: Assignee[];
  assigneeModalOpened: boolean;
  closeAssigneeModal: () => void;
};

const AssigneeModal = ({
  assignees,
  assigneeModalOpened,
  closeAssigneeModal,
}: AssigneeModalProps) => {
  return (
    <>
      {assigneeModalOpened && (
        <ViewModal
          callback={closeAssigneeModal}
          title="Assignee"
          text={
            <div>
              <div>{assignees?.length} employees</div>

              {assignees?.map((assignee) => (
                <div
                  className={styles.noteAssigneeWrapper}
                  key={assignee?.assignee?.user?.id}
                >
                  <div className={styles.noteIcon}>
                    <Account height="24" width="24" />
                  </div>
                  <div className={styles.noteAssigneeText}>
                    {assignee?.assignee?.user?.email}
                  </div>
                </div>
              ))}
            </div>
          }
        />
      )}
    </>
  );
};

type NoteItemProps = {
  note: Note;
  refresh: () => void;
  selectedNote: Note | null;
  selectNote: (note: Note) => void;
};

const NoteItem = ({
  note,
  refresh,
  selectNote,
  selectedNote,
}: NoteItemProps) => {
  const priority = PRIORITY_FILTER.find((el) => el.value === note.priority);
  const noteType = TASK_FILTER_OPTIONS.find((el) => el.value === note.type);
  const status = STATUS.find((statusObj) => statusObj.value === note.status);
  const isEditable = EDITABLE_STATUS.some((el: any) => el === note.status);

  const deadline = formatDate(note?.deadline);

  const [editModalOpened, setEditOpenModal] = useState(false);

  const openEditModal = () => setEditOpenModal(true);
  const closeEditModal = () => setEditOpenModal(false);

  const [assigneeModalOpened, setAssigneeModalOpened] = useState(false);

  const openAssigneeModal = () => setAssigneeModalOpened(true);
  const closeAssigneeModal = () => setAssigneeModalOpened(false);

  const { mutate: getAssignee, data: assignees } = useMutation(
    notesApi.getNoteAssignee,
    {},
  );

  useEffect(() => {
    if (note?.id) {
      getAssignee(note?.id);
    }
  }, []);

  return (
    <tr className={styles.noteItem}>
      <td onClick={() => selectNote(note)}>
        <div className={styles.noteName}>
          <AssigneeAvatarTooltip
            name={`${note?.createdBy?.firstName}
                  ${note?.createdBy?.lastName}`}
            containerCustomClassName={styles.minWidth}
          />
          <span className={styles.nameText}>
            <b className={styles.noteType}> {noteType?.label}: </b> {note.name}
            {note.id === selectedNote?.id && (
              <div className={styles.verticalLine} />
            )}
          </span>
        </div>
      </td>
      <td>
        <div className={styles.flex}>
          <div className={styles.icon}>{priority?.body}</div>
          <div>{priority?.label}</div>
        </div>
      </td>
      <td>
        {assignees?.data?.list.length ? (
          <div
            role="button"
            onClick={openAssigneeModal}
            style={{ display: 'flex' }}
          >
            {assignees?.data?.list.map((user: any, index: number) => (
              <AssigneeAvatarTooltip
                name={`${user.assignee?.user?.firstName}
              ${user.assignee?.user?.lastName}`}
                containerClassName={styles.minWidth}
                key={user.id}
                avatarCustomStyles={{
                  marginLeft: index === 0 ? '' : '-10px',
                }}
              />
            ))}
          </div>
        ) : null}
      </td>
      <td>
        <div className={styles.flex}>
          <div className={styles.icon}>
            {(deadline?.date || deadline?.time) && (
              <ClockIcon color={colors.main_font} />
            )}
          </div>
          <div>
            {deadline?.date} {deadline?.time}
          </div>
        </div>
      </td>
      <td>
        <div className={styles.flex}>
          <Chip
            label={status?.label ?? ''}
            customStyles={`${status?.style || ''}`}
          />

          {isEditable && (
            <div className={styles.editIcon} onClick={openEditModal}>
              <Edit />
            </div>
          )}
        </div>
      </td>

      {editModalOpened && (
        <EditNotesModal
          note={note}
          refresh={refresh}
          closeModal={closeEditModal}
          assigneeIds={assignees?.data?.list || []}
          refreshAssignees={() => getAssignee(note?.id)}
        />
      )}

      <AssigneeModal
        assigneeModalOpened={assigneeModalOpened}
        closeAssigneeModal={closeAssigneeModal}
        assignees={assignees?.data?.list}
      />
    </tr>
  );
};

export const NotesTable = ({ notes, refresh }: NotesTableProps) => {
  const headers = [
    { title: 'Note', style: { width: '55%' } },
    { title: 'Priority', style: { width: '10%' } },
    { title: 'Assignee', style: { width: '10%' } },
    { title: 'Deadline', style: { width: '10%' } },
    { title: 'Status', style: { width: '15%' } },
  ];

  const [selectedNote, setSelectedNote] = useState<Note | null>(null);

  const selectNote = (note: Note) => {
    setSelectedNote(note);
  };

  return (
    <div className={classNames(styles.flex, styles.staticHeight)}>
      <div className={styles.table}>
        <Table addScrollWrapperY headers={headers}>
          {notes?.map((note) => (
            <NoteItem
              selectedNote={selectedNote}
              selectNote={selectNote}
              refresh={refresh}
              note={note}
              key={note.id}
            />
          ))}
        </Table>
      </div>
      {selectedNote && <NoteComment note={selectedNote} />}
    </div>
  );
};
