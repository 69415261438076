import React from 'react';
import { IIcon } from '../../types';
import { colors } from '../../constants/colors';

const DeleteIcon = ({
  color = colors.danger,
  onClick = undefined,
  className = '',
}: IIcon) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      onClick={onClick}
      className={className}
    >
      <path
        d="M10.6667 4.00016V3.46683C10.6667 2.7201 10.6667 2.34672 10.5213 2.06151C10.3935 1.81062 10.1895 1.60665 9.93867 1.47882C9.65347 1.3335 9.28007 1.3335 8.53333 1.3335H7.46667C6.71993 1.3335 6.34656 1.3335 6.06135 1.47882C5.81046 1.60665 5.60649 1.81062 5.47866 2.06151C5.33333 2.34672 5.33333 2.7201 5.33333 3.46683V4.00016M6.66667 7.66683V11.0002M9.33333 7.66683V11.0002M2 4.00016H14M12.6667 4.00016V11.4668C12.6667 12.587 12.6667 13.147 12.4487 13.5748C12.2569 13.9512 11.951 14.2571 11.5747 14.4488C11.1468 14.6668 10.5868 14.6668 9.46667 14.6668H6.53333C5.41323 14.6668 4.85317 14.6668 4.42535 14.4488C4.04903 14.2571 3.74307 13.9512 3.55132 13.5748C3.33333 13.147 3.33333 12.587 3.33333 11.4668V4.00016"
        stroke={color}
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default DeleteIcon;
