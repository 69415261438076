import React from 'react';
import { useNavigate } from 'react-router-dom';
import { RoutesData } from '../../../../types/routes/routes';
import {
  estimateForFE,
  findEstimateByKey,
  findTotalByType,
  ROLE_SYSTEM,
  SPENDING_TYPE,
} from '../constants';
import styles from './TableItem.module.css';

function EstimateItem({ item, isClientView }: any): React.JSX.Element {
  const navigate = useNavigate();

  const handleRedirect = () => {
    navigate(`${RoutesData.Tasks}/${item.id}`, { state: { taskId: item.id } });
  };

  return (
    <tr className={styles.item}>
      <td onClick={handleRedirect} style={{ width: '26%' }}>
        {item?.name}
      </td>
      <td>
        <div className={styles.estimateRow}>
          <span className={styles.estimate}>
            {findEstimateByKey(
              item?.timeSpendingSummary,
              ROLE_SYSTEM.BA,
              SPENDING_TYPE.TEAM_ESTIMATE,
            )}
          </span>
          {isClientView ? (
            <span className={styles.customer}>
              {findEstimateByKey(
                item?.timeSpendingSummary,
                ROLE_SYSTEM.BA,
                SPENDING_TYPE.CUSTOMER_ESTIMATE,
              )}
            </span>
          ) : null}
          <span className={styles.fact}>
            {findEstimateByKey(
              item?.timeSpendingSummary,
              ROLE_SYSTEM.BA,
              SPENDING_TYPE.TEAM_FACT_AFTER_TESTING,
            )}
          </span>
        </div>
      </td>
      <td>
        <div className={styles.estimateRow}>
          <span className={styles.estimate}>
            {findEstimateByKey(
              item?.timeSpendingSummary,
              ROLE_SYSTEM.DESIGNER,
              SPENDING_TYPE.TEAM_ESTIMATE,
            )}
          </span>
          {isClientView ? (
            <span className={styles.customer}>
              {findEstimateByKey(
                item?.timeSpendingSummary,
                ROLE_SYSTEM.DESIGNER,
                SPENDING_TYPE.CUSTOMER_ESTIMATE,
              )}
            </span>
          ) : null}
          <span className={styles.fact}>
            {findEstimateByKey(
              item?.timeSpendingSummary,
              ROLE_SYSTEM.DESIGNER,
              SPENDING_TYPE.TEAM_FACT_AFTER_TESTING,
            )}
          </span>
        </div>
      </td>
      <td>
        <div className={styles.estimateRow}>
          <span className={styles.estimate}>
            {findEstimateByKey(
              item?.timeSpendingSummary,
              ROLE_SYSTEM.TECH_LEAD,
              SPENDING_TYPE.TEAM_ESTIMATE,
            )}
          </span>
          {isClientView ? (
            <span className={styles.customer}>
              {findEstimateByKey(
                item?.timeSpendingSummary,
                ROLE_SYSTEM.TECH_LEAD,
                SPENDING_TYPE.CUSTOMER_ESTIMATE,
              )}
            </span>
          ) : null}
          <span className={styles.fact}>
            {findEstimateByKey(
              item?.timeSpendingSummary,
              ROLE_SYSTEM.TECH_LEAD,
              SPENDING_TYPE.TEAM_FACT_AFTER_TESTING,
            )}
          </span>
        </div>
      </td>
      <td>
        <div className={styles.estimateRow}>
          <span className={styles.estimate}>
            {findEstimateByKey(
              item?.timeSpendingSummary,
              ROLE_SYSTEM.BE_DEV,
              SPENDING_TYPE.TEAM_ESTIMATE,
            )}
          </span>
          {isClientView ? (
            <span className={styles.customer}>
              {findEstimateByKey(
                item?.timeSpendingSummary,
                ROLE_SYSTEM.BE_DEV,
                SPENDING_TYPE.CUSTOMER_ESTIMATE,
              )}
            </span>
          ) : null}
          <span className={styles.fact}>
            {findEstimateByKey(
              item?.timeSpendingSummary,
              ROLE_SYSTEM.BE_DEV,
              SPENDING_TYPE.TEAM_FACT_AFTER_TESTING,
            )}
          </span>
        </div>
      </td>
      <td>
        <div className={styles.estimateRow}>
          <span className={styles.estimate}>
            {estimateForFE(
              item?.timeSpendingSummary,
              SPENDING_TYPE.TEAM_ESTIMATE,
            )}
          </span>
          {isClientView ? (
            <span className={styles.customer}>
              {estimateForFE(
                item?.timeSpendingSummary,
                SPENDING_TYPE.CUSTOMER_ESTIMATE,
              )}
            </span>
          ) : null}
          <span className={styles.fact}>
            {estimateForFE(
              item?.timeSpendingSummary,
              SPENDING_TYPE.TEAM_FACT_AFTER_TESTING,
            )}
          </span>
        </div>
      </td>
      <td>
        <div className={styles.estimateRow}>
          <span className={styles.estimate}>
            {findEstimateByKey(
              item?.timeSpendingSummary,
              ROLE_SYSTEM.QA,
              SPENDING_TYPE.TEAM_ESTIMATE,
            )}
          </span>
          {isClientView ? (
            <span className={styles.customer}>
              {findEstimateByKey(
                item?.timeSpendingSummary,
                ROLE_SYSTEM.QA,
                SPENDING_TYPE.CUSTOMER_ESTIMATE,
              )}
            </span>
          ) : null}
          <span className={styles.fact}>
            {findEstimateByKey(
              item?.timeSpendingSummary,
              ROLE_SYSTEM.QA,
              SPENDING_TYPE.TEAM_FACT_AFTER_TESTING,
            )}
          </span>
        </div>
      </td>
      <td>
        <div className={styles.estimateRow}>
          <span className={styles.estimate}>
            {findEstimateByKey(
              item?.timeSpendingSummary,
              ROLE_SYSTEM.DEVOPS,
              SPENDING_TYPE.TEAM_ESTIMATE,
            )}
          </span>
          {isClientView ? (
            <span className={styles.customer}>
              {findEstimateByKey(
                item?.timeSpendingSummary,
                ROLE_SYSTEM.DEVOPS,
                SPENDING_TYPE.CUSTOMER_ESTIMATE,
              )}
            </span>
          ) : null}
          <span className={styles.fact}>
            {findEstimateByKey(
              item?.timeSpendingSummary,
              ROLE_SYSTEM.DEVOPS,
              SPENDING_TYPE.TEAM_FACT_AFTER_TESTING,
            )}
          </span>
        </div>
      </td>
      <td>
        <div className={styles.estimateRow}>
          <span className={styles.estimate}>
            {findEstimateByKey(
              item?.timeSpendingSummary,
              ROLE_SYSTEM.PM,
              SPENDING_TYPE.TEAM_ESTIMATE,
            )}
          </span>
          {isClientView ? (
            <span className={styles.customer}>
              {findEstimateByKey(
                item?.timeSpendingSummary,
                ROLE_SYSTEM.PM,
                SPENDING_TYPE.CUSTOMER_ESTIMATE,
              )}
            </span>
          ) : null}
          <span className={styles.fact}>
            {findEstimateByKey(
              item?.timeSpendingSummary,
              ROLE_SYSTEM.PM,
              SPENDING_TYPE.TEAM_FACT_AFTER_TESTING,
            )}
          </span>
        </div>
      </td>
      <td>
        <div className={styles.estimateRow}>
          <span className={styles.estimate}>
            {findTotalByType(
              item?.timeSpendingSummaryTotal,
              SPENDING_TYPE.TEAM_ESTIMATE,
            )}
          </span>
          {isClientView ? (
            <span className={styles.customer}>
              {findTotalByType(
                item?.timeSpendingSummaryTotal,
                SPENDING_TYPE.CUSTOMER_ESTIMATE,
              )}
            </span>
          ) : null}
          <span className={styles.fact}>
            {findTotalByType(
              item?.timeSpendingSummaryTotal,
              SPENDING_TYPE.TEAM_FACT_AFTER_TESTING,
            )}
          </span>
        </div>
      </td>
    </tr>
  );
}

export default EstimateItem;
