import { useMemo } from 'react';

export const HEADERS = {
  PLANING: [
    { title: 'Name' },
    { title: 'Priority' },
    { title: 'Assignee' },
    { title: 'Confluence' },
    { title: 'Redmine' },
    { title: 'Status' },
  ],
  ESTIMATE: [
    { title: 'Name' },
    { title: 'BA', style: { width: '8%' }, changeFlex: 'center' },
    { title: 'Designer', style: { width: '8%' }, changeFlex: 'center' },
    { title: 'Tech. lead', style: { width: '8%' }, changeFlex: 'center' },
    { title: 'BE', style: { width: '8%' }, changeFlex: 'center' },
    { title: 'FE', style: { width: '8%' }, changeFlex: 'center' },
    { title: 'QA', style: { width: '8%' }, changeFlex: 'center' },
    { title: 'DevOps', style: { width: '8%' }, changeFlex: 'center' },
    { title: 'PM', style: { width: '8%' }, changeFlex: 'center' },
    { title: 'Total', changeFlex: 'center' },
  ],
  SDLC: [
    { title: 'Name', style: { width: '230px' }, changeFlex: 'flex-start' },
    { title: 'BA est.', style: { width: '5%' }, changeFlex: 'center' },
    { title: 'Design est.', style: { width: '5%' }, changeFlex: 'center' },
    { title: 'SRS', style: { width: '5%' }, changeFlex: 'center' },
    { title: 'Design', style: { width: '5%' }, changeFlex: 'center' },
    { title: 'Tech. design', style: { width: '5%' }, changeFlex: 'center' },
    { title: 'Dev est.', style: { width: '5%' }, changeFlex: 'center' },
    { title: 'QA checklist', style: { width: '5%' }, changeFlex: 'center' },
    { title: 'BE dev.', style: { width: '5%' }, changeFlex: 'center' },
    { title: 'BE tests', style: { width: '5%' }, changeFlex: 'center' },
    { title: 'FE dev.', style: { width: '5%' }, changeFlex: 'center' },
    { title: 'P&F bef. tests', style: { width: '5%' }, changeFlex: 'center' },
    { title: 'Full tests', style: { width: '5%' }, changeFlex: 'center' },
    { title: 'P&F aft. tests', style: { width: '5%' }, changeFlex: 'center' },
    { title: 'Release notes', style: { width: '5%' }, changeFlex: 'center' },
    { title: 'Release status', style: { width: '5%' }, changeFlex: 'center' },
    { title: 'Stabiliz.', changeFlex: 'center' },
  ],
};
export const ROLE_SYSTEM = {
  BA: 'BA',
  DESIGNER: 'DESIGNER',
  TECH_LEAD: 'TECH_LEAD',
  BE_DEV: 'BE_DEV',
  MOBILE_DEV: 'MOBILE_DEV',
  WEB_DEV: 'WEB_DEV',
  QA: 'QA',
  DEVOPS: 'DEVOPS',
  PM: 'PM',
};
export const findEstimateByKey = (data: any, type: string, key: string) => {
  const el = data.find(
    (item: any) => item.type === type && item.roleSystemName === key,
  );
  if (el) {
    return el.estimate;
  } else return '—';
};
export const estimateForFE = (data: any, type: string) => {
  const mobDev = data.find(
    (item: any) =>
      item.roleSystemName === ROLE_SYSTEM.MOBILE_DEV && item.type === type,
  );
  const webDev = data.find(
    (item: any) =>
      item.roleSystemName === ROLE_SYSTEM.WEB_DEV && item.type === type,
  );
  if (mobDev?.estimate && webDev?.estimate) {
    return mobDev?.estimate + webDev?.estimate;
  } else if (mobDev?.estimate || webDev?.estimate) {
    return mobDev?.estimate || webDev?.estimate;
  } else return '—';
};
export const findTotalByType = (data: any, type: string) => {
  if (!data?.length) return '';
  const el = data?.find((item: any) => item.type === type);
  if (el) {
    return el.estimate;
  } else return '—';
};

export const sumTestingValue = (
  value1: string | number,
  value2: string | number,
) => {
  if (value1 === '—' && value2 === '—') return '—';
  if (value1 === '—' && typeof Number(value2) === 'number') {
    return value2;
  } else return value1;
};

export const sumTotalByKey = (data: any[], type: string) => {
  const roleKeys = [
    ROLE_SYSTEM.QA,
    ROLE_SYSTEM.PM,
    ROLE_SYSTEM.DEVOPS,
    ROLE_SYSTEM.BA,
    ROLE_SYSTEM.DESIGNER,
    ROLE_SYSTEM.TECH_LEAD,
    ROLE_SYSTEM.BE_DEV,
    ROLE_SYSTEM.MOBILE_DEV,
    ROLE_SYSTEM.WEB_DEV,
  ];

  return data
    .filter((item) => item?.type === type)
    .reduce(
      (acc, item) => {
        const { roleSystemName, estimate } = item;
        if (roleKeys.includes(roleSystemName)) {
          acc[roleSystemName] =
            (acc[roleSystemName] || 0) + Number(estimate || 0);
        }
        return acc;
      },
      {
        [ROLE_SYSTEM.QA]: 0,
        [ROLE_SYSTEM.PM]: 0,
        [ROLE_SYSTEM.DEVOPS]: 0,
        [ROLE_SYSTEM.BA]: 0,
        [ROLE_SYSTEM.DESIGNER]: 0,
        [ROLE_SYSTEM.TECH_LEAD]: 0,
        [ROLE_SYSTEM.BE_DEV]: 0,
        [ROLE_SYSTEM.MOBILE_DEV]: 0,
        [ROLE_SYSTEM.WEB_DEV]: 0,
      },
    );
};

type StatusOption = {
  value: string;
  label: string;
  style?: any;
};
type StepStatuses = Record<string, StatusOption[]>;
type StatusMap = Record<string, Record<string, string>>;

export const STEPS: string[] = [
  'baEstimate',
  'designEstimate',
  'srs',
  'design',
  'techDesign',
  'devEstimation',
  'qaChecklist',
  'beDevelopment',
  'beTests',
  'feDevelopment',
  'timeCompareBeforeTesting',
  'fullTesting',
  'timeCompareAfterTesting',
  'releaseNotes',
  'releaseStatus',
  'stabilization',
];
export const STEP_STATUSES: StepStatuses = {
  baEstimate: [
    {
      value: 'NOT_STARTED',
      label: 'Not started',
      style: 'neutral',
    },
    {
      value: 'READY_FOR_REVIEW',
      label: 'Ready for review',
      style: 'neutral',
    },
    {
      value: 'APPROVED',
      label: 'Approved',
      style: 'finished',
    },
    {
      value: 'NOT_REQUIRED',
      label: 'Not required',
      style: 'finished',
    },
  ],
  designEstimate: [
    { value: 'NOT_STARTED', label: 'Not started', style: 'neutral' },
    { value: 'READY_FOR_REVIEW', label: 'Ready for review', style: 'neutral' },
    {
      value: 'APPROVED',
      label: 'Approved',
      style: 'finished',
    },
    {
      value: 'NOT_REQUIRED',
      label: 'Not required',
      style: 'finished',
    },
  ],
  srs: [
    { value: 'NOT_STARTED', label: 'Not started', style: 'neutral' },
    { value: 'IN_PROGRESS', label: 'In progress', style: 'neutral' },
    { value: 'DRAFT_IS_READY', label: 'Draft is ready', style: 'neutral' },
    {
      value: 'INTERNALLY_REVIEWED',
      label: 'Internally reviewed',
      style: 'neutral',
    },
    { value: 'FINALIZED', label: 'Finalized', style: 'neutral' },
    { value: 'CUSTOMER_REVIEW', label: 'Customer review', style: 'neutral' },
    {
      value: 'APPROVED_BY_CUSTOMER',
      label: 'Approved by customer',
      style: 'finished',
    },
    {
      value: 'NOT_REQUIRED',
      label: 'Not required',
      style: 'finished',
    },
  ],
  design: [
    { value: 'NOT_STARTED', label: 'Not started', style: 'neutral' },
    { value: 'IN_PROGRESS', label: 'In progress', style: 'neutral' },
    { value: 'READY_FOR_REVIEW', label: 'Ready for review', style: 'neutral' },
    {
      value: 'INTERNALLY_REVIEWED',
      label: 'Internally reviewed',
      style: 'neutral',
    },
    { value: 'FINALIZED', label: 'Finalized', style: 'neutral' },
    { value: 'CUSTOMER_REVIEW', label: 'Customer review', style: 'neutral' },
    {
      value: 'APPROVED_BY_CUSTOMER',
      label: 'Approved by customer',
      style: 'finished',
    },
    {
      value: 'NOT_REQUIRED',
      label: 'Not required',
      style: 'finished',
    },
  ],
  techDesign: [
    { value: 'NOT_STARTED', label: 'Not started', style: 'neutral' },
    { value: 'IN_PROGRESS', label: 'In progress', style: 'neutral' },
    { value: 'DEMONSTRATED', label: 'Demonstrated', style: 'neutral' },
    { value: 'APPROVED', label: 'Approved', style: 'neutral' },
    {
      value: 'NOT_REQUIRED',
      label: 'Not required',
      style: 'finished',
    },
    {
      value: 'UPDATED_AFTER_QA',
      label: 'Updated after QA',
      style: 'finished',
    },
  ],
  devEstimation: [
    { value: 'NOT_STARTED', label: 'Not started', style: 'neutral' },
    { value: 'READY_FOR_REVIEW', label: 'Ready for review', style: 'neutral' },
    {
      value: 'CONFIRMED_BY_TECH_LEAD',
      label: 'Confirmed by Tech lead',
      style: 'neutral',
    },
    {
      value: 'APPROVED',
      label: 'Approved',
      style: 'finished',
    },
    {
      value: 'NOT_REQUIRED',
      label: 'Not required',
      style: 'finished',
    },
  ],
  qaChecklist: [
    { value: 'NOT_STARTED', label: 'Not started', style: 'neutral' },
    { value: 'IN_PROGRESS', label: 'In progress', style: 'neutral' },
    { value: 'READY_FOR_REVIEW', label: 'Ready for review', style: 'neutral' },
    {
      value: 'FINALIZED_AND_APPROVED',
      label: 'Finalized and approved',
      style: 'finished',
    },
    {
      value: 'NOT_REQUIRED',
      label: 'Not required',
      style: 'finished',
    },
  ],
  beDevelopment: [
    { value: 'NOT_STARTED', label: 'Not started', style: 'neutral' },
    { value: 'IN_PROGRESS', label: 'In progress', style: 'neutral' },
    {
      value: 'READY_FOR_CODE_REVIEW',
      label: 'Ready for code review',
      style: 'neutral',
    },
    {
      value: 'FINISHED',
      label: 'Finished',
      style: 'finished',
    },
    {
      value: 'NOT_REQUIRED',
      label: 'Not required',
      style: 'finished',
    },
  ],
  beTests: [
    { value: 'NOT_STARTED', label: 'Not started', style: 'neutral' },
    { value: 'IN_PROGRESS', label: 'In progress', style: 'neutral' },
    {
      value: 'COMPLETED',
      label: 'Completed',
      style: 'finished',
    },
    {
      value: 'NOT_REQUIRED',
      label: 'Not required',
      style: 'finished',
    },
  ],
  feDevelopment: [
    { value: 'NOT_STARTED', label: 'Not started', style: 'neutral' },
    { value: 'IN_PROGRESS', label: 'In progress', style: 'neutral' },
    {
      value: 'READY_FOR_CODE_REVIEW',
      label: 'Ready for code review',
      style: 'neutral',
    },
    {
      value: 'COMPLETED',
      label: 'Completed',
      style: 'finished',
    },
    { value: 'DEMO', label: 'Demo', style: 'neutral' },
    {
      value: 'NOT_REQUIRED',
      label: 'Not required',
      style: 'finished',
    },
  ],
  timeCompareBeforeTesting: [
    { value: 'NOT_STARTED', label: 'Not started', style: 'neutral' },
    {
      value: 'DONE',
      label: 'Done',
      style: 'finished',
    },
    {
      value: 'NOT_REQUIRED',
      label: 'Not required',
      style: 'finished',
    },
  ],
  fullTesting: [
    { value: 'NOT_STARTED', label: 'Not started', style: 'neutral' },
    { value: 'IN_PROGRESS', label: 'In progress', style: 'neutral' },
    { value: 'INTERNAL_DEMO', label: 'Internal demo', style: 'neutral' },
    { value: 'DEMO_TO_CUSTOMER', label: 'Demo for customer', style: 'neutral' },
    { value: 'QA_REPORT', label: 'QA report', style: 'neutral' },
    {
      value: 'TESTING_COMPLETED',
      label: 'Testing completed',
      style: 'finished',
    },
  ],
  timeCompareAfterTesting: [
    { value: 'NOT_STARTED', label: 'Not started', style: 'neutral' },
    {
      value: 'DONE',
      label: 'Done',
      style: 'finished',
    },
    {
      value: 'NOT_REQUIRED',
      label: 'Not required',
      style: 'finished',
    },
  ],
  releaseNotes: [
    { value: 'NOT_STARTED', label: 'Not started', style: 'neutral' },
    { value: 'IN_PROGRESS', label: 'In progress', style: 'neutral' },
    { value: 'READY_FOR_REVIEW', label: 'Ready for review', style: 'neutral' },
    {
      value: 'APPROVED',
      label: 'Approved',
      style: 'finished',
    },
    {
      value: 'NOT_REQUIRED',
      label: 'Not required',
      style: 'finished',
    },
  ],
  releaseStatus: [
    { value: 'NOT_STARTED', label: 'Not started', style: 'neutral' },
    {
      value: 'IMPLEMENTATION_FINISHED',
      label: 'Implementation finished',
      style: 'neutral',
    },
    { value: 'PREPARATION', label: 'Preparation', style: 'neutral' },
    {
      value: 'MOBILE_BUILD_APPROVED',
      label: 'Mobile build approved',
      style: 'neutral',
    },
    { value: 'READY', label: 'Ready', style: 'neutral' },
    {
      value: 'RELEASED',
      label: 'Released',
      style: 'finished',
    },
    {
      value: 'NOT_REQUIRED',
      label: 'Not required',
      style: 'finished',
    },
  ],
  stabilization: [
    { value: 'NOT_STARTED', label: 'Not started', style: 'neutral' },
    { value: 'STARTED', label: 'Started', style: 'neutral' },
    {
      value: 'SUCCESS',
      label: 'Success!!!',
      style: 'finished',
    },
    {
      value: 'NOT_REQUIRED',
      label: 'Not required',
      style: 'finished',
    },
  ],
};
export const STEPS_USER_VIEW: any = {
  baEstimate: 'BA est.',
  designEstimate: 'Design est.',
  srs: 'SRS',
  design: 'Design',
  techDesign: 'Tech. design',
  devEstimation: 'Dev est.',
  qaChecklist: 'QA checklist',
  beDevelopment: 'BE dev.',
  beTests: 'BE tests',
  feDevelopment: 'FE dev.',
  timeCompareBeforeTesting: 'P&F bef. tests',
  fullTesting: 'Full tests',
  timeCompareAfterTesting: 'P&F aft. tests',
  releaseNotes: 'Release notes',
  releaseStatus: 'Release status',
  stabilization: 'Stabiliz.',
};

export const useStatusMap = (stepStatuses: StepStatuses): StatusMap => {
  return useMemo(() => {
    const map: StatusMap = {};

    Object.keys(stepStatuses).forEach((step) => {
      map[step] = stepStatuses[step].reduce<Record<string, string>>(
        (acc, status) => {
          acc[status.value] = status.label;
          return acc;
        },
        {},
      );
    });

    return map;
  }, [stepStatuses]);
};
