import { Option } from '../../components/shared/radio/radio';
import { useEffect, useState } from 'react';
import { Release } from './types';
import { releaseApi } from '../../api/release';
import { projectsApi } from '../../api/projects';
import { User } from '../../components/shared/multi-select-user-id/multi-select-user';

export const useFilter = (initialData: Option[], withoutAll = false) => {
  const [filter, setFilter] = useState<Option[]>(
    initialData
      .map((el) => ({ ...el, selected: false }))
      .concat(
        withoutAll ? [] : [{ value: 'ALL', body: 'All', selected: false }],
      ),
  );

  useEffect(() => {
    setFilter(
      initialData
        .map((el) => ({ ...el, selected: false }))
        .concat(
          withoutAll ? [] : [{ value: 'ALL', body: 'All', selected: false }],
        ),
    );
  }, [initialData]);

  const selectedOption = filter.find((el) => !!el.selected);

  const selectOption = (option: Option) =>
    setFilter((prev) =>
      prev.map((el) => ({ ...el, selected: option.value === el.value })),
    );

  return { filter, selectOption, selectedOption };
};

export const useRelease = () => {
  const [releases, setReleases] = useState<Release[]>([]);

  const getReleases = async () => {
    const { data } = await releaseApi.getReleasesList({
      skip: 0,
      take: 999,
      sort: 'id',
      order: 'ASC',
    });

    setReleases(data?.list);
  };

  useEffect(() => {
    getReleases();
  }, []);

  return releases;
};

export const useAssignees = () => {
  const [assignees, setAssignees] = useState<User[]>([]);

  const getAssignees = async () => {
    const { data } = await projectsApi.getProjectsUsers({
      skip: 0,
      take: 999,
      sort: 'id',
      order: 'ASC',
    });

    setAssignees(
      data?.list?.map((el: any) => {
        const user = el?.user;
        return {
          label: `${user?.firstName || ''} ${user?.lastName || ''}`,
          value: el.id,
          details: user.email,
        };
      }) || [],
    );
  };

  useEffect(() => {
    getAssignees();
  }, []);

  return assignees;
};

import { useQuery } from 'react-query';
import api from '../../api/axios.config';
import { HTTP_STATUSES } from '../../constants';

const fetchData =
  (url: string) =>
  async ({ queryKey }: any) => {
    const [, params] = queryKey;

    const { status, data } = await api.get(url, {
      params,
    });

    if (status !== HTTP_STATUSES.ok) {
      throw new Error('Network response was not ok');
    }

    return data;
  };

type FetchDataProps = {
  take: number;
  skip: number;
  sort: string;
  order: string;
  search: { [key: string]: any };
  filter: { [key: string]: any };
  url: string;
  key: string;
};

export const useFetchData = ({
  take,
  search,
  filter,
  order,
  sort,
  url,
  key,
}: FetchDataProps) => {
  const { data, error, isLoading } = useQuery(
    [key, { take, search, filter, order, sort }],
    fetchData(url),
    {
      keepPreviousData: true,
    },
  );

  return {
    data,
    error,
    isLoading,
  };
};
