import React, { useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import { object, string } from 'yup';
import { useFormik } from 'formik';

import { Task } from '../types';
import { useRelease } from '../hooks';
import { taskApi } from '../../../api/task';
import { HTTP_STATUSES } from '../../../constants';
import { getProjectId } from '../../../utils/localStorage';
import { notesApi } from '../../../api/notes';
import {
  CustomInput,
  OutlineBtn,
  PrimaryBtn,
  Select,
  TextArea,
  ViewModal,
} from '../../../components/shared';
import ExitIcon from '../../../assets/icons/exit-icon';
import TaskIcon from '../../../assets/icons/task';
import Assign from '../../../assets/icons/assign';
import CheckBox from '../../../components/shared/check-box/check-box';
import { PRIORITY, STATUS, TYPE } from '../constants';
import CustomDayPicker from '../../../components/shared/datepicker/day-picker';
import MultiSelectUserId from '../../../components/shared/multi-select-user-id/multi-select-user';
import { dateToISODate, removeNullUndefined } from '../../../utils';
import styles from '../Notes.module.css';

const NO_RELEASE = 'NO_RELEASE';

type CreateNoteProps = {
  Button: (onClick: () => void) => React.ReactElement;
  refresh: () => void;
  featureId?: string | undefined | null;
};

export function CreateNote({ refresh, Button, featureId }: CreateNoteProps) {
  const [modalOpened, setModalOpened] = useState(false);

  const openModal = () => {
    setModalOpened(true);
  };

  const [widerModalOpened, setWiderModalOpened] = useState(false);
  const [releaseTasks, setReleaseTasks] = useState<Task[]>([]);

  const releases = useRelease();

  const releaseOptions: { label: string; value: number | string }[] = useMemo(
    () => [
      { label: 'No release', value: NO_RELEASE },
      ...releases.map((el) => ({
        label: `Release ${el.releaseNumber}`,
        value: el?.releaseNumber,
      })),
    ],
    [releases],
  );

  const mappedTasksByRelease = useMemo(() => {
    const uniqueReleaseIds = Array.from(
      new Set(
        releaseTasks
          .map((el) => el?.release?.releaseNumber)
          .sort()
          .reverse(),
      ),
    );

    return uniqueReleaseIds.map((releaseId) => ({
      releaseNumber: releaseId ?? null,
      relatedTasks: releaseTasks?.filter(
        (task) => task?.release?.releaseNumber === releaseId,
      ),
    }));
  }, [releaseTasks]);

  const mappedTasksToSelectOptions = mappedTasksByRelease.map((mappedTask) => ({
    name: mappedTask?.releaseNumber
      ? `Release ${mappedTask.releaseNumber}`
      : 'No included in any release',
    options: mappedTask.relatedTasks.map((task) => ({
      label: task.name,
      value: task.id,
    })),
  }));

  const getReleaseTasks = async () => {
    setReleaseTasks([]);
    const params: any = {
      skip: 0,
      take: 100,
      sort: 'id',
      order: 'ASC',
    };
    const response = await taskApi.getTasksList(params);
    if (response?.status === HTTP_STATUSES.ok) {
      setReleaseTasks(response?.data?.list);
    }
  };

  useEffect(() => {
    getReleaseTasks();
  }, []);

  const notesForm = useFormik({
    initialValues: {
      name: '',
      featureId: featureId,
      releaseId: null,
      meetUrl: '',
      status: 'NEW',
      description: '',
      deadline: null,
      priority: null,
      type: 'QUESTION',
      isHidden: false,
      assigneeIds: [],
      projectId: Number(getProjectId()),
    },
    onSubmit: async (values, { resetForm }) => {
      const body = {
        ...values,
        deadline: values.deadline ? dateToISODate(values.deadline) : null,
      };
      const { status } = await notesApi.createNote(removeNullUndefined(body));

      if (status === HTTP_STATUSES.created) {
        setWiderModalOpened(false);
        refresh();
        resetForm();
      }
    },
    validationSchema: object().shape({
      name: string().max(100),
    }),
  });

  const [isShowingTask, setShowingTask] = useState(false);
  const [isShowingAssignee, setShowingAssignee] = useState(false);

  const closeAssignee = () => {
    setShowingAssignee(false);
    notesForm.setFieldValue('assigneeIds', []);
  };

  const setFormValue = (name: string) => (v: string) =>
    notesForm.setFieldValue(name, v);

  const setSelectFormValue = (name: string) => (e: { value: string }) => {
    notesForm.setFieldValue(name, e?.value);
  };

  const closeModal = () => {
    setModalOpened(false);
    notesForm.resetForm();
  };

  const closeWiderModal = () => {
    setWiderModalOpened(false);
  };

  const onContinue = () => {
    setModalOpened(false);
    setWiderModalOpened(true);
  };

  return (
    <>
      {Button(openModal)}

      {modalOpened && (
        <ViewModal
          subTitle={''}
          text={
            <div>
              <div className={styles.field}>
                <CustomInput
                  label="Name"
                  value={notesForm.values.name}
                  onChange={setFormValue('name')}
                  maxLength={100}
                  showCountDown
                />
              </div>

              {isShowingTask && (
                <div className={styles.field}>
                  <div className={styles.row}>
                    <div className={styles.flex}>
                      <Select
                        label="Related task"
                        value={notesForm.values.featureId}
                        onChange={setSelectFormValue('featureId')}
                        options={mappedTasksToSelectOptions}
                      />
                    </div>
                    <div className={classNames(styles.icon, styles.fieldIcon)}>
                      <ExitIcon onClick={() => setShowingTask(false)} />
                    </div>
                  </div>
                </div>
              )}

              {isShowingAssignee && (
                <div className={styles.field}>
                  <div className={styles.row}>
                    <div className={styles.flex}>
                      <MultiSelectUserId
                        defaultUsers={notesForm.values.assigneeIds}
                        onChange={setFormValue('assigneeIds')}
                        label="Assigned employee"
                      />
                    </div>
                    <div className={classNames(styles.icon, styles.fieldIcon)}>
                      <ExitIcon onClick={closeAssignee} />
                    </div>
                  </div>
                </div>
              )}

              <div className={styles.iconContainer}>
                {!isShowingTask && (
                  <div className={styles.icon}>
                    <TaskIcon onClick={() => setShowingTask(true)} />
                  </div>
                )}
                {!isShowingAssignee && (
                  <div className={styles.icon}>
                    <Assign onClick={() => setShowingAssignee(true)} />
                  </div>
                )}
              </div>
              <div className={classNames(styles.field, styles.checkbox)}>
                <div className={styles.checkboxInput}>
                  <CheckBox
                    checked={notesForm.values.isHidden}
                    onChange={(e) =>
                      notesForm.setFieldValue('isHidden', e?.target?.checked)
                    }
                  />
                </div>
                <div className={styles.checkboxText}>
                  <div className={styles.checkboxTextLabel}>Hidden</div>
                  <div className={styles.checkboxTextDescription}>
                    This note will be visible to members assign to the note
                  </div>
                </div>
              </div>
              <div className={classNames(styles.field, styles.row)}>
                <div className={styles.btn}>
                  <PrimaryBtn text="Create" onClick={notesForm.submitForm} />
                </div>
                <div className={styles.btn}>
                  <OutlineBtn text="Continue" onClick={onContinue} />
                </div>
                <div className={styles.btn}>
                  <OutlineBtn isSecondary text="Cancel" onClick={closeModal} />
                </div>
              </div>
            </div>
          }
          title="Create a note"
          callback={closeModal}
        />
      )}

      {widerModalOpened && (
        <ViewModal
          subTitle={''}
          width={'90%'}
          callback={closeWiderModal}
          title={'Create note'}
          text={
            <div>
              <div className={styles.field}>
                <CustomInput
                  label="Name"
                  value={notesForm.values.name}
                  onChange={setFormValue('name')}
                  maxLength={100}
                  showCountDown
                />
              </div>

              <div className={styles.field}>
                <div className={styles.row}>
                  <div className={styles.col}>
                    <TextArea
                      label="Detailed description"
                      value={notesForm.values.description}
                      maxTextLength={3000}
                      showCountDown
                      symbols={notesForm.values.description.length}
                      onChange={(e) =>
                        notesForm.setFieldValue('description', e?.target?.value)
                      }
                      cols={2}
                      rows={2}
                    />
                  </div>
                  <div className={styles.col}>
                    <TextArea
                      label="Meet url"
                      value={notesForm.values.meetUrl}
                      maxTextLength={100}
                      showCountDown
                      symbols={notesForm.values.meetUrl?.length || 0}
                      onChange={(e) => {
                        notesForm.setFieldValue('meetUrl', e?.target?.value);
                      }}
                      cols={2}
                      rows={2}
                    />
                  </div>
                </div>
              </div>
              <div className={styles.field}>
                <div className={styles.row}>
                  <div className={classNames(styles.col8, styles.mr8)}>
                    <Select
                      label="Related task"
                      value={notesForm.values.featureId}
                      onChange={setSelectFormValue('featureId')}
                      options={mappedTasksToSelectOptions}
                    />
                  </div>
                  <div className={classNames(styles.col4, styles.ml8)}>
                    <Select
                      label="Related release"
                      value={notesForm.values.releaseId}
                      onChange={setSelectFormValue('releaseId')}
                      options={releaseOptions}
                    />
                  </div>
                </div>
              </div>

              <div className={styles.field}>
                <div className={styles.row}>
                  <div className={styles.col4}>
                    <Select
                      label="Status"
                      value={notesForm.values.status}
                      onChange={setSelectFormValue('status')}
                      options={STATUS}
                    />
                  </div>
                  <div className={styles.col4}>
                    <CustomDayPicker
                      label="Deadline date & time"
                      onSetDate={(value: string | Date) =>
                        notesForm.setFieldValue('deadline', value)
                      }
                      defaultDate={notesForm?.values?.deadline}
                      showTimePicker
                    />
                  </div>
                  <div className={styles.col4}>
                    <Select
                      label="Priority"
                      value={notesForm.values.priority}
                      onChange={setSelectFormValue('priority')}
                      options={PRIORITY}
                    />
                  </div>
                  <div className={styles.col4}>
                    <Select
                      label="Type of the note"
                      value={notesForm.values.type}
                      onChange={setSelectFormValue('type')}
                      options={TYPE}
                    />
                  </div>
                </div>
              </div>

              <div className={styles.field}>
                <div className={styles.label}> Assignees</div>
                <MultiSelectUserId
                  emptyListTitle="No employees assigned"
                  onChange={() => {}}
                  label="Add employee"
                  defaultUsers={notesForm.values.assigneeIds}
                />
              </div>

              <div className={classNames(styles.field, styles.row)}>
                <div className={styles.btn}>
                  <PrimaryBtn text="Create" onClick={notesForm.submitForm} />
                </div>

                <div className={styles.btn}>
                  <OutlineBtn
                    isSecondary
                    text="Cancel"
                    onClick={closeWiderModal}
                  />
                </div>
              </div>
            </div>
          }
        />
      )}
    </>
  );
}
