// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TaskView_wrapper__QHKQm {
  align-items: center;
  background: var(--white);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 16px;
  width: 100%;
}
`, "",{"version":3,"sources":["webpack://./src/renderer/screens/Tasks/task-view/TaskView.module.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,wBAAwB;EACxB,kBAAkB;EAClB,aAAa;EACb,sBAAsB;EACtB,YAAY;EACZ,aAAa;EACb,WAAW;AACb","sourcesContent":[".wrapper {\n  align-items: center;\n  background: var(--white);\n  border-radius: 8px;\n  display: flex;\n  flex-direction: column;\n  height: 100%;\n  padding: 16px;\n  width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `TaskView_wrapper__QHKQm`
};
export default ___CSS_LOADER_EXPORT___;
