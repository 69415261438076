import React, { ReactElement } from 'react';
import styles from './radio.module.css';

export type Option = {
  body: string | ReactElement;
  value: string | number;
  selected?: boolean;
};

type RadioProps = {
  options: Option[];
  value?: string | number | undefined;
  onOptionChange: any;
  round?: boolean;
};

const Radio = (props: RadioProps) => {
  const activeClassName = props.round
    ? styles.roundSelectedOption
    : styles.selectedOption;
  const defaultClassName = props.round ? styles.roundOption : styles.option;

  return (
    <div className={styles.wrapper}>
      {props.options.map((option: Option) => (
        <div
          onClick={() => props.onOptionChange(option)}
          key={option.value}
          className={
            props.value === option.value ? activeClassName : defaultClassName
          }
        >
          {option.body}
        </div>
      ))}
    </div>
  );
};

export default Radio;
