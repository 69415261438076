import { Tooltip } from '../../../../components/shared';
import { colors } from '../../../../constants/colors';
import { statusForModule } from '../../constants';
import styles from './ModuleProcess.module.css';

export const ModuleProcess = ({ modules }: any) => {
  const statusColor = {
    not_started: colors.light_background,
    ready: colors.main_font,
    passed: colors.danger,
  };

  const colorForModule = (isDeployed: boolean | undefined) => {
    if (isDeployed === undefined || isDeployed === null)
      return statusColor.not_started;
    return isDeployed ? statusColor.ready : statusColor.passed;
  };
  return (
    <div className={styles.moduleWrapper}>
      {modules?.length ? (
        modules.map((m: any) => {
          const fillColor = colorForModule(m?.state);
          const status = statusForModule(m?.state);
          const isOffStatus = status === 'Off';
          return (
            <div
              className={styles.tooltipContainer}
              key={m?.id}
              style={{ background: fillColor }}
            >
              <div className={styles.tooltipText}>
                {m.name}{' '}
                <Tooltip
                  label={status || ''}
                  customStyles={{
                    background: fillColor,
                    color: isOffStatus ? colors.main_font : colors.white,
                  }}
                />
              </div>
            </div>
          );
        })
      ) : (
        <></>
      )}
    </div>
  );
};
