// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SDLS_wrapper__jkhlW {
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 100%;
  padding-bottom: 16px;
}
.SDLS_searchRow__vNh-q {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 14px;
  width: 100%;
}
.SDLS_search__U7n61 {
  align-items: center;
  display: flex;
  justify-content: flex-end;
  gap: 12px;
  color: var(--main-font);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  width: 100%;
}
.SDLS_loadingContainer__Uu9J2 {
  align-items: center;
  display: flex;
  justify-content: center;
  margin-top: 20%;
  width: 100%;
}
`, "",{"version":3,"sources":["webpack://./src/renderer/screens/Tasks/SDLC/SDLS.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,QAAQ;EACR,WAAW;EACX,oBAAoB;AACtB;AACA;EACE,mBAAmB;EACnB,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;EACnB,WAAW;AACb;AACA;EACE,mBAAmB;EACnB,aAAa;EACb,yBAAyB;EACzB,SAAS;EACT,uBAAuB;EACvB,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,iBAAiB;EACjB,WAAW;AACb;AACA;EACE,mBAAmB;EACnB,aAAa;EACb,uBAAuB;EACvB,eAAe;EACf,WAAW;AACb","sourcesContent":[".wrapper {\n  display: flex;\n  flex-direction: column;\n  gap: 4px;\n  width: 100%;\n  padding-bottom: 16px;\n}\n.searchRow {\n  align-items: center;\n  display: flex;\n  justify-content: space-between;\n  margin-bottom: 14px;\n  width: 100%;\n}\n.search {\n  align-items: center;\n  display: flex;\n  justify-content: flex-end;\n  gap: 12px;\n  color: var(--main-font);\n  font-size: 16px;\n  font-style: normal;\n  font-weight: 500;\n  line-height: 28px;\n  width: 100%;\n}\n.loadingContainer {\n  align-items: center;\n  display: flex;\n  justify-content: center;\n  margin-top: 20%;\n  width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `SDLS_wrapper__jkhlW`,
	"searchRow": `SDLS_searchRow__vNh-q`,
	"search": `SDLS_search__U7n61`,
	"loadingContainer": `SDLS_loadingContainer__Uu9J2`
};
export default ___CSS_LOADER_EXPORT___;
