// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AssigneeAvatar_tooltipContainer__wsFEe {
  position: relative;
  cursor: pointer;
}
.AssigneeAvatar_avatarContainer__jLR8a {
  align-items: center;
  display: flex;
  justify-content: center;
  background-color: var(--white);
  border-radius: 28px;
  height: 28px;
  width: 28px;
}
.AssigneeAvatar_avatarContainer__jLR8a:hover {
  background-color: var(--main-blue);
}
.AssigneeAvatar_tooltipText__aNTSP {
  visibility: hidden;
  position: absolute;
  top: -40px;
  left: 50%;
  transform: translateX(-50%);
  background-color: var(--white);
  color: var(--main-font);
  text-align: center;
  padding: 4px 6px;
  border-radius: 4px;
  border: 1px solid var(--light-border);
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  font-size: 13px;
  font-weight: 500;
  z-index: 4;
  white-space: nowrap;
  opacity: 0;
  transition: opacity 0.3s ease;
}
.AssigneeAvatar_tooltipContainer__wsFEe:hover .AssigneeAvatar_tooltipText__aNTSP {
  visibility: visible;
  opacity: 1;
}
`, "",{"version":3,"sources":["webpack://./src/renderer/components/shared/assignee-avatar/AssigneeAvatar.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,eAAe;AACjB;AACA;EACE,mBAAmB;EACnB,aAAa;EACb,uBAAuB;EACvB,8BAA8B;EAC9B,mBAAmB;EACnB,YAAY;EACZ,WAAW;AACb;AACA;EACE,kCAAkC;AACpC;AACA;EACE,kBAAkB;EAClB,kBAAkB;EAClB,UAAU;EACV,SAAS;EACT,2BAA2B;EAC3B,8BAA8B;EAC9B,uBAAuB;EACvB,kBAAkB;EAClB,gBAAgB;EAChB,kBAAkB;EAClB,qCAAqC;EACrC,0CAA0C;EAC1C,eAAe;EACf,gBAAgB;EAChB,UAAU;EACV,mBAAmB;EACnB,UAAU;EACV,6BAA6B;AAC/B;AACA;EACE,mBAAmB;EACnB,UAAU;AACZ","sourcesContent":[".tooltipContainer {\n  position: relative;\n  cursor: pointer;\n}\n.avatarContainer {\n  align-items: center;\n  display: flex;\n  justify-content: center;\n  background-color: var(--white);\n  border-radius: 28px;\n  height: 28px;\n  width: 28px;\n}\n.avatarContainer:hover {\n  background-color: var(--main-blue);\n}\n.tooltipText {\n  visibility: hidden;\n  position: absolute;\n  top: -40px;\n  left: 50%;\n  transform: translateX(-50%);\n  background-color: var(--white);\n  color: var(--main-font);\n  text-align: center;\n  padding: 4px 6px;\n  border-radius: 4px;\n  border: 1px solid var(--light-border);\n  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);\n  font-size: 13px;\n  font-weight: 500;\n  z-index: 4;\n  white-space: nowrap;\n  opacity: 0;\n  transition: opacity 0.3s ease;\n}\n.tooltipContainer:hover .tooltipText {\n  visibility: visible;\n  opacity: 1;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tooltipContainer": `AssigneeAvatar_tooltipContainer__wsFEe`,
	"avatarContainer": `AssigneeAvatar_avatarContainer__jLR8a`,
	"tooltipText": `AssigneeAvatar_tooltipText__aNTSP`
};
export default ___CSS_LOADER_EXPORT___;
