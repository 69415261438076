import { usersApi } from './../api/users';
import { QueryClient, useQuery } from 'react-query';
import { getAuthUserRequest } from '../api/users';
import { useUserStore } from '../store/userStore';
import { useRolesStore } from '../store/roleStore';

export const createQueryClient = () =>
  new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        retryOnMount: false,
        retry: false,
      },
    },
  });

export const useUserQuery = () => {
  const userStore = useUserStore();

  return useQuery('user', getAuthUserRequest, {
    onSuccess: (response) => {
      userStore.setUser(response?.data);
    },
    onError: () => {
      userStore.setUser(null);
    },
    staleTime: 1000 * 60 * 5,
  });
};
export const useRoleQuery = () => {
  const roleStore = useRolesStore();

  return useQuery('roles', usersApi.getUserRoles, {
    onSuccess: (response) => {
      roleStore.setRoles(response?.data);
    },
    onError: () => {
      roleStore.setRoles(null);
    },
    staleTime: 1000 * 60 * 5,
  });
};
