import { useCallback, useEffect, useState } from 'react';

import { ToastContainer } from 'react-toastify';
import { useLocation, useNavigate } from 'react-router-dom';
import ContentPageContainer from '../../../components/layouts/ContentPageContainer/content-page-container';
import { taskApi } from '../../../api/task';
import { HTTP_STATUSES } from '../../../constants';

import styles from './TaskView.module.css';
import { RoutesData } from '../../../types/routes/routes';

const TaskView = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const taskId = location?.state?.taskId;

  const [taskDetails, setTaskDetails] = useState<any>({});

  const getTaskDetails = useCallback(async () => {
    if (taskId) {
      const response = await taskApi.getTaskById(taskId);
      if (response.status === HTTP_STATUSES.ok) {
        setTaskDetails(response.data);
      }
    }
  }, [taskId]);

  useEffect(() => {
    getTaskDetails();
  }, [getTaskDetails]);

  console.log(taskDetails);

  return (
    <>
      <ToastContainer />
      <ContentPageContainer>
        <>
          Back to
          <div className={styles.wrapper}>
            <div onClick={() => navigate(RoutesData.Tasks)} role="button">
              go back
            </div>
          </div>
        </>
      </ContentPageContainer>
    </>
  );
};

export default TaskView;
