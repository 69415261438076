import { useEffect } from 'react';
import { useMutation } from 'react-query';

import ExitIcon from '../../../../assets/icons/exit-icon';
import { ApproveModal, Table } from '../../../../components/shared';
import { estimateTaskApi } from '../../../../api/estimate';
import { formatDate, transformFullName } from '../../../../utils';
import { SPENDING_TYPE_VALUES } from '../../../../constants/estimate-tasks';
import { colors } from '../../../../constants/colors';
import Loading from '../../../../components/shared/loading/main-loading';
import { HISTORY_HEADERS } from '../constants';
import styles from './Modals.module.css';

const emptyItem = <span style={{ color: colors.icon_gray }}>0</span>;

interface IEstimateHistory {
  close: () => void;
  roleId: string;
  role: { value: string; label: string } | undefined;
  taskId: number;
}

const EstimateHistory = ({ close, roleId, role, taskId }: IEstimateHistory) => {
  const {
    mutate: getHistory,
    data: dataHistory,
    isLoading,
  } = useMutation(estimateTaskApi.getEstimateHistory, {});

  useEffect(() => {
    const params = {
      sort: 'id',
      order: 'DESC',
      filter: JSON.stringify({
        'entityModel.roleId': roleId,
        field: 'ESTIMATE',
        'entityModel.featureId': taskId,
      }),
    };
    getHistory(params);
  }, [roleId, taskId]);

  return (
    <ApproveModal
      btnText=""
      callback={() => {}}
      closeModal={close}
      hideActionBtn
    >
      <div className={styles.historyWrapper}>
        <span role="button" onClick={close} className={styles.closeBtn}>
          <ExitIcon />
        </span>
        <div className={styles.modalTitle}>Estimate & Fact History</div>
        <p className={styles.fontBold}>{role?.label}</p>
        <Table headers={HISTORY_HEADERS} addScrollWrapperY>
          {isLoading ? (
            <tr className={styles.loading}>
              <td colSpan={6}>
                <Loading />
              </td>
            </tr>
          ) : null}
          {dataHistory?.data?.list.length ? (
            dataHistory?.data?.list.map((el: any) => {
              const typeKey = el.entityModel
                .type as keyof typeof SPENDING_TYPE_VALUES;
              const type = SPENDING_TYPE_VALUES[typeKey];
              const date = formatDate(el.updatedAt);
              const updatedBy = transformFullName(
                el.updatedBy.firstName,
                el.updatedBy.lastName,
                'admin',
              );
              const user = transformFullName(
                el.entityModel?.user?.user?.firstName,
                el.entityModel?.user?.user?.lastName,
                'No name',
              );
              const beforeChange = el.beforeChange.estimate
                ? el.beforeChange.estimate
                : 0;
              const afterChange = el.afterChange.estimate
                ? el.afterChange.estimate
                : 0;
              return (
                <tr key={el.id} className={styles.historyItem}>
                  <td>{user}</td>
                  <td>{type}</td>
                  <td>{beforeChange ? beforeChange : emptyItem} h</td>
                  <td>{afterChange ? afterChange : emptyItem} h</td>
                  <td>{updatedBy}</td>
                  <td>
                    {date.date} <br />
                    {date.time}
                  </td>
                </tr>
              );
            })
          ) : (
            <tr className={styles.loading}>
              <td colSpan={6}>No data</td>
            </tr>
          )}
        </Table>
      </div>
    </ApproveModal>
  );
};

export default EstimateHistory;
