export const transformFullName = (
  firstName: string | undefined,
  lastName: string | undefined,
  alternative = '',
) => {
  if (firstName && lastName) return `${firstName} ${lastName}`;
  if (firstName) return firstName;
  if (lastName) return lastName;
  return alternative;
};
