import React from 'react';
import styles from './TableItem.module.css';

function TotalItem({ total, isClientView }: any): React.JSX.Element {
  return (
    <tr className={styles.totalItem}>
      <td style={{ fontWeight: '600', width: '26%' }}>Total</td>
      <td>
        <div className={styles.estimateRow}>
          <span className={styles.estimate}>
            {total?.TEAM_ESTIMATE.BA || 'N/A'}
          </span>
          {isClientView ? (
            <span className={styles.customer}>
              {total?.CUSTOMER_ESTIMATE.BA || 'N/A'}
            </span>
          ) : null}
          <span className={styles.fact}>
            {total?.TEAM_FACT_AFTER_TESTING.BA || 'N/A'}
          </span>
        </div>
      </td>
      <td>
        <div className={styles.estimateRow}>
          <span className={styles.estimate}>
            {total?.TEAM_ESTIMATE.DESIGNER || 'N/A'}
          </span>
          {isClientView ? (
            <span className={styles.customer}>
              {total?.CUSTOMER_ESTIMATE.DESIGNER || 'N/A'}
            </span>
          ) : null}
          <span className={styles.fact}>
            {total?.TEAM_FACT_AFTER_TESTING.DESIGNER || 'N/A'}
          </span>
        </div>
      </td>
      <td>
        <div className={styles.estimateRow}>
          <span className={styles.estimate}>
            {total?.TEAM_ESTIMATE.TECH_LEAD || 'N/A'}
          </span>
          {isClientView ? (
            <span className={styles.customer}>
              {total?.CUSTOMER_ESTIMATE.TECH_LEAD || 'N/A'}
            </span>
          ) : null}
          <span className={styles.fact}>
            {total?.TEAM_FACT_AFTER_TESTING.TECH_LEAD || 'N/A'}
          </span>
        </div>
      </td>
      <td>
        <div className={styles.estimateRow}>
          <span className={styles.estimate}>
            {total?.TEAM_ESTIMATE.BE_DEV || 'N/A'}
          </span>
          {isClientView ? (
            <span className={styles.customer}>
              {total?.CUSTOMER_ESTIMATE.BE_DEV || 'N/A'}
            </span>
          ) : null}
          <span className={styles.fact}>
            {total?.TEAM_FACT_AFTER_TESTING.BE_DEV || 'N/A'}
          </span>
        </div>
      </td>
      <td>
        <div className={styles.estimateRow}>
          <span className={styles.estimate}>
            {total?.TEAM_ESTIMATE.WEB_DEV + total?.TEAM_ESTIMATE.MOBILE_DEV ||
              'N/A'}
          </span>
          {isClientView ? (
            <span className={styles.customer}>
              {total?.CUSTOMER_ESTIMATE.WEB_DEV +
                total?.CUSTOMER_ESTIMATE.MOBILE_DEV || 'N/A'}
            </span>
          ) : null}
          <span className={styles.fact}>
            {total?.TEAM_FACT_AFTER_TESTING.WEB_DEV +
              total?.TEAM_FACT_AFTER_TESTING.MOBILE_DEV || 'N/A'}
          </span>
        </div>
      </td>
      <td>
        <div className={styles.estimateRow}>
          <span className={styles.estimate}>
            {total?.TEAM_ESTIMATE.QA || 'N/A'}
          </span>
          {isClientView ? (
            <span className={styles.customer}>
              {total?.CUSTOMER_ESTIMATE.QA || 'N/A'}
            </span>
          ) : null}
          <span className={styles.fact}>
            {total?.TEAM_FACT_AFTER_TESTING.QA || 'N/A'}
          </span>
        </div>
      </td>
      <td>
        <div className={styles.estimateRow}>
          <span className={styles.estimate}>
            {total?.TEAM_ESTIMATE.DEVOPS || 'N/A'}
          </span>
          {isClientView ? (
            <span className={styles.customer}>
              {total?.CUSTOMER_ESTIMATE.DEVOPS || 'N/A'}
            </span>
          ) : null}
          <span className={styles.fact}>
            {total?.TEAM_FACT_AFTER_TESTING.DEVOPS || 'N/A'}
          </span>
        </div>
      </td>
      <td>
        <div className={styles.estimateRow}>
          <span className={styles.estimate}>
            {total?.TEAM_ESTIMATE.PM || 'N/A'}
          </span>
          {isClientView ? (
            <span className={styles.customer}>
              {total?.CUSTOMER_ESTIMATE.PM || 'N/A'}
            </span>
          ) : null}
          <span className={styles.fact}>
            {total?.TEAM_FACT_AFTER_TESTING.PM || 'N/A'}
          </span>
        </div>
      </td>
      <td></td>
    </tr>
  );
}

export default TotalItem;
