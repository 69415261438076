// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.radio_wrapper__VbxPj{
  display: flex;
  border: 1px solid var(--border-light);
  border-radius: 20px;
  cursor: pointer;
  color: var(--main-font);
  background-color: var(--white);
  font-size: 15px;
  padding: 2px;
}

.radio_option__pCX8I{
  padding: 6px 12px;
  gap: 8px;
  border-radius: 20px;
}

.radio_selectedOption__w8-73{
  border-radius: 20px;
  padding: 6px 12px;
  gap: 8px;
  background-color: var(--light-border)
}

.radio_roundSelectedOption__vWjKU{
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--light-border);
  border-radius: 50%;
}

.radio_roundOption__eOZ1A{
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}
`, "",{"version":3,"sources":["webpack://./src/renderer/components/shared/radio/radio.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,qCAAqC;EACrC,mBAAmB;EACnB,eAAe;EACf,uBAAuB;EACvB,8BAA8B;EAC9B,eAAe;EACf,YAAY;AACd;;AAEA;EACE,iBAAiB;EACjB,QAAQ;EACR,mBAAmB;AACrB;;AAEA;EACE,mBAAmB;EACnB,iBAAiB;EACjB,QAAQ;EACR;AACF;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,qCAAqC;EACrC,kBAAkB;AACpB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,kBAAkB;AACpB","sourcesContent":[".wrapper{\n  display: flex;\n  border: 1px solid var(--border-light);\n  border-radius: 20px;\n  cursor: pointer;\n  color: var(--main-font);\n  background-color: var(--white);\n  font-size: 15px;\n  padding: 2px;\n}\n\n.option{\n  padding: 6px 12px;\n  gap: 8px;\n  border-radius: 20px;\n}\n\n.selectedOption{\n  border-radius: 20px;\n  padding: 6px 12px;\n  gap: 8px;\n  background-color: var(--light-border)\n}\n\n.roundSelectedOption{\n  width: 28px;\n  height: 28px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  background-color: var(--light-border);\n  border-radius: 50%;\n}\n\n.roundOption{\n  width: 28px;\n  height: 28px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  border-radius: 50%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `radio_wrapper__VbxPj`,
	"option": `radio_option__pCX8I`,
	"selectedOption": `radio_selectedOption__w8-73`,
	"roundSelectedOption": `radio_roundSelectedOption__vWjKU`,
	"roundOption": `radio_roundOption__eOZ1A`
};
export default ___CSS_LOADER_EXPORT___;
