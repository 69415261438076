import { useCallback, useEffect, useMemo, useState } from 'react';
import { useMutation } from 'react-query';

import { notesApi } from '../../../api/notes';
import {
  AssigneeAvatarTooltip,
  Chip,
  NonBorderBtn,
  Table,
} from '../../../components/shared';
import { EDITABLE_STATUS, NOTES_HEADERS } from './constants';
import AddIcon from '../../../assets/icons/add';
import { colors } from '../../../constants/colors';
import { formatDate } from '../../../utils';
import ClockIcon from '../../../assets/icons/clock';
import { PRIORITY_FILTER } from '../../../containers/notes/Notes';
import { STATUS } from '../../../containers/notes/constants';
import { NOTE_STATUS } from '../../../containers/notes/types';
import { Edit } from '../../../assets/icons/edit';
import { EditNotesModal } from '../../../containers/notes/EditNotes';
import { CreateNote } from '../../../containers/notes/CreateNote/CreateNote';
import EditAssigneesModal from './modals/edit-assignees-modal';

import styles from './TaskView.module.css';

const TaskNotesItem = ({ note, refreshData }: any) => {
  const [editNote, setEditNote] = useState(false);
  const [showFullAssigneesList, setShowFullAssigneesList] = useState(false);
  const date = formatDate(note?.deadline);
  const priority = PRIORITY_FILTER.find((el) => el.value === note.priority);
  const status = STATUS.find(
    (statusObj: NOTE_STATUS) => statusObj.value === note.status,
  );
  const isEditable = EDITABLE_STATUS.some((el: any) => el === note.status);

  const { mutate: getAssignee, data: assignees } = useMutation(
    notesApi.getNoteAssignee,
    {},
  );

  useEffect(() => {
    if (note?.id) {
      getAssignee(note?.id);
    }
  }, []);
  const isAssignees = assignees?.data?.list?.length;

  return (
    <tr key={note.id} className={styles.notesItem}>
      <td>
        <div className={styles.dateContainer}>
          <AssigneeAvatarTooltip
            name={`${note?.createdBy?.firstName}
                  ${note?.createdBy?.lastName}`}
            containerCustomClassName={styles.minWidth}
          />
          <span className={styles.notesType}>{note?.type?.toLowerCase()}:</span>{' '}
          {note?.name}
        </div>
      </td>
      <td>
        <div className={styles.headerActions}>
          <div className={styles.chipMarginRight}>{priority?.body}</div>
          <div>{priority?.label}</div>
        </div>
      </td>
      <td>
        <div
          style={{ display: 'flex', cursor: isAssignees ? 'pointer' : 'auto' }}
          onClick={() =>
            isAssignees ? setShowFullAssigneesList(true) : undefined
          }
          role="button"
        >
          {isAssignees
            ? assignees?.data?.list.map((user: any, index: number) => (
                <AssigneeAvatarTooltip
                  name={`${user.assignee?.user?.firstName}
                  ${user.assignee?.user?.lastName}`}
                  containerClassName={styles.minWidth}
                  key={user.id}
                  avatarCustomStyles={{
                    marginLeft: index === 0 ? '' : '-10px',
                  }}
                />
              ))
            : ''}
        </div>
      </td>
      <td>
        <div className={styles.dateContainer}>
          {(date?.date || date?.time) && <ClockIcon color={colors.main_font} />}
          <span>
            {date?.date}
            <br />
            {date?.time}
          </span>
        </div>
      </td>
      <td>
        <div className={styles.headerActions}>
          <Chip
            label={status?.label ?? ''}
            customStyles={`${status?.style || ''}`}
          />
          {isEditable && (
            <div
              className={`${styles.icon} ${styles.marginLeft}`}
              role="button"
              onClick={() => setEditNote(true)}
            >
              <Edit />
            </div>
          )}
          <>
            {editNote && (
              <EditNotesModal
                note={note}
                closeModal={() => setEditNote(false)}
                refresh={refreshData}
                assigneeIds={assignees?.data?.list || []}
                refreshAssignees={() => getAssignee(note?.id)}
              />
            )}
            {showFullAssigneesList && (
              <EditAssigneesModal
                assignees={isAssignees ? assignees?.data?.list : []}
                close={() => setShowFullAssigneesList(false)}
                updateList={() => {}}
                taskId={''}
                showListOnly
              />
            )}
          </>
        </div>
      </td>
    </tr>
  );
};

const TaskNotes = ({ taskId }: any) => {
  const { mutate: getNotes, data: notesDetails } = useMutation(
    notesApi.getNotes,
    {},
  );

  const params = useMemo(() => {
    return {
      skip: 0,
      take: 10,
      sort: 'createdAt',
      order: 'DESC',
      filter: JSON.stringify({ featureId: taskId }),
    };
  }, [taskId]);

  useEffect(() => {
    if (taskId) {
      getNotes(params);
    }
  }, [getNotes, params]);

  const refreshData = useCallback(() => {
    getNotes(params);
  }, [params]);

  const isNotes = notesDetails?.data?.list?.length > 0;

  return (
    <div
      className={styles.displayColumn}
      style={{ minHeight: isNotes ? '200px' : 'auto' }}
    >
      <div className={styles.detailsHeader}>
        <span className={styles.detailsTitle}>Notes</span>
        <span className={styles.headerActions}>
          <div>
            <CreateNote
              Button={(onClick) => (
                <NonBorderBtn
                  text="Add Notes"
                  onClick={onClick}
                  icon={
                    <AddIcon width="16" height="16" color={colors.main_blue} />
                  }
                />
              )}
              refresh={refreshData}
              featureId={taskId}
            />
          </div>
        </span>
      </div>
      <div style={{ width: '100%' }}>
        <Table headers={NOTES_HEADERS}>
          {isNotes ? (
            notesDetails?.data?.list?.map((note: any) => {
              return (
                <TaskNotesItem
                  refreshData={refreshData}
                  note={note}
                  key={note.id}
                />
              );
            })
          ) : (
            <tr></tr>
          )}
        </Table>
      </div>
    </div>
  );
};

export default TaskNotes;
