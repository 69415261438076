import { RoutesData } from '../types/routes/routes';

export type INavigation = {
  name: string;
  to: string;
  icon?: React.ComponentType;
};
export const mainNavigation: INavigation[] = [
  {
    name: 'Tasks',
    to: RoutesData.Tasks,
  },
  {
    name: 'Environments dashboard',
    to: RoutesData.EnvironmentsDashboard,
  },
  {
    name: 'Notes',
    to: RoutesData.Notes,
  },
  {
    name: 'Releases',
    to: RoutesData.Releases,
  },
  {
    name: 'Release notes',
    to: RoutesData.ReleaseNotes,
  },
];
