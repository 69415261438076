import React, { useEffect, useState } from 'react';
import CheckMark from '../../../assets/icons/check-mark';
import CheckMarkEmpty from '../../../assets/icons/check-mark-empty';
import { IToggleButtonGroup } from './types';

import styles from './ToggleButtonGroup.module.css';

const ToggleButtonGroup: React.FC<IToggleButtonGroup> = ({
  options,
  defaultValue,
  onChange,
  customStyle,
}) => {
  const [selectedValue, setSelectedValue] = useState(options?.[0].value);
  const handleClick = (value: string | number) => {
    setSelectedValue(value);
    if (onChange) {
      onChange(value);
    }
  };

  useEffect(() => {
    if (defaultValue) {
      handleClick(defaultValue);
    }
  }, [defaultValue]);

  return (
    <div
      className={styles.toggleButtonGroup}
      style={
        customStyle
          ? customStyle
          : { gridTemplateColumns: `repeat(${options?.length}, 1fr)` }
      }
    >
      {options.map((option) => (
        <button
          key={option.value}
          className={`${styles.toggleButton} ${selectedValue === option.value ? styles.active : ''}`}
          onClick={
            option?.disabled ? undefined : () => handleClick(option.value)
          }
        >
          {option.label}
          {selectedValue === option.value ? <CheckMark /> : <CheckMarkEmpty />}
        </button>
      ))}
    </div>
  );
};

export default ToggleButtonGroup;
