import api from './axios.config';

const defaultParams = { skip: 0, take: 100, sort: 'releaseDate', order: 'ASC' };

const getTaskSpendingSumList = (params = defaultParams) => {
  return api.get<any>('/feature-time-spending/summary', { params });
};
const getTaskSpendingList = (params: any) => {
  return api.get<any>('/feature-time-spending', { params });
};
const getTaskEstimate = (id: string | number) => {
  return api.get<any>(`/feature-time-spending/${id}`);
};
const addTaskEstimate = (body: any) => {
  return api.post<any>('/feature-time-spending', body);
};
const updateTaskEstimateById = (id: string | number, body: any) => {
  return api.patch<any>(`/feature-time-spending/${id}`, body);
};
const deleteTaskEstimate = (id: string | number) => {
  return api.delete<any>(`/feature-time-spending/${id}`);
};
const getEstimateHistory = (params: any) => {
  return api.get<any>('/feature-time-spending/change-history', { params });
};
export const estimateTaskApi = {
  addTaskEstimate,
  deleteTaskEstimate,
  getTaskSpendingList,
  getTaskSpendingSumList,
  getTaskEstimate,
  updateTaskEstimateById,
  getEstimateHistory,
};
