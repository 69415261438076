import { useCallback, useEffect, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import { useFormik } from 'formik';
import { number, object, string } from 'yup';
import { useMutation } from 'react-query';

import { taskApi } from '../../../api/task';
import { HTTP_STATUSES, TOAST_MESSAGES } from '../../../constants';
import {
  AssigneeAvatarTooltip,
  CustomDayPicker,
  CustomInput,
  Select,
} from '../../../components/shared';
import Confluence from '../../../assets/icons/confluence';
import Redmine from '../../../assets/icons/redmine';
import PencilIcon from '../../../assets/icons/pencil';
import { TASK_STATUS } from './constants';
import StatusSelect from '../../../components/shared/table-release-modules/status-select';
import DateInput from '../../../components/shared/datepicker/components/CustomInput';
import { formatDateForReleaseTitle } from '../../../utils';
import EditLinkModal from './modals/edit-link-modal';
import EditAssigneesModal from './modals/edit-assignees-modal';
import { TaskBlockProps } from './types';
import EnvModule from './env-module';

import styles from './TaskView.module.css';

const renderLink = (link: string) => {
  return (
    <>
      {link ? (
        <a href={link} target="_blank" rel="noreferrer">
          Link
        </a>
      ) : (
        <span>Link</span>
      )}
    </>
  );
};
const formValidation = object().shape({
  name: string().min(0).max(100).required('Task name is required'),
  priority: number()
    .nullable()
    .min(1, 'Priority must be greater than or equal to 1')
    .max(100, 'Priority must be less than or equal to 100'),
  releaseId: string().nullable().min(0),
  confluenceLink: string().min(0),
  redmineTaskLink: string().min(0),
});

const TaskDetails = ({
  taskId,
  editAvailable,
  releases = [{ label: '', value: '' }],
}: TaskBlockProps) => {
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openAssigneesModal, setOpenAssigneesModal] = useState(false);
  const [editableLink, setEditableLink] = useState<any>({});
  const [initialValues, setInitialValues] = useState({
    projectId: 1,
    name: '',
    status: null as { value: string; label: string } | null,
    priority: undefined,
    releaseId: null,
    releaseDate: null as Date | null | undefined,
    confluenceLink: '',
    redmineTaskLink: '',
  });

  const { mutate: getAssignees, data: taskAssignees } = useMutation(
    taskApi.getTasksAssignees,
    {},
  );
  const { mutate: getDetails, data: taskDetails } = useMutation(
    taskApi.getTaskById,
    {},
  );

  useEffect(() => {
    getDetails(taskId);
  }, [getDetails]);

  useEffect(() => {
    getAssignees(taskId);
  }, [getAssignees]);

  const isAssignees = taskAssignees?.data?.length > 0;
  const updateStatus = useCallback((v: any) => {
    const status = TASK_STATUS.find((s: any) => {
      return s?.value === v;
    });
    form.setFieldValue('status', status);
  }, []);
  useEffect(() => {
    if (taskDetails?.data) {
      const taskData = taskDetails?.data;
      setInitialValues((prevValues) => ({
        ...prevValues,
        ...taskData,
        releaseDate: taskData.releaseDate
          ? new Date(taskData.releaseDate)
          : undefined,
        status: TASK_STATUS.find((s: any) => {
          return s?.value === taskData.status;
        }),
      }));
    }
  }, [taskDetails?.data]);

  const form = useFormik({
    initialValues,
    enableReinitialize: true,
    onSubmit: async (values: any) => {
      if (!form.isValid) return;
      const toastId = toast.loading(TOAST_MESSAGES.LOADING);
      try {
        const { confluenceLink, releaseDate, status } = values;
        const { name, releaseId, redmineTaskLink } = values;

        const body = {
          projectId: 1,
          name,
          priority: values.priority ? Number(values.priority) : null,
          status: status.value,
          releaseId,
          releaseDate,
          redmineTaskLink,
          confluenceLink,
        };
        const response = await taskApi.updateTask(taskId, body);
        if (response.status === HTTP_STATUSES.ok) {
          toast.success(TOAST_MESSAGES.SAVED);
          getDetails(taskId);
          openEditModal && handleCloseEditLink();
        }
      } catch (error) {
        toast.error(TOAST_MESSAGES.CANT_BE_UPDATED);
      } finally {
        toast.dismiss(toastId);
      }
    },
    validationSchema: formValidation,
  });

  const releaseId = useMemo(() => {
    return (
      releases.find((rel: any) => rel.value == form.values.releaseId)?.label ||
      ''
    );
  }, [releases]);
  const handleCloseEditLink = () => {
    setOpenEditModal(false);
    setEditableLink({});
  };

  const isRelease = Boolean(form.values.releaseId);
  const releaseDate = form?.values?.releaseDate
    ? formatDateForReleaseTitle(form?.values?.releaseDate, 'taskViewFormat')
    : '-';

  const environments = taskDetails?.data?.environments?.map(
    (env: any) => env?.environment?.name,
  );
  const redmine = taskDetails?.data?.redmineTaskLink || '';
  const confluence = taskDetails?.data?.confluenceLink || '';
  return (
    <>
      {editAvailable ? (
        <div className={styles.blockWrapper}>
          <CustomInput
            label="Name"
            placeHolder="Enter name"
            name="name"
            value={form.values?.name}
            onChange={(v: string) => form.setFieldValue('name', v)}
            showCountDown
            onEnter={form.submitForm}
          />
          <div className={styles.taskDetails}>
            <div style={{ display: 'flex' }} className={styles.marginRight}>
              <div style={{ width: '60px' }} className={styles.marginRight}>
                <CustomInput
                  label="Priority"
                  placeHolder="Priority"
                  name="priority"
                  value={form.values?.priority}
                  onChange={(v: string) => form.setFieldValue('priority', v)}
                  onEnter={form.submitForm}
                />
              </div>
              <div style={{ width: '94px' }} className={styles.marginRight}>
                <Select
                  label="Release №"
                  name="releaseId"
                  options={releases}
                  onChange={(o: any) => {
                    if (o) {
                      form.setFieldValue('releaseId', o.value);
                      const date = o?.planedDate
                        ? new Date(o.planedDate)
                        : null;
                      form.setFieldValue('releaseDate', date);
                      form.submitForm();
                    } else {
                      form.setFieldValue('releaseId', null);
                      form.setFieldValue('releaseDate', null);
                      form.submitForm();
                    }
                  }}
                  value={form.values.releaseId}
                  additionalClassNameForTitle={styles.selectorWidth}
                  additionalClassNameContainer={styles.selectorWidth}
                  showClear
                />
              </div>

              <div className={styles.dateWidth}>
                {isRelease ? (
                  <div className={`${styles.taskDetailsItem}`}>
                    <span className={styles.taskDetailsItemTitle}>
                      Production release
                    </span>
                    <DateInput disableInput value={releaseDate} />
                  </div>
                ) : (
                  <CustomDayPicker
                    label="Production release"
                    onSetDate={(value: string | Date) => {
                      form.setFieldValue('releaseDate', value);
                      isRelease ? undefined : form.submitForm();
                    }}
                    defaultDate={form?.values?.releaseDate}
                    disableInput
                  />
                )}
              </div>
            </div>
            <div className={styles.taskDetailsItem}>
              <span className={styles.taskDetailsItemTitle}>Assignees</span>
              <div className={styles.headerActions}>
                {isAssignees ? (
                  taskAssignees?.data.map((user: any, index: number) => (
                    <AssigneeAvatarTooltip
                      name={`${user.assignee?.user?.firstName}
                  ${user.assignee?.user?.lastName}`}
                      key={user.id}
                      avatarCustomStyles={{
                        marginLeft: index === 0 ? '' : '-10px',
                      }}
                    />
                  ))
                ) : (
                  <div className={styles.emptyAssignee} />
                )}
                <div role="button" onClick={() => setOpenAssigneesModal(true)}>
                  <PencilIcon className={styles.actionIcon} />
                </div>
              </div>
            </div>
            <div className={styles.taskDetailsItem}>
              <span className={styles.taskDetailsItemTitle}>Confluence</span>
              <div className={styles.linkItem}>
                <Confluence />
                {renderLink(confluence)}
                <PencilIcon
                  className={styles.actionIcon}
                  onClick={() => {
                    setOpenEditModal(true);
                    setEditableLink({
                      field: 'confluenceLink',
                      label: 'Confluence link',
                    });
                  }}
                />
              </div>
            </div>
            <div className={styles.taskDetailsItem}>
              <span className={styles.taskDetailsItemTitle}>Redmine</span>
              <div className={styles.linkItem}>
                <Redmine />
                {renderLink(redmine)}
                <PencilIcon
                  className={styles.actionIcon}
                  onClick={() => {
                    setOpenEditModal(true);
                    setEditableLink({
                      field: 'redmineTaskLink',
                      label: 'Redmine link',
                    });
                  }}
                />
              </div>
            </div>
            <div className={styles.taskDetailsItem}>
              <EnvModule environments={environments} />
            </div>
            <div className={styles.taskDetailsItem}>
              <span className={styles.taskDetailsItemTitle}>Status</span>
              <StatusSelect
                status={form.values.status}
                callback={(v: any) => {
                  updateStatus(v);
                  form.submitForm();
                }}
                options={TASK_STATUS}
                containerStyles={{ justifyContent: 'flex-start' }}
              />
            </div>
          </div>
        </div>
      ) : (
        <div className={styles.blockWrapper}>
          <div className={styles.taskTitle}>{form?.values.name}</div>
          <div className={styles.taskCommonInfo}>
            <div className={styles.taskDetailsItem} style={{ border: 'none' }}>
              <span className={styles.taskDetailsItemTitle}>Priority</span>
              <span className={styles.taskDetailsValue}>
                {form?.values?.priority}
                <span className={styles.priorityMax}>/100</span>
              </span>
            </div>
            <div className={styles.taskDetailsItem}>
              <span className={styles.taskDetailsItemTitle}>Release №</span>
              <span className={styles.taskDetailsValue}>{releaseId}</span>
            </div>
            <div className={styles.taskDetailsItem}>
              <span className={styles.taskDetailsItemTitle}>
                Production release
              </span>
              <span className={styles.taskDetailsValue}>{releaseDate}</span>
            </div>
            <div className={styles.taskDetailsItem}>
              <span className={styles.taskDetailsItemTitle}>Assignees</span>
              <div style={{ display: 'flex' }}>
                {isAssignees
                  ? taskAssignees?.data.map((user: any, index: number) => (
                      <AssigneeAvatarTooltip
                        name={`${user.assignee?.user?.firstName}
                ${user.assignee?.user?.lastName}`}
                        key={user.id}
                        avatarCustomStyles={{
                          marginLeft: index === 0 ? '' : '-10px',
                        }}
                      />
                    ))
                  : null}
              </div>
            </div>
            <div className={styles.taskDetailsItem}>
              <span className={styles.taskDetailsItemTitle}>Confluence</span>
              <div className={styles.linkItem}>
                <Confluence />
                {renderLink(confluence)}
              </div>
            </div>
            <div className={styles.taskDetailsItem}>
              <span className={styles.taskDetailsItemTitle}>Redmine</span>
              <div className={styles.linkItem}>
                <Redmine />
                {renderLink(redmine)}
              </div>
            </div>
            <div className={styles.taskDetailsItem}>
              <EnvModule environments={environments} />
            </div>
            <div className={styles.taskDetailsItem}>
              <span className={styles.taskDetailsItemTitle}>Status</span>
              <StatusSelect
                status={form.values.status}
                callback={() => {}}
                options={TASK_STATUS}
                containerStyles={{ justifyContent: 'flex-start' }}
                disabled
              />
            </div>
          </div>
        </div>
      )}
      {openEditModal ? (
        <EditLinkModal
          form={form}
          close={handleCloseEditLink}
          editableLink={editableLink}
        />
      ) : null}
      {openAssigneesModal ? (
        <EditAssigneesModal
          assignees={isAssignees ? taskAssignees?.data : []}
          close={() => setOpenAssigneesModal(false)}
          updateList={() => getAssignees(taskId)}
          taskId={taskId}
        />
      ) : null}
    </>
  );
};

export default TaskDetails;
