import { useEffect, useState } from 'react';
import AsyncSelect from 'react-select/async';
import { OptionsOrGroups, GroupBase } from 'react-select';
import debounce from 'debounce-promise';
import classNames from 'classnames';

import MultiSelectUserItem from './multi-select-user-item';
import { projectsApi } from '../../../api/projects';
import { colors } from '../../../constants/colors';
import { HTTP_STATUSES } from '../../../constants';
import { IMultiSelectUsers } from './types';
import NonBorderBtn from '../buttons/NonBorderBtn/NonBorderBtn';

import styles from './multi-select-user.module.css';

export type User = {
  value: string | number;
  label: string;
  [key: string]: any;
};

const componentsOverride = {
  DropdownIndicator: () => null,
  IndicatorSeparator: () => null,
};
const selectCustomStyles = {
  control: (baseStyles: any, state: any) => ({
    ...baseStyles,
    borderColor: state.isFocused ? 'transparent' : 'transparent',
    boxShadow: 'none',
    '&:hover': {
      borderColor: 'transparent',
    },
    fontSize: '13px',
  }),
  option: (baseStyles: any, { isFocused }: any) => ({
    ...baseStyles,
    backgroundColor: isFocused ? colors.light_gray : 'inherit',
    fontSize: '13px',
  }),
};

const getUsers = async ({ value = '' }) => {
  try {
    const params = {
      skip: 0,
      take: 5,
      sort: 'id',
      order: 'ASC',
      ...(value && {
        search: JSON.stringify({
          'user.email': value,
          'user.firstName': value,
          'user.lastName': value,
        }),
      }),
    };

    const res = await projectsApi.getProjectsUsers(params);
    if (res?.status === HTTP_STATUSES.ok) {
      return (
        res?.data?.list?.map((el: any) => {
          const user = el?.user;
          return {
            label: `${user?.firstName || ''} ${user?.lastName || ''}`,
            value: el.id,
            details: user.email,
          };
        }) || []
      );
    }
  } catch (error) {
    console.log(error);
  }
};

const promiseOpts = async (inputValue: string): Promise<User[]> => {
  return await getUsers({
    value: inputValue,
  });
};

const MultiSelectUsers = ({
  onChange,
  label = 'User',
  isSubmitted = false,
  error = '',
  placeHolder = 'Enter email',
  emptyListTitle = 'No users',
  showClear = false,
  defaultUsers = [],
  itemModeView = 'compact',
  customUserList = '',
  showUsersCounter = false,
}: IMultiSelectUsers) => {
  const [selectedUsers, setSelectedUsers] = useState<User[]>([]);
  const [updatedUsers, setUpdatedUsers] = useState<User[]>([]);
  const [usersToSelect, setUsersToSelect] = useState<User[]>([]);

  useEffect(() => {
    if (defaultUsers?.length > 0) {
      setUpdatedUsers(defaultUsers);
    }
  }, [defaultUsers]);

  useEffect(() => {
    getUsers({
      value: '',
    }).then((r) => setUsersToSelect(r));
  }, []);

  const wait = 500;
  const loadOptions = async (
    inputValue: string,
  ): Promise<OptionsOrGroups<User, GroupBase<User>>> => {
    if (inputValue.length > 2) {
      return promiseOpts(inputValue);
    }
    return [];
  };
  const debouncedLoadOptions = debounce(
    (inputValue: string) => loadOptions(inputValue),
    wait,
  );

  const handleClearAll = () => {
    setUpdatedUsers([]);
    setUsersToSelect([]);
    onChange([]);
  };

  const handleAddUsers = () => {
    const isSameUser = (a: User, b: User) => a.value === b.value;

    const onlyInLeft = (
      left: User[],
      right: User[],
      compareFunction: (a: User, b: User) => boolean,
    ): User[] =>
      left.filter(
        (leftValue) =>
          !right.some((rightValue) => compareFunction(leftValue, rightValue)),
      );

    const onlyInA = onlyInLeft(selectedUsers, updatedUsers, isSameUser) || [];
    const result: User[] = [...onlyInA];
    if (result.length > 0) {
      setUpdatedUsers([...updatedUsers, ...result]);
      onChange([...updatedUsers, ...result]);
      setSelectedUsers([]);
      setUsersToSelect(onlyInLeft(usersToSelect, result, isSameUser));
    }
  };
  const handleDeleteItem = (option: User) => {
    const optionArray = [...updatedUsers];
    const index = optionArray.indexOf(option);

    if (index > -1) {
      optionArray.splice(index, 1);
      setUpdatedUsers(optionArray);
      onChange(optionArray);
    }
  };
  const handleSelect = (value: any) => {
    setSelectedUsers(value);
  };
  const usersListClassName = classNames(styles.usersList, {
    [customUserList]: customUserList,
  });

  return (
    <div style={{ width: '100%' }}>
      <div className={styles.usersBox}>
        <div className={usersListClassName}>
          {updatedUsers?.length ? (
            <>
              {showUsersCounter ? (
                <span className={styles.noEmployees}>
                  {updatedUsers?.length} employee
                </span>
              ) : null}
              {updatedUsers.map((el) => (
                <MultiSelectUserItem
                  key={el.label}
                  item={el}
                  handleDeleteItem={handleDeleteItem}
                  itemModeView={itemModeView}
                />
              ))}
              {showClear && (
                <span
                  className={styles.clear}
                  onClick={handleClearAll}
                  role="button"
                >
                  Clear all
                </span>
              )}
            </>
          ) : (
            <span className={styles.noEmployees}>{emptyListTitle}</span>
          )}
        </div>
      </div>
      <div className={styles.selectTarget}>
        <div className={styles.label}> {label} </div>
        <div className={styles.input}>
          <div style={{ width: '100%' }}>
            <AsyncSelect
              defaultOptions={usersToSelect}
              isMulti
              loadOptions={debouncedLoadOptions}
              onChange={handleSelect}
              placeholder={placeHolder}
              styles={selectCustomStyles}
              value={selectedUsers}
              components={componentsOverride}
            />
          </div>
          <NonBorderBtn
            text="Add"
            onClick={handleAddUsers}
            classNameBtn={styles.addBtn}
          />
        </div>
        {error && isSubmitted && <div className={styles.error}>{error}</div>}
      </div>
    </div>
  );
};

export default MultiSelectUsers;
