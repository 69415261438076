import { useFormik } from 'formik';
import { array, number, object, string } from 'yup';
import { toast, ToastContainer } from 'react-toastify';
import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';

import { taskApi } from '../../../api/task';
import ContentPageContainer from '../../../components/layouts/ContentPageContainer/content-page-container';
import {
  CustomDayPicker,
  CustomInput,
  HR,
  PrimaryBtn,
  Select,
} from '../../../components/shared';
import { TASK_STATUS } from './constants';
import { OutlineBtn, MultiSelectUsers } from '../../../components/shared';
import { releaseApi } from '../../../api/release';
import { HTTP_STATUSES, TOAST_MESSAGES } from '../../../constants';
import { RoutesData } from '../../../types/routes/routes';
import { dateToISODate } from '../../../utils';
import styles from './CreateNewTask.module.css';

const formValidation = object().shape({
  name: string().min(0).max(100).required('Task name is required'),
  priority: number()
    .nullable()
    .min(1, 'Priority must be greater than or equal to 1')
    .max(100, 'Priority must be less than or equal to 100'),
  releaseId: string().nullable().min(0),
  status: string(),
  confluenceLink: string().min(0),
  redmineTaskLink: string().min(0),
  assigneeIds: array().nullable(),
});

const CreateNewTask = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const currentReleaseID = location?.state?.releaseId;
  const [releases, setReleases] = useState([]);
  const [disableInput, setDisableInput] = useState(false);

  const form = useFormik({
    initialValues: {
      projectId: 1,
      name: '',
      status: '',
      priority: undefined,
      releaseId: null,
      releaseDate: undefined,
      confluenceLink: '',
      redmineTaskLink: '',
      assigneeIds: [],
    },
    onSubmit: async (values) => {
      if (!form.isValid) return;
      const toastId = toast.loading(TOAST_MESSAGES.LOADING);

      const body = {
        projectId: 1,
        name: values.name,
        status: values.status || 'NOT_STARTED',
        priority: values.priority ? Number(values.priority) : null,
        releaseId: values.releaseId ? Number(values.releaseId) : null,
        releaseDate: values.releaseDate
          ? dateToISODate(values.releaseDate)
          : null,
        confluenceLink: values.confluenceLink || '',
        redmineTaskLink: values.redmineTaskLink || '',
      };
      const response = await taskApi.createNewTask(body);
      if (response?.status === HTTP_STATUSES.created) {
        if (values.assigneeIds?.length) {
          const id = response?.data?.id;
          const res = await taskApi.addAssignees(id, {
            assigneeIds: values.assigneeIds,
          });
          if (res?.status !== HTTP_STATUSES.created) {
            toast.error(TOAST_MESSAGES.CANT_BE_UPDATED);
          }
        }
        toast.success(TOAST_MESSAGES.CREATED);
        navigate(RoutesData.Tasks);
      }

      toast.dismiss(toastId);
    },
    validationSchema: formValidation,
    validateOnChange: false,
  });

  const getReleasesData = async () => {
    const response = await releaseApi.getReleasesList();
    if (response?.data?.list) {
      setReleases(
        response?.data?.list?.map((el: any) => {
          return {
            label: el?.releaseNumber,
            value: el?.id,
            planedDate: el?.planedDate,
          };
        }),
      );
      if (currentReleaseID) {
        const value = response?.data?.list?.filter(
          (el: any) => el?.id == currentReleaseID,
        );
        form.setFieldValue('releaseId', value?.[0]?.id);
        form.setFieldValue('releaseDate', value?.[0]?.planedDate);
      }
    }
  };

  useEffect(() => {
    getReleasesData();
  }, [currentReleaseID]);

  const setTargetUsersIds = (arr: any) => {
    const ids = arr?.map((el: any) => el?.value);
    form.setFieldValue('assigneeIds', ids);
  };

  return (
    <>
      <ToastContainer />
      <ContentPageContainer>
        <div className={styles.wrapper}>
          <div className={styles.formWrapper}>
            <div className={styles.formTitle}>Create New Task</div>
            <CustomInput
              label="Name"
              placeHolder="Enter name"
              name="name"
              value={form.values.name}
              onChange={(v: string) => form.setFieldValue('name', v)}
              showCountDown
              error={form.errors.name}
            />
            <div className={styles.row}>
              <CustomInput
                label="Priority"
                placeHolder="0 - 100"
                name="priority"
                value={form.values.priority}
                onChange={(v: string) => form.setFieldValue('priority', v)}
                error={form.errors.priority}
              />
              <Select
                label="Release"
                name="releaseId"
                options={releases}
                onChange={(o: any) => {
                  if (o) {
                    form.setFieldValue('releaseId', o.value);
                    const date = o.planedDate ? new Date(o.planedDate) : null;
                    form.setFieldValue('releaseDate', date);
                    setDisableInput(true);
                  } else {
                    form.setFieldValue('releaseId', null);
                    form.setFieldValue('releaseDate', undefined);
                    setDisableInput(false);
                  }
                }}
                value={form.values.releaseId}
                showClear
              />
              <CustomDayPicker
                label="Production release"
                onSetDate={(value: string | Date) => {
                  form.setFieldValue('releaseDate', value);
                }}
                disableInput={disableInput}
                disableCalendarIcon={disableInput}
                defaultDate={form?.values.releaseDate}
              />
              <Select
                label="Status"
                name="status"
                options={TASK_STATUS}
                onChange={(o: any) => {
                  if (o) {
                    form.setFieldValue('status', o?.value);
                  } else {
                    form.setFieldValue('status', '');
                  }
                }}
                value={form.values.status}
                error={form.errors.status}
                showClear
              />
            </div>
            <HR />
            <div className={styles.rowEmployees}>
              <span className={styles.titleEmployees}>Assignees</span>
            </div>
            <div className={styles.row}>
              <div className={styles.col2}>
                <MultiSelectUsers
                  emptyListTitle="No employees assigned"
                  label="Add employee"
                  placeHolder="Enter employee"
                  onChange={setTargetUsersIds}
                />
              </div>
              <div className={styles.col2} />
            </div>
            <HR />
            <div className={styles.row}>
              <CustomInput
                label="Confluence link"
                placeHolder="https://"
                name="confluenceLink"
                value={form.values.confluenceLink}
                onChange={(v: string) =>
                  form.setFieldValue('confluenceLink', v)
                }
                error={form.errors.confluenceLink}
              />
              <CustomInput
                label="Redmine link"
                placeHolder="https://"
                name="redmineTaskLink"
                value={form.values.redmineTaskLink}
                onChange={(v: string) =>
                  form.setFieldValue('redmineTaskLink', v)
                }
                error={form.errors.redmineTaskLink}
              />
            </div>
            <HR />
            <div className={styles.row}>
              <PrimaryBtn
                text="Create"
                onClick={form.submitForm}
                disabled={form.isSubmitting}
              />
              <OutlineBtn
                text="Cancel"
                onClick={() => navigate(-1)}
                isSecondary
              />
            </div>
          </div>
        </div>
      </ContentPageContainer>
    </>
  );
};

export default CreateNewTask;
