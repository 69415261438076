import React, { useEffect } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useRoleQuery, useUserQuery } from '../../../hooks/useQuery';

function PrivateRoute() {
  const { isSuccess, isLoading } = useUserQuery();
  const { refetch: refetchRole } = useRoleQuery();
  useEffect(() => {
    refetchRole();
  }, []);

  if (isLoading) {
    return <div>Loading</div>;
  }

  return isSuccess ? <Outlet /> : <Navigate to="/login" />;
}

export default PrivateRoute;
