import React from 'react';
import styles from './TableItem.module.css';

const NA = '—';

function TotalItem({ total, isClientView }: any): React.JSX.Element {
  return (
    <tr className={styles.totalItem}>
      <td className={styles.titleTotal}>Total</td>
      <td>
        <div className={styles.estimateRow}>
          <span className={styles.estimate}>
            {total?.TEAM_ESTIMATE.BA || NA}
          </span>
          {isClientView ? (
            <span className={styles.customer}>
              {total?.CUSTOMER_ESTIMATE.BA || NA}
            </span>
          ) : null}
          <span className={styles.fact}>
            {total?.TEAM_FACT_AFTER_TESTING.BA || NA}
          </span>
        </div>
      </td>
      <td>
        <div className={styles.estimateRow}>
          <span className={styles.estimate}>
            {total?.TEAM_ESTIMATE.DESIGNER || NA}
          </span>
          {isClientView ? (
            <span className={styles.customer}>
              {total?.CUSTOMER_ESTIMATE.DESIGNER || NA}
            </span>
          ) : null}
          <span className={styles.fact}>
            {total?.TEAM_FACT_AFTER_TESTING.DESIGNER || NA}
          </span>
        </div>
      </td>
      <td>
        <div className={styles.estimateRow}>
          <span className={styles.estimate}>
            {total?.TEAM_ESTIMATE.TECH_LEAD || NA}
          </span>
          {isClientView ? (
            <span className={styles.customer}>
              {total?.CUSTOMER_ESTIMATE.TECH_LEAD || NA}
            </span>
          ) : null}
          <span className={styles.fact}>
            {total?.TEAM_FACT_AFTER_TESTING.TECH_LEAD || NA}
          </span>
        </div>
      </td>
      <td>
        <div className={styles.estimateRow}>
          <span className={styles.estimate}>
            {total?.TEAM_ESTIMATE.BE_DEV || NA}
          </span>
          {isClientView ? (
            <span className={styles.customer}>
              {total?.CUSTOMER_ESTIMATE.BE_DEV || NA}
            </span>
          ) : null}
          <span className={styles.fact}>
            {total?.TEAM_FACT_AFTER_TESTING.BE_DEV || NA}
          </span>
        </div>
      </td>
      <td>
        <div className={styles.estimateRow}>
          <span className={styles.estimate}>
            {total?.TEAM_ESTIMATE.WEB_DEV + total?.TEAM_ESTIMATE.MOBILE_DEV ||
              NA}
          </span>
          {isClientView ? (
            <span className={styles.customer}>
              {total?.CUSTOMER_ESTIMATE.WEB_DEV +
                total?.CUSTOMER_ESTIMATE.MOBILE_DEV || NA}
            </span>
          ) : null}
          <span className={styles.fact}>
            {total?.TEAM_FACT_AFTER_TESTING.WEB_DEV +
              total?.TEAM_FACT_AFTER_TESTING.MOBILE_DEV || NA}
          </span>
        </div>
      </td>
      <td>
        <div className={styles.estimateRow}>
          <span className={styles.estimate}>
            {total?.TEAM_ESTIMATE.QA || NA}
          </span>
          {isClientView ? (
            <span className={styles.customer}>
              {total?.CUSTOMER_ESTIMATE.QA || NA}
            </span>
          ) : null}
          <span className={styles.fact}>
            {total?.TEAM_FACT_AFTER_TESTING.QA || NA}
          </span>
        </div>
      </td>
      <td>
        <div className={styles.estimateRow}>
          <span className={styles.estimate}>
            {total?.TEAM_ESTIMATE.DEVOPS || NA}
          </span>
          {isClientView ? (
            <span className={styles.customer}>
              {total?.CUSTOMER_ESTIMATE.DEVOPS || NA}
            </span>
          ) : null}
          <span className={styles.fact}>
            {total?.TEAM_FACT_AFTER_TESTING.DEVOPS || NA}
          </span>
        </div>
      </td>
      <td>
        <div className={styles.estimateRow}>
          <span className={styles.estimate}>
            {total?.TEAM_ESTIMATE.PM || NA}
          </span>
          {isClientView ? (
            <span className={styles.customer}>
              {total?.CUSTOMER_ESTIMATE.PM || NA}
            </span>
          ) : null}
          <span className={styles.fact}>
            {total?.TEAM_FACT_AFTER_TESTING.PM || NA}
          </span>
        </div>
      </td>
      <td></td>
    </tr>
  );
}

export default TotalItem;
