// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
  --main-blue: #205dd1;
  --dark-blue: #0641b1;
  --light-blue: #f8fafb;
  --main-font: #0b1215;
  --disabled-font: #7b9098;
  --danger: #d12020;
  --dark-danger: #b20505;
  --element-background: #f9e6e6;
  --light-gray: #f0f0f0;
  --border-light: #e2ebed;
  --medium-gray: #b9b9b9;
  --icon-gray: #8e9fa7;
  --silver: #c4d1d7;
  --light-border: #eff1f4;
  --light-background: #eff1f4;
  --white: #fff;
  --lite-background: rgba(255, 255, 255, 0.56);
  --box-shadow: rgba(11, 18, 21, 0.12);
  --pink: #d12075;
}
`, "",{"version":3,"sources":["webpack://./src/renderer/styles/colors.css"],"names":[],"mappings":"AAAA;EACE,oBAAoB;EACpB,oBAAoB;EACpB,qBAAqB;EACrB,oBAAoB;EACpB,wBAAwB;EACxB,iBAAiB;EACjB,sBAAsB;EACtB,6BAA6B;EAC7B,qBAAqB;EACrB,uBAAuB;EACvB,sBAAsB;EACtB,oBAAoB;EACpB,iBAAiB;EACjB,uBAAuB;EACvB,2BAA2B;EAC3B,aAAa;EACb,4CAA4C;EAC5C,oCAAoC;EACpC,eAAe;AACjB","sourcesContent":[":root {\n  --main-blue: #205dd1;\n  --dark-blue: #0641b1;\n  --light-blue: #f8fafb;\n  --main-font: #0b1215;\n  --disabled-font: #7b9098;\n  --danger: #d12020;\n  --dark-danger: #b20505;\n  --element-background: #f9e6e6;\n  --light-gray: #f0f0f0;\n  --border-light: #e2ebed;\n  --medium-gray: #b9b9b9;\n  --icon-gray: #8e9fa7;\n  --silver: #c4d1d7;\n  --light-border: #eff1f4;\n  --light-background: #eff1f4;\n  --white: #fff;\n  --lite-background: rgba(255, 255, 255, 0.56);\n  --box-shadow: rgba(11, 18, 21, 0.12);\n  --pink: #d12075;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
