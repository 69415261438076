import styles from './TaskView.module.css';

const envs = ['DEV', 'QA', 'Stage', 'Prod'];

const EnvModule = ({ environments }: any) => {
  return (
    <>
      <span className={styles.taskDetailsItemTitle}>Environments</span>
      <div className={styles.envsWrapper}>
        {envs.map((env: any) => {
          const title = env.slice(0, 1);
          if (environments?.includes(env)) {
            return (
              <span
                key={env}
                className={`${styles.envItem} ${styles.envItemActive}`}
              >
                {title}
              </span>
            );
          } else {
            return (
              <span key={env} className={styles.envItem}>
                {title}
              </span>
            );
          }
        })}
      </div>
    </>
  );
};

export default EnvModule;
