import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { RoutesData } from '../../../../types/routes/routes';
import { STEP_STATUSES, STEPS, useStatusMap } from '../constants';
import StatusSelect from '../status-select';
import { sdlcTasksApi } from '../../../../api/sdlc';
import { HTTP_STATUSES, TOAST_MESSAGES } from '../../../../constants';
import { removeFields } from '../../../../utils';
import styles from './TableItem.module.css';

export const fieldsToRemove: any[] = [
  'createdAt',
  'deletedAt',
  'feature',
  'updatedAt',
];

function SDLCItem({ item, isEditStatusAvailable }: any): React.JSX.Element {
  const [currentItem, setCurrentItem] = useState(item);
  const navigate = useNavigate();
  const handleRedirect = () => {
    navigate(`${RoutesData.TaskDetails}/${item?.featureId}`, {
      state: { taskId: item?.featureId },
    });
  };
  const updateStepStatus = async (status: any, step: string) => {
    const allStatuses = removeFields(currentItem, fieldsToRemove);
    const body = {
      featureId: item.id,
      ...allStatuses,
      [step]: status,
    };
    const response = await sdlcTasksApi.updateSDLCStepStatus(item.id, body);
    if (response?.status === HTTP_STATUSES.ok) {
      toast.success(TOAST_MESSAGES.SAVED);
      setCurrentItem(response.data);
    } else {
      toast.error(TOAST_MESSAGES.COMMON_ERROR);
    }
  };

  const statusMap = useStatusMap(STEP_STATUSES);
  return (
    <tr className={styles.sdlcItem}>
      <td
        onClick={handleRedirect}
        style={{ width: '230px', paddingLeft: '16px' }}
        className={styles.stickyColumn}
      >
        {item?.feature?.name}
      </td>
      {STEPS.map((el) => {
        const value = currentItem[el] || '';
        const labelText = statusMap[el]?.[value] || '';
        const statusOptions = STEP_STATUSES[el];
        const currentValue = statusOptions.find(
          (op: any) => op?.label === labelText,
        );
        return (
          <td key={el} className={styles.tdSdlcStatus}>
            <span className={styles.sdlcStatus}>
              <StatusSelect
                status={currentValue}
                options={statusOptions}
                callback={(v) => updateStepStatus(v, el)}
                disabled={!isEditStatusAvailable}
                containerStyles={{ maxWidth: '126px' }}
              />
            </span>
          </td>
        );
      })}
    </tr>
  );
}

export default SDLCItem;
