import React from 'react';
import { INoReleaseModule } from './types';
import Table from '../table/table';
import ReleaseItem from './table-items/release-item';
import EstimateItem from './table-items/estimate-item';
import { formatDateForReleaseTitle } from '../../../utils';
import HR from '../hr/hr';
import { colors } from '../../../constants/colors';
import { HEADERS } from './constants';
import styles from './Release.module.css';

function NoReleaseModule({
  tasks = [],
  viewType = 'PLANING',
  isClientView = false,
}: INoReleaseModule): React.JSX.Element {
  const date = tasks[0]?.releaseDate
    ? formatDateForReleaseTitle(tasks[0]?.releaseDate)
    : 'N/A';

  const renderItem = (item: any) => {
    const ITEM = {
      PLANING: <ReleaseItem key={item.name + item.priority} item={item} />,
      ESTIMATE: (
        <EstimateItem key={item.id} item={item} isClientView={isClientView} />
      ),
      SDLC: <></>,
    };
    return ITEM[viewType];
  };

  if (!tasks?.length)
    return (
      <>
        <div className={styles.wrapper}>
          <Table headers={HEADERS[viewType]}>
            <tr>
              <td colSpan={HEADERS[viewType].length} className={styles.noItems}>
                <span>No data</span>
              </td>
            </tr>
          </Table>
        </div>
      </>
    );
  return (
    <>
      <div className={styles.wrapper}>
        <div className={styles.titleWrapper}>
          <span className={styles.title}>Not included in any release</span>
          <span className={styles.releaseDate}>Completion date: {date} </span>
        </div>
        <Table headers={HEADERS[viewType]}>
          {tasks.map((item: any) => renderItem(item))}
        </Table>
      </div>
      <HR color={colors.white} margin="8px 0px" />
    </>
  );
}

export default NoReleaseModule;
