import { useCallback, useEffect, useMemo, useState } from 'react';

import { ESTIMATE_HEADERS, ESTIMATE_HEADERS_CLIENT } from './constants';
import { HTTP_STATUSES, SPENDING_TYPE_CUSTOMER } from '../../../constants';
import { estimateTaskApi } from '../../../api/estimate';
import ClockIcon from '../../../assets/icons/clock';
import { NonBorderBtn, Switch, Table } from '../../../components/shared';
import AddIcon from '../../../assets/icons/add';
import { colors } from '../../../constants/colors';
import TaskEstimateItem from './task-estimate-item';
import { Role } from '../../../store/roleStore';
import { findTotalByType } from '../../../components/shared/table-release-modules/constants';

import AddEstimateModal from './modals/add-estimate-modal';
import styles from './TaskView.module.css';

const emptyValue = <span style={{ color: colors.icon_gray }}>—</span>;

const TaskEstimate = ({
  taskId,
  editPMAvailable,
  roles = [{ id: '', systemName: '', role: '' }],
  users,
  currentUser,
}: any) => {
  const rolesArray = roles?.map((role: any) => {
    return { id: role?.id, systemName: role?.systemName };
  });
  const [estimateTotal, setEstimateTotal] = useState<any>({});
  const [showCustomer, setShowCustomer] = useState(false);
  const [addEstimate, setAddEstimate] = useState(false);
  const [editTLAvailable, setEditTLAvailable] = useState(false);
  const [updateByRoleID, setUpdateByRoleID] = useState<string | number>('');

  const getEstimate = useCallback(async () => {
    if (taskId) {
      const params = {
        skip: 0,
        take: 1,
        sort: 'id',
        order: 'ASC',
        filter: JSON.stringify({ id: taskId }),
      };
      const estimate = await estimateTaskApi.getTaskSpendingSumList(params);

      if (estimate.status === HTTP_STATUSES.ok) {
        setEstimateTotal(estimate.data?.list?.[0]?.timeSpendingSummaryTotal);
      }
    }
  }, [taskId]);

  useEffect(() => {
    getEstimate();
  }, [getEstimate]);
  useEffect(() => {
    if (!roles?.length || !currentUser?.userProjects?.length) return;
    const pmRole =
      roles && roles.find((r: Role) => r?.systemName === 'TECH_LEAD');
    if (pmRole?.id === currentUser?.userProjects[0]?.roleId) {
      setEditTLAvailable(true);
    }
  }, [currentUser, roles]);

  const estimateModalType = useMemo(
    () => (editPMAvailable ? 'pm' : editTLAvailable ? 'lead' : 'user'),
    [editPMAvailable, editTLAvailable],
  );

  return (
    <div className={styles.displayColumn}>
      <div className={styles.detailsHeader}>
        <span className={styles.detailsTitle} style={{ marginBottom: '0' }}>
          Estimate & Fact
        </span>
        <div className={styles.headerActions}>
          {editPMAvailable && (
            <div
              className={styles.headerActions}
              style={{
                borderRight: `1px solid ${colors.light_gray}`,
                paddingRight: '12px',
              }}
            >
              <p style={{ marginRight: '12px' }}>For the client</p>
              <Switch
                iconView="eye"
                handleSwitch={(v: boolean) => setShowCustomer(v)}
                switchColors
              />
            </div>
          )}
          <div>
            <NonBorderBtn
              text={editPMAvailable || editTLAvailable ? 'Add' : 'Estimate'}
              onClick={() => setAddEstimate(true)}
              icon={
                editPMAvailable || editTLAvailable ? (
                  <AddIcon width="16" height="16" color={colors.main_blue} />
                ) : (
                  <ClockIcon />
                )
              }
            />
          </div>
        </div>
      </div>
      <div style={{ width: '100%' }}>
        <Table
          headers={showCustomer ? ESTIMATE_HEADERS_CLIENT : ESTIMATE_HEADERS}
        >
          {rolesArray?.map((role: any) => {
            return (
              <TaskEstimateItem
                key={role.id}
                role={role}
                taskId={taskId}
                editPMAvailable={editPMAvailable}
                users={users}
                showCustomer={showCustomer}
                updateByRoleID={updateByRoleID}
                getSummary={getEstimate}
                setUpdateByRoleID={setUpdateByRoleID}
              />
            );
          })}
          {taskId ? (
            <tr className={styles.totalEstimate}>
              <td>Total</td>
              <td>
                {findTotalByType(
                  estimateTotal,
                  SPENDING_TYPE_CUSTOMER.TEAM_ESTIMATE,
                ) || emptyValue}
              </td>
              {showCustomer ? (
                <td>
                  {findTotalByType(
                    estimateTotal,
                    SPENDING_TYPE_CUSTOMER.CUSTOMER_ESTIMATE,
                  ) || emptyValue}
                </td>
              ) : null}
              <td>
                {findTotalByType(
                  estimateTotal,
                  SPENDING_TYPE_CUSTOMER.TEAM_FACT_BEFORE_TESTING,
                ) || emptyValue}
              </td>
              <td>
                {findTotalByType(
                  estimateTotal,
                  SPENDING_TYPE_CUSTOMER.TEAM_FACT_AFTER_TESTING,
                ) || emptyValue}
              </td>
              <td></td>
            </tr>
          ) : null}
        </Table>
      </div>
      {addEstimate ? (
        <AddEstimateModal
          users={estimateModalType === 'user' ? currentUser : users}
          roles={roles}
          close={() => {
            setAddEstimate(false);
            setUpdateByRoleID('');
          }}
          taskId={taskId}
          type={estimateModalType}
          setAddEstimate={setAddEstimate}
          getEstimate={getEstimate}
          setUpdateByRoleID={setUpdateByRoleID}
        />
      ) : null}
    </div>
  );
};

export default TaskEstimate;
