import { useLocation } from 'react-router-dom';
import { useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { ToastContainer } from 'react-toastify';

import ContentPageContainer from '../../components/layouts/ContentPageContainer/content-page-container';
import { PageHeader } from '../../components/shared';
import { projectsApi } from '../../api/projects';
import { HTTP_STATUSES } from '../../constants';
import { useRoleQuery } from '../../hooks/useQuery';
import { ACTION_SCREENS, taskNavigation, TASKS_TABS } from './navigation';
import '../../styles/global.css';

const Tasks = () => {
  const location = useLocation();
  const queryClient = useQueryClient();

  const [searchValue, setSearchValue] = useState('');
  const [searchByString, setSearchByString] = useState(false);
  const { data: roles } = useRoleQuery();
  const currentUser: any = queryClient.getQueryData('user');

  const currentTab =
    TASKS_TABS.find((el) => location.pathname.includes(el.path)) ||
    TASKS_TABS[0];
  const Component = currentTab?.content;
  const actionScreen = Object.values(ACTION_SCREENS).find((screen) =>
    location.pathname.includes(screen.path),
  );

  const fetchProjectUsers = async () => {
    const params: any = {
      skip: 0,
      take: 100,
      sort: 'id',
      order: 'ASC',
    };
    const res = await projectsApi.getProjectsUsers(params);
    const userMe = currentUser?.data
      ? {
          value: currentUser?.data?.id,
          label: 'Me',
        }
      : null;

    if (res?.status === HTTP_STATUSES.ok) {
      const projectUsers = [
        {
          value: 'all',
          label: 'All',
        },
        userMe,
      ].concat(
        res?.data?.list?.map((user: any) => {
          return {
            value: user?.id,
            label: `${user?.user?.firstName} ${user?.user?.lastName}`,
            roleId: user.roleId,
          };
        }),
      );
      return projectUsers || [];
    }
  };

  const { data: users } = useQuery({
    queryKey: ['projectUsers'],
    queryFn: fetchProjectUsers,
  });

  return (
    <>
      <ToastContainer />
      <PageHeader
        navigateArr={taskNavigation}
        searchValue={searchValue}
        setSearchValue={setSearchValue}
        searchByString={searchByString}
        setSearchByString={setSearchByString}
        disableSearch={Boolean(actionScreen?.path)}
      />
      <div className="overflowContainer">
        {actionScreen ? (
          <actionScreen.component
            users={users}
            roles={roles?.data}
            currentUser={currentUser?.data}
          />
        ) : (
          <ContentPageContainer>
            {Component && (
              <Component
                search={searchValue}
                searchByString={searchByString}
                users={users}
                roles={roles?.data}
                currentUser={currentUser?.data}
              />
            )}
          </ContentPageContainer>
        )}
      </div>
    </>
  );
};

export default Tasks;
