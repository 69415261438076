import { makeAutoObservable } from 'mobx';

export type Role = {
  createdAt: null | string;
  deletedAt: null | string;
  id: string | number;
  label: string;
  systemName: string;
  updatedAt: null | string;
};

class RolesStore {
  roles: Role[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  setRoles(data: any) {
    this.roles = data;
  }
}

export const rolesStore = new RolesStore();
export const useRolesStore = () => rolesStore;
