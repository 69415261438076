import React, { useMemo } from 'react';
import { INoReleaseModule } from './types';
import Table from '../table/table';
import ReleaseItem from './table-items/release-item';
import EstimateItem from './table-items/estimate-item';
import SDLCItem from './table-items/sdlc-item';
import TableSDLC from './table-items/sdlc-table';
import { formatDateForReleaseTitle } from '../../../utils';
import HR from '../hr/hr';
import { colors } from '../../../constants/colors';
import { HEADERS } from './constants';
import styles from './Release.module.css';

function NoReleaseModule({
  tasks = [],
  viewType = 'PLANING',
  isClientView = false,
  isEditStatusAvailable = false,
}: INoReleaseModule): React.JSX.Element {
  const releaseDate = tasks[0]?.releaseDate || tasks[0]?.feature?.releaseDate;
  const date = releaseDate ? formatDateForReleaseTitle(releaseDate) : 'N/A';

  const isSDLC = useMemo(() => viewType === 'SDLC', [viewType]);
  const isEstimate = useMemo(() => viewType === 'ESTIMATE', [viewType]);

  const renderItem = (item: any) => {
    const ITEM = {
      PLANING: <ReleaseItem key={item.name + item.priority} item={item} />,
      ESTIMATE: (
        <EstimateItem key={item.id} item={item} isClientView={isClientView} />
      ),
      SDLC: <></>,
    };
    return ITEM[viewType];
  };

  if (!tasks?.length)
    return (
      <>
        <div className={styles.wrapper}>
          <Table headers={HEADERS[viewType]}>
            <tr>
              <td colSpan={HEADERS[viewType].length} className={styles.noItems}>
                <span>No data</span>
              </td>
            </tr>
          </Table>
        </div>
      </>
    );
  return (
    <>
      <div className={styles.wrapper}>
        <div className={styles.titleWrapper}>
          <span className={styles.title}>Not included in any release</span>
          <span className={styles.releaseDate}>Completion date: {date} </span>
        </div>
        {isSDLC ? (
          <TableSDLC headers={HEADERS[viewType]}>
            {tasks?.map((item: any) => (
              <SDLCItem
                key={item.id}
                item={item}
                isEditStatusAvailable={isEditStatusAvailable}
              />
            ))}
          </TableSDLC>
        ) : (
          <Table headers={HEADERS[viewType]} addScrollWrapperY={isEstimate}>
            {tasks.map((item: any) => renderItem(item))}
          </Table>
        )}
      </div>
      <HR color={colors.white} margin="8px 0px" />
    </>
  );
}

export default NoReleaseModule;
