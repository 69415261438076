import React from 'react';
import { IIcon } from '../../types';
import { colors } from '../../constants/colors';

const EyeOpen = ({ color = colors.icon_gray }: IIcon) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M1.61341 8.47563C1.52263 8.33183 1.47723 8.25996 1.45181 8.1491C1.43273 8.06583 1.43273 7.9345 1.45181 7.85123C1.47723 7.74036 1.52263 7.6685 1.61341 7.5247C2.36369 6.33672 4.59694 3.3335 8.00027 3.3335C11.4036 3.3335 13.6369 6.33672 14.3871 7.5247C14.4779 7.6685 14.5233 7.74036 14.5487 7.85123C14.5678 7.9345 14.5678 8.06583 14.5487 8.1491C14.5233 8.25996 14.4779 8.33183 14.3871 8.47563C13.6369 9.66363 11.4036 12.6668 8.00027 12.6668C4.59694 12.6668 2.36369 9.66363 1.61341 8.47563Z"
        stroke={color}
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8 10C9.1046 10 10 9.1046 10 8C10 6.8954 9.1046 6 8 6C6.8954 6 6 6.8954 6 8C6 9.1046 6.8954 10 8 10Z"
        stroke={color}
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default EyeOpen;
