import classNames from 'classnames';
import Account from '../../../assets/icons/account';
import { colors } from '../../../constants/colors';
import Tooltip from '../tooltip/tooltip';

import styles from './AssigneeAvatar.module.css';

const AssigneeAvatarTooltip = ({
  name,
  containerCustomClassName = '',
  avatarCustomStyles,
}: any) => {
  const containerClassName = classNames(styles.tooltipContainer, {
    [containerCustomClassName]: containerCustomClassName,
  });
  return (
    <div className={containerClassName}>
      <span className={styles.avatarContainer} style={avatarCustomStyles}>
        <Account height="24" width="24" />
      </span>
      <div className={styles.tooltipText}>
        <Tooltip label={name} customStyles={{ color: colors.main_font }} />
      </div>
    </div>
  );
};

export default AssigneeAvatarTooltip;
