import React from 'react';
import { IIcon } from '../../types';
import { colors } from '../../constants/colors';

const DecompositionIcon = ({
  className,
  onClick = undefined,
  color = colors.main_font,
  width = '12',
  height = '12',
}: IIcon) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 12 12"
      fill="none"
      className={className}
      onClick={onClick}
    >
      <g clipPath="url(#clip0_1872_18136)">
        <path
          d="M2.49752 6.00012L3.06902 6.57162V7.35712C3.06902 7.98862 3.58102 8.50012 4.21202 8.50012H5.25M2.49752 6.00012L3.06902 5.42862V4.64312C3.06902 4.01162 3.58052 3.50012 4.21202 3.50012H5.25M2.49752 6.00012H1.125M8.9 4.5H7C6.43995 4.5 6.1599 4.5 5.946 4.391C5.75785 4.29515 5.60485 4.14215 5.509 3.954C5.4 3.7401 5.4 3.56005 5.4 3C5.4 2.43994 5.4 2.25992 5.509 2.04601C5.60485 1.85784 5.75785 1.70486 5.946 1.60899C6.1599 1.5 6.43995 1.5 7 1.5L8.9 1.5C9.46005 1.5 9.74008 1.5 9.95399 1.60899C10.1422 1.70486 10.2951 1.85784 10.391 2.04601C10.5 2.25992 10.5 2.43994 10.5 3C10.5 3.56005 10.5 3.7401 10.391 3.954C10.2951 4.14215 10.1422 4.29515 9.95399 4.391C9.74008 4.5 9.46005 4.5 8.9 4.5ZM8.90002 10.5H7.00002C6.43997 10.5 6.15992 10.5 5.94602 10.391C5.75787 10.2951 5.60487 10.1422 5.50902 9.954C5.40002 9.7401 5.40002 9.56005 5.40002 9C5.40002 8.43994 5.40002 8.25992 5.50902 8.04601C5.60487 7.85784 5.75787 7.70487 5.94602 7.609C6.15992 7.5 6.43997 7.5 7.00002 7.5H8.90002C9.46007 7.5 9.7401 7.5 9.95401 7.609C10.1422 7.70487 10.2952 7.85784 10.391 8.04601C10.5 8.25992 10.5 8.43994 10.5 9C10.5 9.56005 10.5 9.7401 10.391 9.954C10.2952 10.1422 10.1422 10.2951 9.95401 10.391C9.7401 10.5 9.46007 10.5 8.90002 10.5Z"
          stroke={color}
          strokeWidth="1.3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_1872_18136">
          <rect width="12" height="12" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default DecompositionIcon;
