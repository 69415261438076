export const removeFields = <T extends Record<string, any>>(
  obj: T,
  fieldRemove: (keyof T)[],
): Partial<T> => {
  return Object.keys(obj).reduce<Partial<T>>((acc, key) => {
    if (!fieldRemove.includes(key as keyof T)) {
      acc[key as keyof T] = obj[key as keyof T];
    }
    return acc;
  }, {});
};
