export const HEADERS = {
  PLANING: [
    { title: 'Name' },
    { title: 'Priority' },
    { title: 'Assignee' },
    { title: 'Confluence' },
    { title: 'Redmine' },
    { title: 'Status' },
  ],
  ESTIMATE: [
    { title: 'Name' },
    { title: 'BA', style: { width: '8%' }, changeFlex: 'center' },
    { title: 'Designer', style: { width: '8%' }, changeFlex: 'center' },
    { title: 'Tech. lead', style: { width: '8%' }, changeFlex: 'center' },
    { title: 'BE', style: { width: '8%' }, changeFlex: 'center' },
    { title: 'FE', style: { width: '8%' }, changeFlex: 'center' },
    { title: 'QA', style: { width: '8%' }, changeFlex: 'center' },
    { title: 'DevOps', style: { width: '8%' }, changeFlex: 'center' },
    { title: 'PM', style: { width: '8%' }, changeFlex: 'center' },
    { title: 'Total', changeFlex: 'center' },
  ],
  SDLC: [
    { title: 'Name' },
    { title: 'BA' },
    { title: 'Designer' },
    { title: 'Tech. lead' },
    { title: 'BE' },
    { title: 'FE' },
    { title: 'QA' },
    { title: 'DevOps' },
    { title: 'PM' },
  ],
};
export const SPENDING_TYPE = {
  TEAM_ESTIMATE: 'TEAM_ESTIMATE',
  CUSTOMER_ESTIMATE: 'CUSTOMER_ESTIMATE',
  TEAM_FACT_BEFORE_TESTING: 'TEAM_FACT_BEFORE_TESTING',
  TEAM_FACT_AFTER_TESTING: 'TEAM_FACT_AFTER_TESTING',
};
export const ROLE_SYSTEM = {
  BA: 'BA',
  DESIGNER: 'DESIGNER',
  TECH_LEAD: 'TECH_LEAD',
  BE_DEV: 'BE_DEV',
  MOBILE_DEV: 'MOBILE_DEV',
  WEB_DEV: 'WEB_DEV',
  QA: 'QA',
  DEVOPS: 'DEVOPS',
  PM: 'PM',
};

export const ROLE_SYSTEM_ARRAY = [
  'BA',
  'Designer',
  'Tech. lead',
  'BE',
  'MOBILE_DEV',
  'WEB_DEV',
  'QA',
  'DevOps',
  'PM',
];
export const findEstimateByKey = (data: any, key: string, type: string) => {
  const el = data.find(
    (item: any) => item.roleSystemName === key && item.type === type,
  );
  if (el) {
    return el.estimate;
  } else return 'N/A';
};
export const estimateForFE = (data: any, type: string) => {
  const mobDev = data.find(
    (item: any) =>
      item.roleSystemName === ROLE_SYSTEM.MOBILE_DEV && item.type === type,
  );
  const webDev = data.find(
    (item: any) =>
      item.roleSystemName === ROLE_SYSTEM.WEB_DEV && item.type === type,
  );
  if (mobDev?.estimate && webDev?.estimate) {
    return mobDev?.estimate + webDev?.estimate;
  } else if (mobDev?.estimate || webDev?.estimate) {
    return mobDev?.estimate || webDev?.estimate;
  } else return 'N/A';
};
export const findTotalByType = (data: any, type: string) => {
  const el = data.find((item: any) => item.type === type);
  if (el) {
    return el.estimate;
  } else return 'N/A';
};

export const sumTotalByKey = (data: any[], type: string) => {
  const roleKeys = [
    ROLE_SYSTEM.QA,
    ROLE_SYSTEM.PM,
    ROLE_SYSTEM.DEVOPS,
    ROLE_SYSTEM.BA,
    ROLE_SYSTEM.DESIGNER,
    ROLE_SYSTEM.TECH_LEAD,
    ROLE_SYSTEM.BE_DEV,
    ROLE_SYSTEM.MOBILE_DEV,
    ROLE_SYSTEM.WEB_DEV,
  ];

  return data
    .filter((item) => item?.type === type)
    .reduce(
      (acc, item) => {
        const { roleSystemName, estimate } = item;
        if (roleKeys.includes(roleSystemName)) {
          acc[roleSystemName] =
            (acc[roleSystemName] || 0) + Number(estimate || 0);
        }
        return acc;
      },
      {
        [ROLE_SYSTEM.QA]: 0,
        [ROLE_SYSTEM.PM]: 0,
        [ROLE_SYSTEM.DEVOPS]: 0,
        [ROLE_SYSTEM.BA]: 0,
        [ROLE_SYSTEM.DESIGNER]: 0,
        [ROLE_SYSTEM.TECH_LEAD]: 0,
        [ROLE_SYSTEM.BE_DEV]: 0,
        [ROLE_SYSTEM.MOBILE_DEV]: 0,
        [ROLE_SYSTEM.WEB_DEV]: 0,
      },
    );
};
