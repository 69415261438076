import { useState } from 'react';
import classNames from 'classnames';

import { IInput } from './types';
import SearchIcon from '../../../assets/icons/search';
import ClearInputIcon from '../../../assets/icons/clear-input';
import NonBorderBtn from '../buttons/NonBorderBtn/NonBorderBtn';
import styles from './Input.module.css';

const CustomInput = ({
  adornment,
  className,
  containerClassName,
  customInputStyle,
  disabled,
  error,
  helperText = '',
  id,
  maxLength,
  minLength,
  name,
  label,
  onBlur,
  onChange,
  onClear,
  onSearch,
  onFocus,
  onEnter,
  pattern = '',
  placeHolder = '',
  readonly,
  showCountDown = false,
  type = 'text',
  value,
  view = 'simple',
  withClear = false,
  defaultValue,
  ...rest
}: IInput) => {
  const [showClear, setShowClear] = useState(false);
  const handleKeyDown = (e: any) => {
    if (e.key === 'Enter') {
      onEnter();
    }
  };
  const handleChange = (e: any) => {
    withClear && setShowClear(true);
    onChange && onChange(e?.target?.value);
  };

  const handleClear = () => {
    setShowClear(false);
    onClear && onClear();
  };
  const handleSearch = () => {
    if (!value) return;
    onSearch && onSearch();
  };

  const inputClassNames = classNames(styles.simpleInput, className, {
    [styles.simpleInput__error]: error,
  });
  const complexInputClassNames = classNames(styles.complexInput, className);
  const containerClassNames = classNames(
    styles.complexInputContainer,
    containerClassName,
  );

  const countDown = `${0 + String(value)?.length}/100`;

  const inputComponents = {
    simple: (
      <input
        {...rest}
        autoComplete="off"
        className={inputClassNames}
        disabled={disabled}
        id={id}
        maxLength={maxLength}
        minLength={minLength}
        name={name}
        onBlur={onBlur}
        onChange={handleChange}
        onFocus={onFocus}
        onKeyDown={(e) => handleKeyDown(e)}
        placeholder={placeHolder}
        type={type}
        value={value}
        readOnly={readonly}
        style={customInputStyle}
      />
    ),
    endAdornment: (
      <>
        <div className={styles.complexInputContainer}>
          <input
            disabled={disabled}
            type="text"
            pattern={pattern}
            placeholder={placeHolder}
            className={complexInputClassNames}
            onChange={handleChange}
            value={value}
            onKeyDown={(e) => handleKeyDown(e)}
            defaultValue={defaultValue}
            style={customInputStyle}
            id={id}
          />
          {adornment ? adornment : <SearchIcon />}
        </div>
      </>
    ),
    startAdornment: (
      <div className={containerClassNames}>
        <div className={styles.actionIcon}>
          <SearchIcon onClick={handleSearch} />
        </div>
        <input
          type="text"
          placeholder={placeHolder}
          className={complexInputClassNames}
          value={value}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          style={customInputStyle}
          id={id}
          disabled={disabled}
        />
        {showClear && (
          <div
            className={styles.actionIcon}
            role="button"
            onClick={handleClear}
          >
            <ClearInputIcon />
          </div>
        )}
      </div>
    ),
    endWithBtn: (
      <>
        <div className={containerClassNames} style={{ paddingRight: '0' }}>
          <input
            type="text"
            placeholder={placeHolder}
            className={styles.complexInput}
            onChange={handleChange}
            id={id}
          />
          <NonBorderBtn text="Add" onClick={() => {}} />
        </div>
      </>
    ),
  };

  return (
    <div className={styles.wrapper}>
      {label ? <span className={styles.wrapper__label}>{label}</span> : null}
      {inputComponents[view]}
      {helperText && <div className={styles.helper_text}>{helperText}</div>}
      <div className={styles.bottom_container}>
        {error ? <span className={styles.wrapper__error}>{error}</span> : null}
        {showCountDown ? (
          <div className={styles.helper_text}>{countDown}</div>
        ) : null}
      </div>
    </div>
  );
};

export default CustomInput;
