export function formatDateForReleaseTitle(
  date: Date,
  view?: 'listFormat' | 'taskViewFormat',
): string {
  const formDate = new Date(date);
  const day = String(formDate.getDate()).padStart(2, '0');
  const month = String(formDate.getMonth() + 1).padStart(2, '0');
  const year = formDate.getFullYear();
  const viewTypes = {
    listFormat: `${day}-${month}-${year}`,
    taskViewFormat: `${day}.${month}.${year}`,
  };

  return viewTypes[view || 'listFormat'];
}
