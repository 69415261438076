import React from 'react';

export const Edit = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2873_9371)">
        <path
          d="M12.0001 6.66622L9.33343 3.99955M1.66675 14.3329L3.92299 14.0822C4.19865 14.0516 4.33648 14.0362 4.46531 13.9946C4.57961 13.9576 4.68838 13.9053 4.78867 13.8392C4.90171 13.7646 4.99977 13.6666 5.1959 13.4704L14.0001 4.66622C14.7365 3.92984 14.7365 2.73593 14.0001 1.99955C13.2637 1.26317 12.0698 1.26317 11.3334 1.99955L2.52923 10.8038C2.33311 10.9999 2.23505 11.0979 2.16051 11.211C2.09437 11.3113 2.04209 11.42 2.00509 11.5344C1.96339 11.6632 1.94807 11.801 1.91744 12.0766L1.66675 14.3329Z"
          stroke="#0B1215"
          strokeWidth="1.3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_2873_9371">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
