import { useLocation } from 'react-router-dom';
import { useCallback, useEffect, useState } from 'react';

import ContentPageContainer from '../../components/layouts/ContentPageContainer/content-page-container';
import { PageHeader } from '../../components/shared';
import { taskNavigation, TASKS_TABS } from './navigation';
import { RoutesData } from '../../types/routes/routes';
import { CreateNewTask } from '../../screens/Tasks';
import { projectsApi } from '../../api/projects';
import { HTTP_STATUSES } from '../../constants';
import { userStore } from '../../store';
import { observer } from 'mobx-react-lite';

const Tasks = observer(() => {
  const location = useLocation();
  const [searchValue, setSearchValue] = useState('');
  const [searchByString, setSearchByString] = useState(false);
  const [users, setUsers] = useState<any[]>([]);
  const currentUser: any = userStore?.user;

  const currentTab =
    TASKS_TABS.find((el) => location.pathname.includes(el.path)) ||
    TASKS_TABS[0];
  const Component = currentTab?.content;
  const isNewTaskScreen = location.pathname.includes(RoutesData.CreateTask);

  const getUsersList = useCallback(async () => {
    const params: any = {
      skip: 0,
      take: 100,
      sort: 'id',
      order: 'ASC',
    };
    const res = await projectsApi.getProjectsUsers(params);
    const userMe = currentUser
      ? {
          value: currentUser.id,
          label: 'Me',
        }
      : null;

    if (res?.status === HTTP_STATUSES.ok) {
      const projectUsers = [
        {
          value: 'all',
          label: 'All',
        },
        userMe,
      ].concat(
        res?.data?.list?.map((user: any) => {
          return {
            value: user?.id,
            label: `${user?.user?.firstName} ${user?.user?.lastName}`,
          };
        }),
      );
      setUsers(projectUsers);
    }
  }, []);
  useEffect(() => {
    getUsersList();
  }, [getUsersList]);

  return (
    <>
      <PageHeader
        navigateArr={taskNavigation}
        searchValue={searchValue}
        setSearchValue={setSearchValue}
        searchByString={searchByString}
        setSearchByString={setSearchByString}
      />
      <div
        style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          overflow: 'auto',
        }}
      >
        {isNewTaskScreen ? (
          <CreateNewTask />
        ) : (
          <ContentPageContainer>
            {Component && (
              <Component
                search={searchValue}
                searchByString={searchByString}
                users={users}
              />
            )}
          </ContentPageContainer>
        )}
      </div>
    </>
  );
});

export default Tasks;
