import { useEffect, useState } from 'react';
import { CustomInput } from '../../../../components/shared';
import { colors } from '../../../../constants/colors';
import ExitSmallIcon from '../../../../assets/icons/exit-small-icon';
import styles from './Modals.module.css';

interface Entity {
  id: number;
  name: string;
  estimate: string;
}
interface IDecomposition {
  form:
    | {
        submitForm: () => void;
        isSubmitting: boolean;
        values: any;
        setFieldValue: (name: string, v: any) => void;
        initialValues: any;
      }
    | undefined;
  editPMAvailable: boolean;
  editMode: boolean;
  data: any[];
  setTouched: (value: boolean) => void;
}

const Decomposition = ({
  form,
  data,
  setTouched,
  editMode,
  editPMAvailable,
}: IDecomposition) => {
  const [entities, setEntities] = useState<Entity[]>([
    { id: Date.now(), name: '', estimate: '' },
  ]);
  const changeValues = (id: string | number, nameField: string, value: any) => {
    setTouched(true);
    const updatedData = data.map((i: any) =>
      i.id === id ? { ...i, [nameField]: value } : i,
    );
    form?.setFieldValue('decomposition', updatedData);
  };

  useEffect(() => {
    const lastEntity = entities[entities.length - 1];
    if (lastEntity?.name.trim() !== '' && entities.length < 100) {
      setEntities([...entities, { id: Date.now(), name: '', estimate: '' }]);
    }
    form?.setFieldValue('addDecomposition', entities);
  }, [entities]);

  const handleAdd = (id: number, field: keyof Entity, value: string) => {
    setTouched(true);
    setEntities((prev) =>
      prev.map((entity) =>
        entity.id === id ? { ...entity, [field]: value } : entity,
      ),
    );
  };
  const handleRemove = (id: number, type: 'oldValues' | 'newValues') => {
    setTouched(true);
    if (type === 'newValues') {
      if (entities.length > 1) {
        setEntities((prev) => prev.filter((entity) => entity.id !== id));
      }
    } else {
      const updatedData = data.filter((i: any) => i.id !== id);
      form?.setFieldValue('decomposition', updatedData);
    }
  };

  const renderInputRow = (
    id: number,
    name: string,
    estimate: string,
    isRemovable: boolean,
    type: 'oldValues' | 'newValues',
  ) => {
    const isOldValue = type === 'oldValues';
    const isShowError = (estimate && !name) || (name && !estimate);

    return (
      <div key={id} style={{ width: '100%' }}>
        <div className={styles.decompositionItem}>
          <CustomInput
            value={name}
            onChange={(v: string) =>
              isOldValue
                ? changeValues(id, 'name', v)
                : handleAdd(id, 'name', v)
            }
          />
          <CustomInput
            value={estimate}
            onChange={(v: string) =>
              isOldValue
                ? changeValues(id, 'estimate', v)
                : handleAdd(id, 'estimate', v)
            }
            type="number"
          />
          <div
            onClick={isRemovable ? () => handleRemove(id, type) : undefined}
            role="button"
            className={styles.removeBtn}
            style={{ cursor: isRemovable ? 'pointer' : '' }}
          >
            <ExitSmallIcon
              color={isRemovable ? colors.dark_danger : colors.medium_gray}
            />
          </div>
        </div>
        <div className={styles.helperText}>
          {isShowError
            ? 'Please fill in both fields OR this subtask will be skipped'
            : ''}
        </div>
      </div>
    );
  };
  return (
    <div className={styles.decompositionList}>
      <div className={styles.decompositionItem}>
        <p className={styles.label}>Subtask</p>
        <p className={styles.label}>Time</p>
        <p />
      </div>
      {editPMAvailable ? (
        <>
          {editMode &&
            data.map((item: any) =>
              renderInputRow(
                item.id,
                item.name,
                item.estimate,
                true,
                'oldValues',
              ),
            )}

          {entities.map((entity) =>
            renderInputRow(
              entity.id,
              entity.name,
              entity.estimate,
              entity.name.length > 0,
              'newValues',
            ),
          )}
        </>
      ) : (
        <>
          {data.length &&
            data.map((el: any) => (
              <div key={el.id} className={styles.decompositionItem}>
                <span>{el.name}</span>
                <span>{el.estimate} hours</span>
                <div />
              </div>
            ))}
        </>
      )}
    </div>
  );
};

export default Decomposition;
