// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Estimate_wrapper__LZDpH {
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 100%;
  padding-bottom: 16px;
}
.Estimate_searchRow__BhRR- {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 14px;
  width: 100%;
}
.Estimate_legend__dKs39 {
  align-items: center;
  display: flex;
  justify-content: flex-start;
  gap: 24px;
  color: var(--main-font);
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  max-width: 50%;
  min-width: 50%;
}
.Estimate_legend__dKs39 > div {
  align-items: center;
  display: flex;
  justify-content: space-between;
  gap: 6px;
}
.Estimate_borderLeft__Je5c7 {
  border-left: 1px solid var(--silver);
  padding-left: 20px;
}
.Estimate_legend__dKs39 > div > p {
  width: max-content;
}
.Estimate_search__WdYZr {
  align-items: center;
  display: flex;
  justify-content: flex-end;
  gap: 12px;
  color: var(--main-font);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  width: 100%;
}
.Estimate_loadingContainer__zzFkb {
  align-items: center;
  display: flex;
  justify-content: center;
  margin-top: 20%;
  width: 100%;
}
`, "",{"version":3,"sources":["webpack://./src/renderer/screens/Tasks/estimate/Estimate.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,QAAQ;EACR,WAAW;EACX,oBAAoB;AACtB;AACA;EACE,mBAAmB;EACnB,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;EACnB,WAAW;AACb;AACA;EACE,mBAAmB;EACnB,aAAa;EACb,2BAA2B;EAC3B,SAAS;EACT,uBAAuB;EACvB,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,iBAAiB;EACjB,cAAc;EACd,cAAc;AAChB;AACA;EACE,mBAAmB;EACnB,aAAa;EACb,8BAA8B;EAC9B,QAAQ;AACV;AACA;EACE,oCAAoC;EACpC,kBAAkB;AACpB;AACA;EACE,kBAAkB;AACpB;AACA;EACE,mBAAmB;EACnB,aAAa;EACb,yBAAyB;EACzB,SAAS;EACT,uBAAuB;EACvB,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,iBAAiB;EACjB,WAAW;AACb;AACA;EACE,mBAAmB;EACnB,aAAa;EACb,uBAAuB;EACvB,eAAe;EACf,WAAW;AACb","sourcesContent":[".wrapper {\n  display: flex;\n  flex-direction: column;\n  gap: 4px;\n  width: 100%;\n  padding-bottom: 16px;\n}\n.searchRow {\n  align-items: center;\n  display: flex;\n  justify-content: space-between;\n  margin-bottom: 14px;\n  width: 100%;\n}\n.legend {\n  align-items: center;\n  display: flex;\n  justify-content: flex-start;\n  gap: 24px;\n  color: var(--main-font);\n  font-size: 13px;\n  font-style: normal;\n  font-weight: 400;\n  line-height: 16px;\n  max-width: 50%;\n  min-width: 50%;\n}\n.legend > div {\n  align-items: center;\n  display: flex;\n  justify-content: space-between;\n  gap: 6px;\n}\n.borderLeft {\n  border-left: 1px solid var(--silver);\n  padding-left: 20px;\n}\n.legend > div > p {\n  width: max-content;\n}\n.search {\n  align-items: center;\n  display: flex;\n  justify-content: flex-end;\n  gap: 12px;\n  color: var(--main-font);\n  font-size: 16px;\n  font-style: normal;\n  font-weight: 500;\n  line-height: 28px;\n  width: 100%;\n}\n.loadingContainer {\n  align-items: center;\n  display: flex;\n  justify-content: center;\n  margin-top: 20%;\n  width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `Estimate_wrapper__LZDpH`,
	"searchRow": `Estimate_searchRow__BhRR-`,
	"legend": `Estimate_legend__dKs39`,
	"borderLeft": `Estimate_borderLeft__Je5c7`,
	"search": `Estimate_search__WdYZr`,
	"loadingContainer": `Estimate_loadingContainer__zzFkb`
};
export default ___CSS_LOADER_EXPORT___;
