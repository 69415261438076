import { makeAutoObservable } from 'mobx';

class UserStore {
  user = null;

  constructor() {
    makeAutoObservable(this);
  }

  setUser(userData: any) {
    this.user = userData;
  }

  logout() {
    this.user = null;
  }
}

export const userStore = new UserStore();
export const useUserStore = () => userStore;
