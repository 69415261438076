import { useQueryClient } from 'react-query';
import React, { useMemo, useState } from 'react';
import classNames from 'classnames';

import PageWrapper from '../../components/layouts/PageWrapper/page-wrapper';
import {
  CustomInput,
  NonBorderBtn,
  Select,
  Radio,
} from '../../components/shared';
import SendIcon from '../../assets/icons/send';
import { Option } from '../../components/shared/radio/radio';
import Arrow from '../../assets/icons/arrow';
import { useAssignees, useFetchData, useFilter, useRelease } from './hooks';
import { SORT_BY, TASK_FILTER, TASK_FILTER_OPTIONS } from './constants';
import { NotesTable } from './NotesTable';
import { CreateNote } from './CreateNote/CreateNote';

import styles from './Notes.module.css';

const ORDER: { value: string; body: React.ReactElement }[] = [
  {
    value: 'DESC',
    body: <Arrow rotate />,
  },
  {
    value: 'ASC',
    body: <Arrow />,
  },
];

const isIgnored = (v: string | number | undefined | null) => v === 'ALL' || !v;

export const PRIORITY_FILTER = [
  {
    body: <div className={classNames(styles.color, styles.urgent)} />,
    value: 'URGENT',
    label: 'Urgent',
  },
  {
    body: <div className={classNames(styles.color, styles.high)} />,
    value: 'HIGH',
    label: 'High',
  },
  {
    body: <div className={classNames(styles.color, styles.medium)} />,
    value: 'MEDIUM',
    label: 'Medium',
  },
  {
    body: <div className={classNames(styles.color, styles.low)} />,
    value: 'LOW',
    label: 'Low',
  },
];

function Notes() {
  const {
    filter: taskStatusFilter,
    selectOption,
    selectedOption: selectedTaskOption,
  } = useFilter(TASK_FILTER);

  const assignees = useAssignees();

  const assigneesOpts = useMemo(
    () =>
      assignees?.map((assignee) => ({
        body: `Release ${assignee.label}`,
        value: assignee?.value,
      })),
    [assignees],
  );

  const {
    filter: assigneeFilter,
    selectOption: selectAssignee,
    selectedOption: selectedAssignee,
  } = useFilter(assigneesOpts);

  const releases = useRelease();

  const releaseOpts = useMemo(
    () =>
      releases?.map((release) => ({
        body: `Release ${release?.releaseNumber}`,
        value: release?.id,
      })),
    [releases],
  );

  const {
    filter: releaseFilter,
    selectOption: selectReleaseFilter,
    selectedOption: selectedReleaseOption,
  } = useFilter(releaseOpts);

  const {
    filter: taskFilter,
    selectOption: selectTaskFilter,
    selectedOption: selectedTaskFilterOption,
  } = useFilter(TASK_FILTER_OPTIONS);

  const {
    filter: priorityStatusFilter,
    selectOption: selectPriorityStatus,
    selectedOption: selectedPriorityOption,
  } = useFilter(PRIORITY_FILTER);

  const {
    filter: orderFilter,
    selectOption: selectOrder,
    selectedOption: selectedOrder,
  } = useFilter(ORDER, true);

  const [sortBy, setSortBy] = useState('name');

  const filter = useMemo(() => {
    const optionsBuilder: any = {
      take: 999,
      skip: 0,
      sort: sortBy,
      order: selectedOrder?.value,
    };

    const optsFilter: any = {};

    if (!isIgnored(selectedPriorityOption?.value)) {
      optsFilter.priority = selectedPriorityOption?.value;
    }

    if (!isIgnored(selectedTaskOption?.value)) {
      optsFilter.status = selectedTaskOption?.value;
    }

    if (!isIgnored(selectedReleaseOption?.value)) {
      optsFilter.releaseId = selectedReleaseOption?.value;
    }

    if (!isIgnored(selectedTaskFilterOption?.value)) {
      optsFilter.type = selectedTaskFilterOption?.value;
    }

    optionsBuilder.filter = JSON.stringify(optsFilter);

    return optionsBuilder;
  }, [
    sortBy,
    orderFilter,
    priorityStatusFilter,
    taskFilter,
    releaseFilter,
    assigneeFilter,
  ]);

  const queryClient = useQueryClient();

  const { data } = useFetchData({ ...filter, url: '/notes', key: 'notes' });

  const refresh = () => {
    queryClient.invalidateQueries('notes');
  };

  const [searchValue, setSearchValue] = useState('');
  const [searchByString, setSearchByString] = useState(false);

  const handleClearSearch = () => {
    setSearchValue('');
    setSearchByString(!searchByString);
  };

  return (
    <PageWrapper>
      <CreateNote
        Button={(onClick) => (
          <div className={styles.roundBtn}>
            <NonBorderBtn text="" icon={<SendIcon />} onClick={onClick} />
          </div>
        )}
        refresh={refresh}
      />

      <div className={styles.wrapper}>
        <div className={classNames(styles.row)}>
          <div
            style={{
              background: 'transparent',
              width: '320px',
            }}
          >
            <CustomInput
              view="startAdornment"
              className={styles.searchInput}
              containerClassName={styles.searchInput}
              onChange={setSearchValue}
              onClear={handleClearSearch}
              onSearch={() => setSearchByString(!searchByString)}
              onEnter={() => setSearchByString(!searchByString)}
              placeHolder="Search notes"
              value={searchValue}
              withClear
              id="searchInput"
            />
          </div>
          <div className={styles.select}>
            <Select
              options={SORT_BY}
              onChange={(e: any) => setSortBy(e.value)}
              value={sortBy}
              additionalInfoText={'By'}
              showAdditionalInfoInSelect
            />
          </div>

          <div className={classNames(styles.filters)}>
            <div className={styles.filterBoldLabel}> Filter:</div>
            <div className={styles.row}>
              <div className={styles.select}>
                <Select
                  value={selectedReleaseOption?.value}
                  onChange={selectReleaseFilter}
                  options={releaseFilter?.map((el: Option) => ({
                    ...el,
                    label: el.body,
                  }))}
                  additionalInfoText={'Release'}
                  showAdditionalInfoInSelect
                />
              </div>
              <div className={styles.select}>
                <Select
                  value={selectedTaskFilterOption?.value}
                  onChange={selectTaskFilter}
                  options={taskFilter?.map((el: Option) => ({
                    ...el,
                    label: el.body,
                  }))}
                  additionalInfoText={'Type'}
                  showAdditionalInfoInSelect
                />
              </div>
              <div className={styles.select}>
                <Select
                  value={selectedAssignee?.value}
                  onChange={selectAssignee}
                  options={assigneeFilter?.map((el: Option) => ({
                    ...el,
                    label: el.body,
                  }))}
                  additionalInfoText={'Assignee'}
                  showAdditionalInfoInSelect
                />
              </div>
            </div>
          </div>
        </div>

        <div className={`${styles.flexItems} ${styles.between}`}>
          <div className={classNames(styles.flexItems)}>
            <div className={styles.filterBoldLabel}> Sort By</div>
            <Radio
              value={sortBy}
              options={SORT_BY.map((el) => ({
                body: el.label,
                value: el.value,
              }))}
              onOptionChange={(option: Option) =>
                setSortBy(String(option.value))
              }
            />

            <div className={styles.emptyDivider} />
            <div className={styles.select}>
              <Radio
                value={selectedOrder?.value}
                options={orderFilter}
                onOptionChange={selectOrder}
                round
              />
            </div>

            <div className={styles.divider} />
          </div>

          <div className={classNames(styles.flexItems)}>
            <div className={styles.filterLabel}> Priority</div>

            <Radio
              value={selectedPriorityOption?.value}
              options={priorityStatusFilter}
              onOptionChange={selectPriorityStatus}
              round
            />

            <div className={styles.emptyDivider} />

            <Radio
              value={selectedTaskOption?.value}
              options={taskStatusFilter}
              onOptionChange={selectOption}
            />
          </div>
        </div>

        <NotesTable refresh={refresh} notes={data?.list} />
      </div>
    </PageWrapper>
  );
}

export default Notes;
