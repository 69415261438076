import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import classNames from 'classnames';

import { IReleaseModule } from './types';
import Table from '../table/table';
import ReleaseItem from './table-items/release-item';
import EstimateItem from './table-items/estimate-item';
import ArrowSelectIcon from '../../../assets/icons/arrow-select';
import { colors } from '../../../constants/colors';
import AddBtnIcon from '../../../assets/icons/add-btn-icon';
import { RoutesData } from '../../../types/routes/routes';
import { formatDateForReleaseTitle } from '../../../utils';
import HR from '../hr/hr';
import { HEADERS, SPENDING_TYPE, sumTotalByKey } from './constants';
import TotalItem from './table-items/total-item';
import styles from './Release.module.css';

function ReleaseModule({
  tasks,
  releaseId,
  isSomeSearch = false,
  viewType = 'PLANING',
  isClientView = false,
}: IReleaseModule) {
  const navigate = useNavigate();

  const [showTasks, setShowTasks] = useState(false);
  const date = useMemo(
    () =>
      tasks[0]?.releaseDate
        ? formatDateForReleaseTitle(tasks[0]?.releaseDate)
        : 'N/A',
    [],
  );
  const releaseName = useMemo(() => tasks[0]?.release?.releaseNumber, []);
  const iconClassName = classNames(styles.icon, {
    [styles.icon__open]: showTasks,
  });
  const titleWrapperClassName = classNames(
    styles.titleWrapper,
    styles.titleWrapperRelease,
  );

  const tasksCount = tasks?.length ? tasks?.length : 'No';
  const handleRedirect = () => {
    navigate(RoutesData.CreateTask, { state: { releaseId } });
  };

  useEffect(() => {
    const releaseStatus = tasks[0]?.release?.status;
    const isShowByDefault = isSomeSearch || releaseStatus !== 'COMPLETED';
    setShowTasks(isShowByDefault);
  }, []);

  const renderItem = (item: any) => {
    const ITEM = {
      PLANING: <ReleaseItem key={item.name + item.priority} item={item} />,
      ESTIMATE: (
        <EstimateItem key={item.id} item={item} isClientView={isClientView} />
      ),
      SDLC: <></>,
    };
    return ITEM[viewType];
  };

  const isEstimate = viewType === 'ESTIMATE';
  const estimateTotal = isEstimate
    ? {
        TEAM_ESTIMATE: sumTotalByKey(
          tasks?.flatMap((item: any) => item?.timeSpendingSummary),
          SPENDING_TYPE.TEAM_ESTIMATE,
        ),
        TEAM_FACT_AFTER_TESTING: sumTotalByKey(
          tasks?.flatMap((item: any) => item?.timeSpendingSummary),
          SPENDING_TYPE.TEAM_FACT_AFTER_TESTING,
        ),
        CUSTOMER_ESTIMATE: sumTotalByKey(
          tasks?.flatMap((item: any) => item?.timeSpendingSummary),
          SPENDING_TYPE.CUSTOMER_ESTIMATE,
        ),
      }
    : undefined;
  return (
    <>
      <ToastContainer />
      <div className={styles.wrapper}>
        <div className={titleWrapperClassName}>
          <div className={styles.titleWrapper} style={{ padding: 0 }}>
            <span
              className={styles.title}
              role="button"
              onClick={() => setShowTasks(!showTasks)}
              style={{
                color: showTasks ? colors.main_blue : colors.main_font,
                cursor: 'pointer',
              }}
            >
              <ArrowSelectIcon
                color={showTasks ? colors.main_blue : colors.main_font}
                className={iconClassName}
              />
              Release {releaseName}
            </span>
            <span className={styles.releaseDate}>Release date: {date} </span>
          </div>
          <div className={styles.endTitleBlock}>
            <span>
              {tasksCount} {tasksCount === 1 ? 'task' : 'tasks'}
            </span>
            <span
              className={styles.addBtn}
              role="button"
              onClick={handleRedirect}
            >
              <AddBtnIcon />
            </span>
          </div>
        </div>
        {showTasks ? (
          <Table headers={HEADERS[viewType]}>
            {tasks?.length ? (
              <>
                {tasks?.map((item: any) => renderItem(item))}
                {isEstimate ? (
                  <TotalItem
                    total={estimateTotal}
                    isClientView={isClientView}
                  />
                ) : null}
              </>
            ) : (
              <>no tasks</>
            )}
          </Table>
        ) : null}
      </div>
      <HR color={colors.white} margin="8px 0px" />
    </>
  );
}

export default ReleaseModule;
