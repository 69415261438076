import React from 'react';
import { IIcon } from '../../types';

const BackNavIcon = ({ className, onClick = undefined }: IIcon) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      onClick={onClick}
      className={className}
    >
      <path
        d="M15 18L9 12L15 6"
        stroke="#0B1215"
        strokeWidth="1.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default BackNavIcon;
