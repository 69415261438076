import React, { useEffect, useMemo, useState } from 'react';
import { object, string } from 'yup';
import { useFormik } from 'formik';
import classNames from 'classnames';

import { taskApi } from '../../api/task';
import { HTTP_STATUSES } from '../../constants';
import { getProjectId } from '../../utils/localStorage';
import { notesApi } from '../../api/notes';
import {
  CustomInput,
  OutlineBtn,
  PrimaryBtn,
  Select,
  TextArea,
  ViewModal,
  CustomDayPicker,
  MultiSelectUsers,
} from '../../components/shared';
import { Note, PRIORITY, STATUS, TYPE } from './constants';
import { dateToISODate, removeNullUndefined } from '../../utils';
import { Task } from './types';
import { useRelease } from './hooks';
import styles from './Notes.module.css';

const NO_RELEASE = 'NO_RELEASE';

type EditNotesProps = {
  closeModal: () => void;
  note: Note;
  refresh: () => void;
  refreshAssignees: () => void;
  assigneeIds?: any[];
};

export function EditNotesModal({
  closeModal,
  note,
  refresh,
  refreshAssignees,
  assigneeIds = [],
}: EditNotesProps) {
  const [releaseTasks, setReleaseTasks] = useState<Task[]>([]);

  const releases = useRelease();

  const releaseOptions: { label: string; value: number | string }[] = useMemo(
    () => [
      { label: 'No release', value: NO_RELEASE },
      ...releases.map((el) => ({
        label: `Release ${el.releaseNumber}`,
        value: el?.releaseNumber,
      })),
    ],
    [releases],
  );

  const mappedTasksByRelease = useMemo(() => {
    const uniqueReleaseIds = Array.from(
      new Set(releaseTasks.map((el) => el?.release?.releaseNumber)),
    );

    return uniqueReleaseIds.map((releaseId) => ({
      releaseNumber: releaseId ?? null,
      relatedTasks: releaseTasks?.filter(
        (task) => task?.release?.releaseNumber === releaseId,
      ),
    }));
  }, [releaseTasks]);

  const mappedTasksToSelectOptions = mappedTasksByRelease.map((mappedTask) => ({
    name: mappedTask?.releaseNumber
      ? `Release ${mappedTask.releaseNumber}`
      : 'No included in any release',
    options: mappedTask.relatedTasks.map((task) => ({
      label: task.name,
      value: task.id,
    })),
  }));
  const defUsers = assigneeIds?.length
    ? assigneeIds?.map((us: any) => {
        return {
          label: `${us.assignee.user?.firstName || ''} ${us.assignee.user?.lastName || ''}`,
          value: us.assigneeId,
          details: us.assignee.user.email,
        };
      })
    : [];

  const getReleaseTasks = async () => {
    setReleaseTasks([]);
    const params: any = {
      skip: 0,
      take: 100,
      sort: 'id',
      order: 'ASC',
    };
    const response = await taskApi.getTasksList(params);
    if (response?.status === HTTP_STATUSES.ok) {
      setReleaseTasks(response?.data?.list);
    }
  };

  useEffect(() => {
    getReleaseTasks();
  }, []);

  const notesForm = useFormik({
    initialValues: {
      name: note?.name,
      featureId: note?.featureId,
      releaseId: note?.releaseId,
      meetUrl: note?.meetUrl,
      status: note?.status,
      description: note?.description,
      deadline: note?.deadline ? new Date(note?.deadline) : null,
      priority: note?.priority,
      type: note?.type,
      isHidden: note?.isHidden,
      projectId: Number(getProjectId()),
    },
    onSubmit: async (values, { resetForm }) => {
      const body = {
        ...values,
        deadline: values.deadline ? dateToISODate(values.deadline) : null,
      };
      const { status } = await notesApi.updateNote(
        note?.id,
        removeNullUndefined(body),
      );

      if (status === HTTP_STATUSES.ok) {
        closeModal();
        refresh();
        resetForm();
      }
    },
    validationSchema: object().shape({
      name: string().max(100),
    }),
  });

  const setFormValue = (name: string) => (v: string) =>
    notesForm.setFieldValue(name, v);

  const setSelectFormValue = (name: string) => (e: { value: string }) => {
    notesForm.setFieldValue(name, e?.value);
  };

  const handleClose = () => {
    notesForm.resetForm();
    closeModal();
  };

  const setTargetUsersIds = async (arr: any) => {
    const ids = arr?.map((el: any) => el?.value) || [];
    const body = {
      noteId: note.id,
      assigneeIds: ids,
    };
    const response = await notesApi.updateNoteAssignees(body);
    if (response.status === HTTP_STATUSES.ok) {
      refreshAssignees();
    }
  };

  return (
    <>
      <ViewModal
        callback={handleClose}
        subTitle={''}
        title={'Edit a note'}
        width="auto"
        text={
          <div>
            <div className={styles.field}>
              <CustomInput
                label="Name"
                value={notesForm.values.name}
                onChange={setFormValue('name')}
                maxLength={100}
                showCountDown
              />
            </div>

            <div className={styles.field}>
              <div className={styles.row}>
                <div className={styles.col}>
                  <TextArea
                    label="Detailed description"
                    value={notesForm.values.description ?? ''}
                    maxTextLength={3000}
                    showCountDown
                    symbols={notesForm?.values?.description?.length ?? 0}
                    onChange={(e) =>
                      notesForm.setFieldValue('description', e?.target?.value)
                    }
                    cols={2}
                    rows={2}
                  />
                </div>
                <div className={styles.col}>
                  <TextArea
                    label="Meet url"
                    value={notesForm?.values?.meetUrl}
                    maxTextLength={100}
                    showCountDown
                    symbols={notesForm.values.meetUrl?.length || 0}
                    onChange={(e) => {
                      notesForm.setFieldValue('meetUrl', e?.target?.value);
                    }}
                    cols={2}
                    rows={2}
                  />
                </div>
              </div>
            </div>
            <div className={styles.field}>
              <div className={styles.row}>
                <div className={classNames(styles.col8, styles.mr8)}>
                  <Select
                    label="Related task"
                    value={notesForm.values.featureId}
                    onChange={setSelectFormValue('featureId')}
                    options={mappedTasksToSelectOptions}
                  />
                </div>
                <div className={classNames(styles.col4, styles.ml8)}>
                  <Select
                    label="Related release"
                    value={notesForm.values.releaseId}
                    onChange={setSelectFormValue('releaseId')}
                    options={releaseOptions}
                  />
                </div>
              </div>
            </div>

            <div className={styles.field}>
              <div className={styles.row}>
                <div className={styles.col4}>
                  <Select
                    label="Status"
                    value={notesForm.values.status}
                    onChange={setSelectFormValue('status')}
                    options={STATUS}
                  />
                </div>
                <div className={styles.col4}>
                  <CustomDayPicker
                    label="Deadline date & time"
                    onSetDate={(value: string | Date) =>
                      notesForm.setFieldValue('deadline', value)
                    }
                    defaultDate={notesForm?.values?.deadline}
                    showTimePicker
                  />
                </div>
                <div className={styles.col4}>
                  <Select
                    label="Priority"
                    value={notesForm.values.priority}
                    onChange={setSelectFormValue('priority')}
                    options={PRIORITY}
                  />
                </div>
                <div className={styles.col4}>
                  <Select
                    label="Type of the note"
                    value={notesForm.values.type}
                    onChange={setSelectFormValue('type')}
                    options={TYPE}
                  />
                </div>
              </div>
            </div>

            <div className={styles.field}>
              <div className={styles.label}> Assignees</div>
              <MultiSelectUsers
                emptyListTitle="No employees assigned"
                onChange={setTargetUsersIds}
                label="Add employee"
                defaultUsers={defUsers}
              />
            </div>

            <div className={classNames(styles.field, styles.row)}>
              <div className={styles.btn}>
                <PrimaryBtn text="Edit" onClick={notesForm.submitForm} />
              </div>

              <div className={styles.btn}>
                <OutlineBtn isSecondary text="Cancel" onClick={handleClose} />
              </div>
            </div>
          </div>
        }
      />
    </>
  );
}
