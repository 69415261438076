// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PageWrapper_wrapper__qEv22 {
  align-items: center;
  background: linear-gradient(180deg, #f9e6e6, #bbc8e0);
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 24px;
  width: 100vw;
  flex: 1 1;
  overflow: auto;
}
`, "",{"version":3,"sources":["webpack://./src/renderer/components/layouts/PageWrapper/PageWrapper.module.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,qDAAqD;EACrD,aAAa;EACb,sBAAsB;EACtB,SAAS;EACT,aAAa;EACb,YAAY;EACZ,SAAO;EACP,cAAc;AAChB","sourcesContent":[".wrapper {\n  align-items: center;\n  background: linear-gradient(180deg, #f9e6e6, #bbc8e0);\n  display: flex;\n  flex-direction: column;\n  margin: 0;\n  padding: 24px;\n  width: 100vw;\n  flex: 1;\n  overflow: auto;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `PageWrapper_wrapper__qEv22`
};
export default ___CSS_LOADER_EXPORT___;
