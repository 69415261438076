import axios, { AxiosError } from 'axios';
import { API_URL } from '../constants';
import { getAccessToken, getProjectId } from '../utils/localStorage';

const api = axios.create({
  baseURL: API_URL,
  // other configuration options...
});

api.interceptors.request.use((config) => {
  const accessToken = getAccessToken();
  const projectId = getProjectId();

  if (accessToken) {
    config.headers.Authorization = `Bearer ${accessToken}`;
  }

  if (projectId) {
    config.params = {
      projectId,
      ...config.params,
    };
  }

  return config;
});

api.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error: AxiosError) => {
    if (!error.response) {
      return Promise.reject(new Error('Network error'));
    }

    const { status } = error.response;

    switch (status) {
      case 401:
        return Promise.reject(new Error('Unauthorized'));
      case 400: {
        return error.response;
      }
      default: {
        return Promise.reject(error.response);
      }
    }
  },
);

export default api;
