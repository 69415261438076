import React from 'react';
import className from 'classnames';
import { IOutlinedBtn } from './types';
import AddBtnIcon from '../../../../assets/icons/add-btn-icon';
import styles from './NonBorderBtn.module.css';

const NonBorderBtn = ({
  classNameBtn = '',
  text,
  onClick,
  disabled = false,
  withIcon = true,
  icon = <AddBtnIcon />,
}: IOutlinedBtn) => {
  const btnClassName = className(styles.non_border_btn, {
    [classNameBtn]: classNameBtn,
  });

  return (
    <button
      disabled={disabled}
      className={btnClassName}
      onClick={(e) => (disabled ? e.preventDefault() : onClick())}
    >
      {withIcon ? icon : null}
      {text}
    </button>
  );
};

export default NonBorderBtn;
