import api from './axios.config';

type NewTaskResponse = any;
type TaskResponse = any;

const defaultParams: any = {
  skip: 0,
  take: 10,
  sort: 'createdAt',
  order: 'ASC',
  search: { name: '' },
};
const defaultAssignees: any = { assigneeIds: [1] };

const getTasksList = (params = defaultParams) => {
  return api.get<any, TaskResponse>('/features', { params });
};
const getTaskById = (id: string | number) => {
  return api.get<any, TaskResponse>(`/features/${id}`);
};
const getTasksAssignees = (id: string | number) => {
  return api.get<any, TaskResponse>(`/features/${id}/assignees`);
};
const addAssignees = (id: string | number, body = defaultAssignees) => {
  return api.post<any, TaskResponse>(`/features/${id}/assignees`, body);
};
const updateAssignees = (id: string | number, body = defaultAssignees) => {
  return api.patch<any, TaskResponse>(`/features/${id}/assignees`, body);
};
const createNewTask = (body: any) =>
  api.post<any, NewTaskResponse>('/features', body);
const updateTask = (id: string | number, body: any) => {
  return api.patch<any, TaskResponse>(`/features/${id}`, body);
};
const getPhaseStatus = (id: string | number) => {
  return api.get<any, TaskResponse>(`/features/${id}/phase-statuses`);
};
const updatePhaseStatus = (id: string | number, body: any) => {
  return api.patch<any, TaskResponse>(`/features/${id}/phase-statuses`, body);
};
const updateTaskStatus = (id: string | number, body: any) => {
  return api.patch<any, TaskResponse>(`/features/${id}/status`, body);
};
const deleteTask = (id: string | number) => {
  return api.delete<any, TaskResponse>(`/features/${id}`);
};

export const taskApi = {
  addAssignees,
  createNewTask,
  getTasksList,
  getTaskById,
  getTasksAssignees,
  getPhaseStatus,
  deleteTask,
  updateAssignees,
  updateTask,
  updatePhaseStatus,
  updateTaskStatus,
};
