import { useState } from 'react';
import ExitIcon from '../../../../assets/icons/exit-icon';
import { ApproveModal, CustomInput } from '../../../../components/shared';
import styles from './Modals.module.css';

interface IEditLinkModal {
  close: () => void;
  form:
    | {
        submitForm: () => void;
        isSubmitting: boolean;
        values: any;
        setFieldValue: (name: string, v: any) => void;
      }
    | undefined;
  editableLink: { field: ''; label: '' };
}

const EditLinkModal = ({ form, close, editableLink }: IEditLinkModal) => {
  const [touched, setTouched] = useState(false);
  return (
    <ApproveModal
      btnText="Save"
      callback={form?.submitForm}
      closeModal={close}
      disabled={
        form?.isSubmitting || !form?.values[editableLink.field] || !touched
      }
    >
      <div className={styles.editWrapper}>
        <span role="button" onClick={close} className={styles.closeBtn}>
          <ExitIcon />
        </span>
        <div className={styles.modalTitle}>Edit link</div>
        <CustomInput
          label={editableLink?.label}
          placeHolder="https://"
          name="name"
          value={form?.values[editableLink?.field]}
          onChange={(v: string) => {
            form?.setFieldValue(editableLink?.field, v);
            setTouched(true);
          }}
        />
      </div>
    </ApproveModal>
  );
};

export default EditLinkModal;
