import React, { useState } from 'react';
import TripleTrueIcon from '../../../assets/icons/tripleTrue';
import TripleFalseIcon from '../../../assets/icons/tripleFalse';
import EyeOpen from '../../../assets/icons/eye-open';
import EyeClose from '../../../assets/icons/eye-close';
import './Switch.css';
import { colors } from '../../../constants/colors';

interface SwitchProps {
  initialState?: boolean;
  label?: string;
  handleSwitch?: (val: boolean) => void;
  details?: string;
  iconView?: 'default' | 'eye' | 'custom';
  switchColors?: boolean;
  customOnIcon?: React.ReactNode;
  customOffIcon?: React.ReactNode;
}

const Switch: React.FC<SwitchProps> = ({
  initialState = false,
  label,
  handleSwitch = undefined,
  details,
  iconView = 'default',
  switchColors = false,
  customOnIcon = <TripleTrueIcon />,
}) => {
  const [isActive, setIsActive] = useState<boolean>(initialState);

  const toggleSwitch = () => {
    setIsActive(!isActive);
    handleSwitch && handleSwitch(!isActive);
  };

  const renderSwitch = () => {
    switch (iconView) {
      case 'eye':
        return (
          <div
            className={`switch-eye ${isActive ? 'active' : 'inactive'} ${switchColors ? 'switch-eye-gray' : ''}`}
            onClick={toggleSwitch}
          >
            <div
              className={`switch-eye-icon ${switchColors ? 'switch-eye-icon-white' : ''}`}
            >
              {isActive ? (
                <EyeOpen
                  color={isActive ? colors.main_font : colors.icon_gray}
                />
              ) : (
                <EyeClose
                  color={isActive ? colors.icon_gray : colors.main_font}
                />
              )}
            </div>
          </div>
        );
      case 'default':
        return (
          <div
            className={`switch ${isActive ? 'active' : 'inactive'}`}
            onClick={toggleSwitch}
          >
            <div className="switch-icon">
              {isActive ? (
                customOnIcon
              ) : (
                <TripleFalseIcon className="falseIcon" />
              )}
            </div>
          </div>
        );
      default:
        return (
          <div
            className={`switch ${isActive ? 'active' : 'inactive'}`}
            onClick={toggleSwitch}
          >
            <div className="switch-icon">
              {isActive ? (
                customOnIcon
              ) : (
                <TripleFalseIcon className="falseIcon" />
              )}
            </div>
          </div>
        );
    }
  };

  return (
    <div className="switchWrapper">
      {label ? <span>{label}</span> : null}

      <div className="detailsContainer">
        {renderSwitch()}
        {details ? <span>{details}</span> : null}
      </div>
    </div>
  );
};

export default Switch;
